import { Search } from "@mui/icons-material";
import { Autocomplete, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { deselectAllMarks, postMetric, selectMark, selectRows } from "../app";
import { getCRMSpotsInImage, getSpotsInActiveImage } from "../app/appSelectors";

export const NUM_SEARCH_RESULTS = 300;

export const commonACProps = {
  size: "small",
  selectOnFocus: true,
  blurOnSelect: true,
  popupIcon: <Search />,
  sx: {
    minWidth: 250,
    maxWidth: 600,
    width: "100%",
    "& .MuiAutocomplete-popupIndicator": { transform: "none" },
  },
  ListboxProps: { style: { maxHeight: 108 } },
  noOptionsText: "Inga platser hittades",
  renderInput: (params) => (
    <TextField {...params} placeholder="Sök efter plats, namn" />
  ),
};
export function SimpleOverviewSearch() {
  const dispatch = useDispatch();
  const org = useSelector((state) => state.app.org);


  if (org?.usingBAS) {
    const basImport = useSelector((s) => s.app.import);
    const selectedBasRows = useSelector((s) => s.app.selectedRows);
    const spotsOnImage = useSelector((s) => getSpotsInActiveImage(s));
    const basRowsOnImage = basImport.filter((x) =>
      spotsOnImage.includes(x.spot)
    );

    const handleChange = (event, option) => {
      // do not send metric on empty search
      if (option?.spot) {
        dispatch(postMetric({ type: "spot_search", target: option.spot }));
      }
      dispatch(deselectAllMarks());
      if (option) {
        dispatch(selectRows([option]));
      }
    };

    return (
      <Autocomplete
        {...commonACProps}
        options={basRowsOnImage}
        value={selectedBasRows[0] || null}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.spot + " " + option.name
        }
        filterOptions={autocompleteSearchAllBasPropertiesIgnoreCase}
        onChange={handleChange}
      />
    );
  }

  if (org?.hamnvyCRM) {
    const marks = useSelector((s) => s.app.image?.marks);
    const spots = useSelector((s) => getCRMSpotsInImage(s));
    const selectedSpots = useSelector((s) => s.app.selectedSpots);

    const handleChange = (event, option) => {
      dispatch(deselectAllMarks());
      if (option) {
        const mark = marks.find((x) => x.spot === option.spotNumber);
        if (mark) dispatch(selectMark(mark));
      }
    };

    return (
      <Autocomplete
        {...commonACProps}
        options={spots}
        value={
          selectedSpots[0]
            ? spots.find((x) => x.spotNumber === selectedSpots[0])
            : null
        }
        getOptionLabel={(option) =>
          typeof option === "string"
            ? option
            : option.spotNumber +
            (option?.boat ? " " + option?.boat?.owner?.name : "")
        }
        filterOptions={autocompleteSearchAllCRMPropertiesIgnoreCase}
        onChange={handleChange}
      />
    );
  }

  return null;
}
export const autocompleteSearchAllBasPropertiesIgnoreCase = (options, state) =>
  options
    .filter((x) =>
      []
        .concat(Object.keys(x).map((k) => x[k]))
        .join("|")
        .toLowerCase()
        .includes(state.inputValue.toLowerCase())
    )
    .slice(0, NUM_SEARCH_RESULTS);

export const autocompleteSearchAllCRMPropertiesIgnoreCase = (spots, state) => {
  const meta = ["org", "id", "createdAt", "updatedAt"];
  const ignore = ["boat", "owner", ...meta];
  const objToStr = (x, ...ignoreKeys) =>
    x
      ? Object.keys(x)
        .map((k) => (ignoreKeys.includes(k) ? null : x[k]))
        .filter((x) => x)
        .join("|")
      : [];
  return spots
    .filter((x) =>
      []
        .concat(
          objToStr(x, ...ignore),
          objToStr(x?.boat, ...ignore),
          objToStr(x?.boat?.owner, ...ignore)
        )
        .join("|")
        .toLowerCase()
        .includes(state.inputValue.toLowerCase())
    )
    .slice(0, NUM_SEARCH_RESULTS);
};
