import { IUser } from "@hamnvy/shared";
import { Add, Block, OpenInNew, Person } from "@mui/icons-material";
import {
  Button,
  Card,
  DialogContent,
  FormControlLabel,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DialogWithCloseButton } from "../../Components/Dialog";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { userIsAdmin } from "../../services/utils/userRights";
import { getUsers } from "../app";
import Header from "../main/Header";
import PageHeader from "../main/PageHeader";
import UserForm from "./UserForm";

interface Props {}

const UsersPage: FC<Props> = (props) => {
  if (!userIsAdmin()) return null;
  const { t } = useTranslation();

  const [isAdding, setAdding] = useState<boolean>(false);
  const [view, setView] = useState<"card" | "table">("card");
  const users: IUser[] = useAppSelector((s) => s.app.users);
  const org: any = useAppSelector((s) => s.app.org);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (users.length === 0) {
      dispatch(getUsers());
    }
  }, []);

  return (
    <>
      <Header>
        <PageHeader title="Användare & grupper" />
      </Header>
      <Box sx={{ px: 2 }}>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, pb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={() => setAdding(true)}
          >
            Ny användare
          </Button>
          <FormControlLabel
            control={
              <Switch
                checked={view === "table"}
                onClick={() => setView(view === "table" ? "card" : "table")}
              />
            }
            sx={{ userSelect: "none" }}
            label="Tabell"
          />
        </Box>
        <Typography variant="h5">{org?.orgName}</Typography>
        {view === "card" && (
          <Box sx={{ py: 2, display: "flex", gap: 1, flexWrap: "wrap" }}>
            {users
              .filter((x) => !x?.deleted)
              .map((u) => (
                <Card
                  key={u.id}
                  sx={{
                    p: 2,
                    maxWidth: 400,
                    display: "grid",
                    columnGap: 2,
                    alignItems: "center",
                    gridTemplateColumns: "auto 1fr auto",
                    width: "100%",
                    "> *": {
                      // stop email from moving open button
                      minWidth: 0,
                    },
                  }}
                >
                  <Person color="primary" />
                  <Box sx={{ display: "block" }}>
                    <Box
                      sx={{
                        "& p": {
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          width: "calc(96%)",
                        },
                      }}
                    >
                      <Typography
                        sx={{ display: "flex", gap: 0.5, alignItems: "center" }}
                      >
                        <b>{u?.name ?? u.username}</b>
                        {u?.disabled && (
                          <Tooltip
                            title={t("user.disabled", {
                              defaultValue: "Inaktiverad",
                            })}
                          >
                            <Block color="error" fontSize="small" />
                          </Tooltip>
                        )}
                      </Typography>
                      {u?.email && <Typography>{u?.email}</Typography>}
                    </Box>
                  </Box>
                  <IconButton onClick={() => navigate(`/users/${u.id}`)}>
                    <OpenInNew />
                  </IconButton>
                </Card>
              ))}
          </Box>
        )}
        {view === "table" && (
          <Box sx={{ py: 2 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Användarnamn</TableCell>
                    <TableCell>Epost</TableCell>
                    <TableCell>Namn</TableCell>
                    <TableCell>Telefon</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((u) => (
                    <TableRow key={u.id}>
                      <TableCell padding="none">
                        <IconButton onClick={() => navigate(`/users/${u.id}`)}>
                          <OpenInNew />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 0.5,
                            alignItems: "center",
                          }}
                        >
                          {u.username}
                          {u?.disabled && (
                            <Tooltip
                              title={t("user.disabled", {
                                defaultValue: "Inaktiverad",
                              })}
                            >
                              <Block color="error" fontSize="small" />
                            </Tooltip>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>{u?.email ?? "-"}</TableCell>
                      <TableCell>{u?.name ?? "-"}</TableCell>
                      <TableCell>{u?.phone ?? "-"}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Box>
      <DialogWithCloseButton
        title="Lägg till användare"
        open={isAdding}
        onClose={() => setAdding(false)}
      >
        <DialogContent>
          <UserForm
            onCancel={() => setAdding(false)}
            onSuccess={() => setAdding(false)}
          />
        </DialogContent>
      </DialogWithCloseButton>
    </>
  );
};

export default UsersPage;
