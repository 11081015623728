import { BasRow, MinimalMark } from "@hamnvy/shared";
import {
  Table,
  TableCell,
  TableContainer,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import config from "../../../config";
import { useAppSelector } from "../../../hooks";
import { api } from "../../../services/api";
import { basColumns } from "../../../services/bas";
import ImageWithMarks from "../../assignment/ImageWithMarks";
import PreviewImage from "../../assignment/PreviewImage";
import { InteractiveFieldDisplay } from "../../map/MapView/DisplayDetails";

interface Props {
  spotNumber: string;
}

const SpotPreviewContent: FC<Props> = (props) => {
  const { spotNumber } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [spotMarks, setSpotMarks] = useState<MinimalMark[]>([]);
  const basImport: BasRow[] = useAppSelector((s) => s.app.import);
  const basData = basImport.find((x) => x.spot === spotNumber);

  const notFoundSnack = (): void => {
    enqueueSnackbar(
      t("spotPreview.spot_marks_not_found", {
        defaultValue: "Platsen kunde inte hittas på någon hamnvy",
      }),
      { variant: "error" }
    );
  };

  const getSpotMarks = (spot: string): void => {
    api
      .get(config.apiUrl + "/marks?spot=" + spot)
      .then(({ data }) => {
        const marks: MinimalMark[] = data?.marks ?? [];
        if (marks.length === 0) notFoundSnack();
        setSpotMarks(marks);
      })
      .catch(() => {
        notFoundSnack();
      });
  };

  useEffect(() => {
    getSpotMarks(spotNumber);
  }, []);

  return (
    <Box sx={{ display: "grid", gap: 2 }}>
      <Box sx={{ display: "flex", gap: 2 }}>
        {spotMarks.map((mark) => (
          <PreviewImage key={mark.id} title="Vald plats:">
            <ImageWithMarks marks={[mark]} imageId={mark.image} />
          </PreviewImage>
        ))}
        <BasContent fields={["width"]} basData={basData} alwaysLabel />
      </Box>
      <BasContent fields={["name", "email", "phone"]} basData={basData} />
    </Box>
  );
};

const BasContent: FC<{
  basData?: BasRow;
  fields: string[];
  alwaysLabel?: boolean;
}> = (props) => {
  const { basData, fields, alwaysLabel } = props;
  const theme = useTheme();
  const notSmallScreen = useMediaQuery(theme.breakpoints.up("md"));
  return basData ? (
    <TableContainer>
      <Table>
        {fields.map((field) => (
          <TableRow key={"tr-" + field}>
            {(notSmallScreen || alwaysLabel) && (
              <TableCell>
                {basColumns.find((x) => x.field === field)?.title}
              </TableCell>
            )}
            <TableCell
              align="right"
              padding="none"
              sx={{ py: 1, overflow: "hidden" }}
            >
              {/* @ts-expect-error */}
              {InteractiveFieldDisplay(field, basData?.[field])}
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </TableContainer>
  ) : null;
};

export default SpotPreviewContent;
