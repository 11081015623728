import { AxiosResponse } from "axios";
import { api } from "./api";

class AttachmentApi {
  static delete(fileId: string): Promise<AxiosResponse> {
    return api.delete(`/files/${fileId}`);
  }
}

export { AttachmentApi };
