import { IUser, IUsergroup } from "@hamnvy/shared";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { isFulfilled } from "@reduxjs/toolkit";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ComponentHeading from "../../Components/ComponentHeading";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { postNotification } from "../app";
import OrganisationField from "./common/OrganisationField";
import TypeOfNotificationField from "./common/TypeOfNotificationField";
import UsergroupsField from "./common/UsergroupsField";

interface FormState {
  variant: string;
  org: string;
  title: string;
  description: string;
  users: IUser[];
  anonymous: boolean;
}

const initialFormState: FormState = {
  variant: "",
  org: "",
  title: "",
  description: "",
  users: [],
  anonymous: false,
};

type Props = {
  isEditing?: boolean;
};

const EditNotification = (props: Props) => {
  const { isEditing = false } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useAppSelector<IUser | null>((s) => s.app.user);
  const users = useAppSelector<IUser[]>((s) => s.app.users);
  const [groups, setGroups] = useState<IUsergroup[]>([]);
  const groupIds = groups.map((g) => g.id);

  const [state, setState] = useState<FormState>(initialFormState);

  const publish = async () => {
    const payload = _.pickBy(state, _.identity);
    if (payload?.users) {
      const result = await dispatch(
        // @ts-ignore
        postNotification({ ...payload, users: state.users.map((x) => x.id) })
      );
      if (isFulfilled(result)) {
        enqueueSnackbar(
          t("notification.create_snack_success", {
            defaultValue: "Notis skapad",
          }),
          { variant: "success" }
        );
        setState({
          ...initialFormState,
          variant: state.variant,
          users: state.users,
        });
      } else {
        enqueueSnackbar(
          t("notification.create_snack_fail", {
            defaultValue:
              "Något gick fel, kunde inte skapa notis. Försök igen.",
          }),
          { variant: "error" }
        );
      }
    }
  };

  const handleVariantChange = (variant: string) => {
    setGroups([]);
    const newState = { ...state, variant };
    switch (variant) {
      case "org_msg":
        newState.users = users.filter((x) => x.org === user?.org?.id);
        break;
      default:
        newState.users = [];
        break;
    }
    setState(newState);
  };

  const filteredUsers = users.filter((x) =>
    groups.length ? groupIds.includes(x.role) : true
  );

  return (
    <>
      <Box sx={{ display: "grid", gap: 1 }}>
        <ComponentHeading
          title={isEditing ? "Redigera notis" : "Skicka ut notis"}
        />
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <TypeOfNotificationField
            value={state.variant}
            onChange={handleVariantChange}
            isSuperUser={user?.isSuperUser}
          />
          {state.variant === "org_msg" && user?.isSuperUser && (
            <OrganisationField
              value={state.org}
              onChange={(org) => setState({ ...state, org })}
            />
          )}
          {state.variant === "grp_msg" && (
            <UsergroupsField
              value={groups}
              onChange={(groups) => {
                setGroups(groups);
                const gids = groups.map((x) => x.id);
                setState({
                  ...state,
                  users: users.filter((x) => gids.includes(x?.role)),
                });
              }}
            />
          )}
        </Box>

        {(state.users.length > 0 ||
          groups.length > 0 ||
          state.variant === "usr_msg") && (
          <Autocomplete
            multiple
            size="small"
            value={state.users}
            options={filteredUsers}
            onChange={(e, users) => setState({ ...state, users })}
            getOptionLabel={(option) => option?.name || option.username}
            renderInput={(params) => (
              <TextField label="Mottagare" {...params} />
            )}
          />
        )}

        <TextField
          size="small"
          variant="outlined"
          label="Rubrik"
          autoComplete="off"
          required
          value={state.title}
          onChange={(e) => setState({ ...state, title: e.target.value })}
        />
        <TextField
          multiline
          size="small"
          label="Beskrivning"
          autoComplete="off"
          value={state.description}
          onChange={(e) => setState({ ...state, description: e.target.value })}
        />
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            color="primary"
            variant="contained"
            disabled={!state.variant || !state.title || !users.length}
            onClick={publish}
          >
            Skicka
          </Button>
          {user?.isSuperUser && (
            <FormControlLabel
              control={
                <Checkbox
                  value={state.anonymous}
                  onClick={() =>
                    setState({ ...state, anonymous: !state.anonymous })
                  }
                />
              }
              label="Anonym avsändare"
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default EditNotification;
