import { Box } from "@mui/material";
import { FC, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "../../../Components/utils/BackButton";
import { useAppDispatch } from "../../../hooks";
import NotFoundPage from "../../../routes/404.page";
import { getLatestBasImport } from "../../app";
import Header from "../../main/Header";
import PageHeader from "../../main/PageHeader";
import AssignmentCommentary from "../AssignmentCommentary";
import AssignmentDetails from "../AssignmentDetails";
import { AssignmentContext } from "../AssignmentProvider";
import AssignmentSpotSummary from "../AssignmentSpotSummary";
import AssignmentStatusChip from "../fields/AssignmentStatusChip";

const SingleFailure: FC<{}> = () => {
  const { getSingleAssignment, assignment } = useContext(AssignmentContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const marks = assignment?.marks ?? [];

  useEffect(() => {
    if (marks.length > 0) {
      // todo: only get spots specified
      dispatch(getLatestBasImport());
    }
  }, [marks]);

  useEffect(() => {
    if (id) getSingleAssignment(id);
  }, []);

  if (!id) return <NotFoundPage />;

  return (
    <>
      <Header>
        <PageHeader
          title="Felanmälan"
          titleHeader={
            <BackButton
              color="inherit"
              onClick={() => navigate("/assignments/failure")}
              label="Tillbaka"
            />
          }
          sx={{
            "& > .title": {
              "& > .MuiTypography-root": {
                lineHeight: 1,
              },
              rowGap: 1,
            },
            alignItems: "end",
          }}
        >
          {/* todo: Add org.name for anonymous access through sharing */}
          {assignment && (
            <AssignmentStatusChip editable assignment={assignment} />
          )}
        </PageHeader>
      </Header>
      {assignment ? (
        <>
          <Box
            sx={{
              px: 2,
              pb: 2,
            }}
          >
            <AssignmentDetails />
            {/* todo: If Comments enabled or there exists comments  */}
            <AssignmentCommentary />
          </Box>
        </>
      ) : (
        <NotFoundPage />
      )}
    </>
  );
};

export default SingleFailure;
