import { defaultMarkSettings } from "../../features/admin/MarkSettings";
import store from "../../store";
import { basColumns, getColumns } from "../bas";


const tableFields = [ "spot", "name", "phone", "boatBrand", "boatModel", "address", "city", "email", "width", "boatWidth", "boatLength", "boatDepth", "boatComment", "spotComment"].map((field, index) => ({field, visible: true, position: index}))

export const defaultColumnsSettings = [
  { title: "Datakolumner från import i tabell", field: 'bas', value: tableFields, valueType: 'array' },
  { title: "Datakolumner från import i detaljvy", field: 'details', value: getColumns('boatType', 'boatBrand', 'boatModel', 'name', 'phone'), valueType: 'array' },
]

// defaults inserted
export function getSettingsOverridenByCollections(settingsType, defaults, ...settingsCollections) {
  const marksSettingsFromCollection = []
    .concat(...settingsCollections
      .map(settingCollection => {
        const settings = settingCollection.filter(x => {
          return x.type === settingsType;
        })
        return settings
      }));
  // console.log(marksSettingsFromCollection)
  if (marksSettingsFromCollection.length > 0) {
    return defaults.map(def => {
      let settings;
      marksSettingsFromCollection.forEach(settingsSource => {
        // console.log('settingsSource: ', settingsSource) // ok
        const setting = settingsSource.settings.find(x => x.field === def.field);
        // console.log('setting: ', setting) // ok
        if (setting) {
          // console.log('default gets overriden by ', setting) // ok
          settings = {...def, ...setting};
        }
        // sista elementet i input listan har sista ordet ;)
      })
      if (settings) return settings 
      return def;
    });
  }
  return defaults;
}

export const getMarkSetting = (field) => {
  const orgSettings = store.getState().app.orgSettings;
  // todo add userSettings
  const settings = getSettingsOverridenByCollections('marks', defaultMarkSettings, orgSettings)
  return settings.find(c => c.field === field).value;
}

export function getDefaultOverridenValue(field, defaultSetting, overriddenSettingsCollection) {
  const setting = overriddenSettingsCollection.find(x => x.field === field);
  const defVal = defaultSetting;
  const newVal = setting.value;
  // console.log({defVal, newVal})
  if (typeof defVal === "object") {
    const result = Object.keys(defVal).map(key => {
      const index = newVal.findIndex(x => x.field === defVal[key].field);
      if (index === -1) return null
      else { // dvs värdet finns i db
        // console.log({defVal: defVal[key], newVal: newVal[index], index})
        return {...defVal[key], ...newVal[index]} // skriver över
      } 
    }).filter(x => x)
    // console.log('result', result)
    return result;
  } 
  return defVal
}