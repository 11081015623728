import { createSelector } from "reselect";
import { filterRowByDataFilters, filterRowByImportFilters, filterRowBySearchQuery } from "../data/filters";

import _ from "lodash";

const customers = state => state.app.customers
const boats = state => state.app.boats

export const getBoatOwners = createSelector(
    customers, boats,
    (customers, boats) => _.uniq(boats.filter(x => x.owner).map(x => x.owner.id)).map(ownerId => customers.find(x => x.id === ownerId)).filter(x => x)
  )

export const getNonBoatOwners = createSelector(
    getBoatOwners, customers,
    (boatOwners, customers) => _.differenceBy(customers, boatOwners, "id")
)

const data = state => state.app.import;
const activeImage = state => state.app.image;
const images = state => state.app.images;
const marks = state => state.app.marks;
const spots = state => state.app.spots;
const searchQuery = state => state.data.searchQuery;
const displayOnlyRowsOnView = state => state.data.displayOnlyRowsOnView;
const spotsInImage = state => getSpotsInActiveImage(state)
const activeDataFilters = state => getActiveDataFilters(state);
const activeImportFilters = state => getActiveImportFilters(state);
const rowsFromMarks = state => getRowsWithDataAndFieldsFromMarks(state)
const rowsInImage = state => getRowsInImage(state)


export const getSpotsInActiveImage = createSelector(
  marks, activeImage,
  (marks, activeImage) => marks
    .filter(m => m.image === activeImage?.id)
    .filter(m => m.spot)
    .map(m => m.spot)
)

export const getActiveDataFilters = createSelector(
  state => state.data.dataFilters,
  filters => filters.filter(x => x.active)
)

export const getCRMSpotsInImage = createSelector(
  spotsInImage,
  spots,
  (spotsInImage, spots) => spots
    .filter(s => spotsInImage.includes(s.spotNumber))
)

export const getRowsInImage = createSelector(
  spotsInImage,
  data,
  (spotsInImage, data) => data
    .filter(row => spotsInImage.includes(row.spot))
)

export const getEmptySpotsInImage = createSelector(
  rowsInImage,
  (rowsInImage) => rowsInImage.filter(x => !x.firstname).map(x => x.spot)
)

export const getActiveImportFilters = createSelector(
  state => state.data.importFilters,
  filters => filters.filter(x => x.active)
)

export const getRowsWithDataAndFieldsFromMarks = createSelector(
  marks,
  data,
  (marks, data) => marks
    .filter(m => m?.spot) // only marks assigned to spot
    .filter(m => m.data)
    .map(m => ({
      row: data.find(x => x.spot === m.spot),
      data: m.data,
      fields: m.fields
    }))
)

export const getFilteredData = createSelector(
  data,
  searchQuery,
  displayOnlyRowsOnView,
  spotsInImage,
  activeDataFilters,
  activeImportFilters,
  rowsFromMarks,
  (data, searchQuery, displayOnlyRowsOnView, spotsInImage, activeDataFilters,
    activeImportFilters, rowsFromMarks) =>
    data
      .filter(row => displayOnlyRowsOnView ? spotsInImage.includes(row.spot) : true)
      .filter(row => filterRowBySearchQuery(row, searchQuery))
      .filter(row => filterRowByImportFilters(row, activeImportFilters))
      .filter(row => filterRowByDataFilters(row, activeDataFilters, rowsFromMarks))
)

export const getMasterImage = createSelector(
 images,
  (images) => images.find(img => img?.master)
)