import { Add } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  ListItem,
  Popper,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import PopperContent from "../../../Components/PopperContent";
import SlimChip from "../../../Components/utils/SlimChip";
import { AssignmentContext } from "../AssignmentProvider";

interface Props {
  editable?: boolean;
}

const Labels: FC<Props> = (props) => {
  const { editable } = props;
  const { assignment, updateAssignment } = useContext(AssignmentContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [labelToAdd, setLabelToAdd] = useState<string>("");

  const handleClick = (event?: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event ? (anchorEl ? null : event.currentTarget) : null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "label-popper" : undefined;

  const labels = assignment?.labels ?? [];

  const handleAddLabel = async () => {
    if (labelToAdd) {
      try {
        await updateAssignment({ labels: labels.concat(labelToAdd) });
        handleClick();
        setLabelToAdd("");
      } catch (err) {
        enqueueSnackbar(
          t("assignment.update-property-error", {
            defaultValue: "Fel uppstod. Kunde inte uppdatera egenskap.",
          }),
          { variant: "error" }
        );
      }
    }
  };
  return (
    <>
      {editable && (
        <IconButton
          sx={{ height: "24px", width: "24px" }}
          onClick={handleClick}
        >
          <Add />
        </IconButton>
      )}
      {labels.map((label) => (
        <SlimChip
          key={label}
          label={label}
          onDelete={
            editable
              ? () =>
                  updateAssignment({
                    labels: labels.filter((x) => x !== label),
                  })
              : undefined
          }
          color={theme.palette.secondary.main}
        />
      ))}
      {editable && (
        <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-end">
          <PopperContent
            heading="Lägg till kategori"
            handleClick={() => handleClick()}
          >
            <Autocomplete
              size="small"
              value={labelToAdd}
              onInputChange={(e, value) => setLabelToAdd(value ?? "")}
              // todo fyll på med tidigare använda
              options={["El", "Vatten", "Brygga", "Förtöjning"]}
              getOptionDisabled={(label) => labels.includes(label)}
              renderOption={(params, label) => (
                <ListItem {...params}>
                  <SlimChip
                    clickable
                    color={theme.palette.secondary.main}
                    label={label}
                  />
                </ListItem>
              )}
              freeSolo
              forcePopupIcon
              noOptionsText={t("workAssignment.category_empty", {
                defaultValue: "Hittar ingen att tilldela till",
              })}
              renderInput={(params) => (
                <TextField
                  sx={{ "& > input, div": { background: "#fff" } }}
                  label={t("workAssignment.category", {
                    defaultValue: "Kategori",
                  })}
                  {...params}
                />
              )}
            />
            <Tooltip
              title={
                labels.includes(labelToAdd)
                  ? "Kategori redan tillagd"
                  : "Skriv ovan för att skapa ny kategori eller lägga till befintlig"
              }
            >
              <Box>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  startIcon={<Add />}
                  disabled={!labelToAdd || labels.includes(labelToAdd)}
                  onClick={handleAddLabel}
                >
                  Lägg till
                </Button>
              </Box>
            </Tooltip>
          </PopperContent>
        </Popper>
      )}
    </>
  );
};

export default Labels;
