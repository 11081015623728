import { Typography } from "@mui/material";

export function HelpTexts(modes) {
  return (
    <>
      {modes.includes("add") && (
        <Typography variant="body1" className="help">
          Klicka på bilden för att lägga till ny plats
        </Typography>
      )}
      {modes.includes("edit") && (
        <Typography variant="body1" className="help">
          Klicka på en befintlig plats för att göra ändringar. <br /> Markera en
          punkt för att kunna flytta.
        </Typography>
      )}
      {modes.includes("move") && (
        <Typography variant="body1" className="help">
          Klicka igen för att ge markerad plats en ny position. Klicka på en ny
          plats för att fortsätta att flytta.
        </Typography>
      )}
    </>
  );
}
