import {
  Assignment,
  AssignmentCompact,
  Comment,
  CommentDraft,
  MinimalMark,
} from "@hamnvy/shared";
import { AxiosResponse } from "axios";
import { uniqBy } from "lodash";
import { createContext, FC, ReactNode, useEffect, useState } from "react";
import { api } from "../../services/api";
import { getIdsFromFilePreviews } from "../../services/utils/files";

export type AssignmentContextType = {
  assignment?: Assignment;
  comments: Comment[];
  assignments: Assignment[];
  getAssignments: () => void;
  getSingleAssignment: (id: string) => void;
  commentAssignment: (
    comment: CommentDraft
  ) => Promise<string | undefined> | undefined;
  updateAssignment: (
    payload: Partial<AssignmentCompact>
  ) => Promise<void> | undefined;
  deleteAssignment: (id?: string) => Promise<AxiosResponse> | undefined;
  marks: MinimalMark[];
  isEditing: boolean;
  setEditing: (status: boolean) => void;
  appendAssignment: (assignment: Assignment) => void;
};
export const AssignmentContext = createContext<AssignmentContextType>({
  assignments: [],
  comments: [],
  getAssignments: () => null,
  getSingleAssignment: (id) => null,
  commentAssignment: (comment) => undefined,
  updateAssignment: (payload) => undefined,
  deleteAssignment: (id) => undefined,
  marks: [],
  isEditing: false,
  setEditing: (status: boolean) => null,
  appendAssignment: (assignment: Assignment) => null,
});

const AssignmentProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [assignment, setOpenAssignment] = useState<Assignment | undefined>();
  const [assignments, setAssignments] = useState<Assignment[]>([]);
  const [comments, setComments] = useState<Comment[]>([]);
  const [isEditing, setEditing] = useState<boolean>(false);
  const marks =
    uniqBy(
      assignments.flatMap((x) => x.marks),
      "id"
    ) ?? [];

  useEffect(() => {
    getAssignments();
  }, []);

  const getAssignments = async () => {
    try {
      const result = await api.get("/assignments");
      const data: Assignment[] = result.data?.assignments ?? [];
      setAssignments(data);
    } catch (err) {
      console.log(err);
    }
  };

  const getSingleAssignment = async (id: string) => {
    try {
      const result = await api.get(`/assignments/${id}`);
      const main: Assignment = result.data?.assignment;
      const comments: Comment[] = result.data?.comments;
      setOpenAssignment(main);
      setComments(comments);
    } catch (err) {
      console.log(err);
    }
  };

  const commentAssignment = async (data: CommentDraft) => {
    try {
      if (!assignment) return;
      const result = await api.post(`/assignments/${assignment.id}/comment`, {
        ...data,
        attachments: getIdsFromFilePreviews(data.attachments),
        createdAt: new Date(),
      });
      const comment: Comment = result.data?.comment;
      setOpenAssignment({
        ...assignment,
        comments: assignment.comments.concat(comment.id),
      });
      setComments([...comments, { ...comment, attachments: data.attachments }]);
      return Promise.resolve(comment.id);
    } catch (err) {
      return Promise.reject("error");
    }
  };

  const updateAssignment = async (payload: Partial<AssignmentCompact>) => {
    try {
      let id: string = "";
      if (payload.id) id = payload.id;
      else if (assignment) id = assignment.id;
      else return Promise.reject();
      const result = await api.patch(`/assignments/${id}`, payload);
      const data: Assignment = result.data?.assignment;
      setOpenAssignment(data);
      return Promise.resolve();
    } catch (err) {
      console.log(err);
      return Promise.reject();
    }
  };

  const deleteAssignment = (id?: string) => {
    let targetId = id;
    if (!targetId) {
      if (assignment) targetId = assignment.id;
      else return;
    }
    return api.delete(`/assignments/${targetId}`);
  };

  const appendAssignment = (toAdd: Assignment) => {
    setAssignments(assignments.concat(toAdd));
  };

  return (
    <AssignmentContext.Provider
      value={{
        assignment,
        comments,
        getAssignments,
        commentAssignment,
        getSingleAssignment,
        updateAssignment,
        deleteAssignment,
        assignments,
        marks,
        isEditing,
        setEditing,
        appendAssignment,
      }}
    >
      {children}
    </AssignmentContext.Provider>
  );
};

export default AssignmentProvider;
