import { Edit } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";

export default function EditButton(callback, options = {}) {
  return (
    <Tooltip title={options?.title || "Redigera"}>
      <Box
        sx={{ display: "flex", alignItems: "center", justifyItems: "center" }}
      >
        <IconButton
          onClick={() => callback()}
          title={null}
          size="small"
          {...options}
        >
          <Edit />
        </IconButton>
      </Box>
    </Tooltip>
  );
}
