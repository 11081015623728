import { Tooltip } from "@mui/material";
import _ from "lodash";
import React, { CSSProperties } from "react";
import theme from "../../../styles/theme";
import Bar from "./bar";
import mockBars from "./mockBars";

type Props = {
  bars?: Bar[];
  defaultColor?: string;
  textColor?: string;
  size?: string; // height or width depending on type
  type?: ChartType;
  thickness?: number;
  valueLabels?: boolean;
  labels?: "tooltip" | "visible";
};

export type ChartType =
  | "top-bottom"
  | "bottom-top"
  | "left-right"
  | "right-left";

const BarChart = (props: Props) => {
  const {
    bars = mockBars,
    defaultColor = theme.palette.primary.main,
    textColor = "#FFF",
    size = "100%",
    type = "bottom-top",
    thickness = 20,
    valueLabels = false,
    labels = "visible",
  } = props;
  const max = _.max(bars.map((x) => x.value)) ?? 0;
  const getTypeStyle = (): CSSProperties => {
    switch (type) {
      case "bottom-top":
        return { alignItems: "flex-end", gridAutoFlow: "column" };
      case "top-bottom":
        return { alignItems: "flex-start", gridAutoFlow: "column" };
      case "left-right":
        return {
          gridTemplateColumns: `${labels === "visible" ? "auto " : ""}1fr`,
          alignItems: "center",
        };
      case "right-left":
        return {
          gridTemplateColumns: `1fr${labels === "visible" ? " auto" : ""}`,
          justifyItems: "end",
          alignItems: "center",
        };
      default:
        return {};
    }
  };
  return (
    <div style={{ display: "grid", gap: 8, ...getTypeStyle() }}>
      {bars.map((bar, i) => (
        <React.Fragment key={i}>
          {type === "left-right" && labels === "visible" && (
            <span style={{ userSelect: "none", ...bar.labelStyle }}>
              {bar?.label}
            </span>
          )}
          <Tooltip title={bar?.tooltip ?? ""}>
            <div
              style={{
                backgroundColor: bar?.color ?? defaultColor,
                ...(["left-right", "right-left"].includes(type)
                  ? {
                      width: `calc((${size} * ${bar.value}) / ${max})`,
                      height: thickness,
                      display: "flex",
                      justifyContent:
                        type === "left-right" ? "flex-end" : "flex-start",
                    }
                  : {
                      height: `calc((${size} * ${bar.value}) / ${max})`,
                      width: thickness,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent:
                        type === "top-bottom" ? "flex-end" : "flex-start",
                    }),
                alignItems: "center",
                color: bar?.textColor ?? textColor,
                userSelect: "none",
                cursor: bar?.onClick ? "pointer" : "unset",
                ...bar.barStyle,
              }}
              onClick={() => bar?.onClick && bar.onClick()}
            >
              {valueLabels && (
                <span style={{ margin: 8 }}>{bar.value.toFixed(0)}</span>
              )}
            </div>
          </Tooltip>
          {type === "right-left" && labels === "visible" && (
            <span style={{ userSelect: "none" }}>{bar?.label}</span>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default BarChart;
