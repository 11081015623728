import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { MinusButton } from "../../Components/utils";
import { basColumns } from "../../services/bas";
import {
  defaultColumnsSettings,
  getDefaultOverridenValue,
  getSettingsOverridenByCollections,
} from "../../services/utils/settings";
import { addOrganisationSettings, removeOrganisationSettings } from "../app";
import { addUserSettings } from "../app/appApi";
import { AddBasDetailsField } from "../map/MapView/DisplayDetails";

export default function SetDetailsColumns({ settingsFor = "user" }) {
  const dispatch = useDispatch();
  const detailsColumns = useSelector((state) => state.app.detailsColumns);
  const orgSettings = useSelector((state) => state.app.orgSettings);
  let columns = detailsColumns;
  // console.log('columns before', columns)

  if (settingsFor === "organisation") {
    const settings = getSettingsOverridenByCollections(
      "columns",
      defaultColumnsSettings,
      orgSettings
    );
    // console.log('org overrides, settings: ', settings)

    columns = getDefaultOverridenValue("details", basColumns, settings);
    // console.log('gets columns: ', columns)
  }

  const handleAdd = (field) => {
    if (settingsFor === "organisation")
      dispatch(
        addOrganisationSettings({
          type: "columns",
          settingsGroup: "details",
          settings: [{ field }],
        })
      );
    else if (settingsFor === "user")
      dispatch(
        addUserSettings({
          type: "columns",
          settingGroup: "details",
          settings: [{ field }],
        })
      );
  };

  return (
    <Card>
      <CardHeader title="Detaljkolumner" />
      <CardContent>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Visningsnamn</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {columns.map((column, index) => (
                <TableRow
                  key={"admin-detail-bascolumn-row-" + column.field + index}
                >
                  <TableCell>
                    {MinusButton(() =>
                      dispatch(
                        removeOrganisationSettings({
                          type: "columns",
                          settingsGroup: "details",
                          settings: [{ field: column.field }],
                        })
                      )
                    )}
                  </TableCell>
                  <TableCell>{column.title}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={2}>
                  <AddBasDetailsField
                    fields={columns.map((x) => x.field)}
                    onChange={(e, selected) =>
                      selected ? handleAdd(selected.field) : null
                    }
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
