import {
  Add,
  Close,
  HelpOutlineTwoTone,
  HighlightOff,
} from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Container,
  DialogContent,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DialogWithCloseButton } from "../../Components/Dialog";
import { ConfirmDialog, DeleteButton } from "../../Components/utils";
import { justDateString } from "../../services/utils/dates";
import theme from "../../styles/theme";
import {
  deletePermanentGuestSpot,
  getGuestSpots,
  getLatestBasImport,
  postPermanentGuestSpots,
  postTemporaryGuestSpot,
} from "../app";
import { GenerateSpots } from "../crm/manageSpots";
import { StickyHeadTable } from "../data/Tables/Tables";
import Header from "../main/Header";
import PageHeader from "../main/PageHeader";
import DatePickerWithArrows from "./DatePickerWithArrows";
import LimitedGuestSpotForm from "./forms/LimitedGuestSpotForm";
import TemporarySpotsTable from "./tables/TemporarySpotsTable";
import TimeLimitedSpotsTable from "./tables/TimeLimitedSpotsTable";

export default function SpotsAvailable() {
  const { t } = useTranslation();
  const notMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const user = useSelector((s) => s.app.user);
  const guestSpots = useSelector((s) => s.app.guestSpots);
  const basImport = useSelector((s) => s.app.import);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.org.usingBAS && basImport.length === 0)
      dispatch(getLatestBasImport());
    dispatch(getGuestSpots());
  }, []);

  const [selectedDate, setDate] = useState(new Date());
  const [isDeletingPermanent, setDeletingPermanent] = useState(null);
  const [isAdding, setAdding] = useState(false);
  const [activeForm, setForm] = useState(null);

  const handleDateChange = (date) => {
    setDate(date);
    const dateOnly = justDateString(date.toISOString());
    dispatch(getGuestSpots(dateOnly));
  };

  const handleSuccessForm = () => {
    dispatch(getGuestSpots(justDateString(selectedDate.toISOString())));
    setForm(null);
  };

  const permanent = []
    .concat(guestSpots.permanent)
    .sort((a, b) => a.spotNumber.localeCompare(b.spotNumber)); //, undefined, {numeric: true}));

  const [tempHelp, setTempHelp] = useState(false);
  const TemporaryGuestSpots = (
    <div>
      <Typography variant="h5">
        Lediga tillsvidare
        <IconButton
          aria-label="Hjälp"
          size="small"
          onClick={() => setTempHelp(!tempHelp)}
        >
          {tempHelp ? <HighlightOff /> : <HelpOutlineTwoTone />}
        </IconButton>
      </Typography>
      {tempHelp && (
        <Typography variant="body1">
          Här listas alla båtplatser som antingen saknar båtplatsinnehavare
          eller där båtplatsinnehavare för nävarande inte har någon båt på
          platsen.
        </Typography>
      )}
      <TemporarySpotsTable date={selectedDate} />
    </div>
  );

  return (
    <>
      <ConfirmDialog
        title="Ta bort permanent gästplats?"
        text={t("permanentGuestSpot.delete_confirm_description", {
          defaultValue:
            "Denna operation gör att platsen försvinner från dagar från och med valt datum, {{date}}.",
          date: justDateString(selectedDate.toISOString()),
        })}
        open={isDeletingPermanent}
        setOpen={setDeletingPermanent}
        onConfirm={() =>
          dispatch(
            deletePermanentGuestSpot({
              id: isDeletingPermanent,
              date: justDateString(selectedDate.toISOString()),
            })
          )
        }
      />
      <Header>
        <PageHeader title="Gästplatser">
          <DatePickerWithArrows
            onDateChange={(date) => handleDateChange(date)}
            date={selectedDate}
            sx={{
              "& .MuiInputBase-root": { backgroundColor: "white !important" },
              "& > .MuiButtonBase-root": {
                // color: "white" 
              },
            }}
          />
        </PageHeader>
      </Header>
      <Container style={{ display: "grid", marginTop: 16, gap: 16 }}>
        <Button
          style={{ width: "fit-content" }}
          startIcon={!isAdding ? <Add /> : <Close />}
          onClick={() => {
            setAdding(!isAdding);
            setForm(null);
          }}
          color={!isAdding ? "success" : undefined}
          variant={!isAdding ? "contained" : "text"}
        >
          {!isAdding ? "Registrera plats som ledig" : "Stäng"}
        </Button>
        {isAdding && (
          <>
            <div
              className="type-of-availability-buttons"
              style={{ display: "flex", flexWrap: "wrap", gap: 4 }}
            >
              <Button
                variant={activeForm === "limited" ? "contained" : "outlined"}
                color="primary"
                onClick={() => setForm("limited")}
              >
                Tidsbegränsat
              </Button>
              <Button
                variant={activeForm === "temporary" ? "contained" : "outlined"}
                color="primary"
                onClick={() => setForm("temporary")}
              >
                Tillsvidare
              </Button>
              {user.role?.rights?.includes("readwrite") && (
                <Button
                  variant={
                    activeForm === "permanent" ? "contained" : "outlined"
                  }
                  color="primary"
                  onClick={() => setForm("permanent")}
                >
                  Permanent
                </Button>
              )}
            </div>
            <div className="active-form">
              {activeForm === "limited" && (
                <LimitedGuestSpotForm onSuccess={() => handleSuccessForm()} />
              )}
              {activeForm === "temporary" && (
                <TemporaryGuestSpotForm onSuccess={() => handleSuccessForm()} />
              )}
              {activeForm === "permanent" && (
                <div style={{ display: "grid", gap: 16 }}>
                  <PermanentGuestSpotForm
                    onSuccess={() => handleSuccessForm()}
                    showGenerator
                  />
                </div>
              )}
            </div>
          </>
        )}

        <div>
          <Typography variant="h5">Tidsbegränsat lediga</Typography>
          <TimeLimitedSpotsTable />
        </div>

        {TemporaryGuestSpots}

        <div>
          <Typography variant="h5">Permanenta gästplatser</Typography>
          <StickyHeadTable
            stickyHeaderRow={
              <>
                <TableCell>Plats</TableCell>
                <TableCell></TableCell>
              </>
            }
            emptyText="Inga permanenta gästplatser kunde hittas"
            size="small"
          >
            {permanent.map((s) => (
              <TableRow key={"row-" + s.id}>
                <TableCell>{s.spotNumber}</TableCell>
                <TableCell align="right" padding="none">
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    {notMobile &&
                      DeleteButton(() => setDeletingPermanent(s.id), {
                        size: "small",
                      })}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </StickyHeadTable>
        </div>
      </Container>
    </>
  );
}

function TemporaryGuestSpotForm({ onSuccess }) {
  const basImport = useSelector((s) => s.app.import);
  const dispatch = useDispatch();

  const [spot, setSpot] = useState(null);
  const [note, setNote] = useState("");
  const [error, setError] = useState(null);

  const publish = async () => {
    const formData = {
      spotNumber: typeof spot === "string" ? spot : spot.spot,
      note,
    };
    const result = await dispatch(postTemporaryGuestSpot(formData));
    if (result.meta.requestStatus === "fulfilled") onSuccess();
    else if (result.meta.rejectedWithValue) {
      setError(result.payload);
    }
  };

  return (
    <div style={{ display: "grid", gap: 8, maxWidth: 400 }}>
      <DialogWithCloseButton
        open={error}
        onClose={() => setError(null)}
        title="Fel inträffade"
      >
        <DialogContent>
          <p>{error?.message}</p>
          {error?.list && (
            <ul>
              {error.list.map((x, i) => (
                <li key={"el" + i}>{x}</li>
              ))}
            </ul>
          )}
        </DialogContent>
      </DialogWithCloseButton>
      <Autocomplete
        style={{ minWidth: 200 }}
        options={basImport}
        value={spot}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.spot + " " + option.name
        }
        noOptionsText="Inga platser hittades"
        renderInput={(params) => (
          <TextField
            {...params}
            label="Sök efter plats/innehavare"
            variant="outlined"
          />
        )}
        onChange={(event, option) => setSpot(option)}
        selectOnFocus={true}
      />
      <TextField
        multiline
        type="text"
        variant="outlined"
        label="Anteckning"
        value={note}
        onChange={(e) => setNote(e.target.value)}
      />
      <Button
        disabled={!spot}
        variant="contained"
        color="primary"
        onClick={() => publish()}
      >
        Registrera
      </Button>
    </div>
  );
}

function PermanentGuestSpotForm({ onSuccess, showGenerator = false }) {
  const basImport = useSelector((s) => s.app.import);
  const dispatch = useDispatch();

  const [spot, setSpot] = useState(null);
  const [error, setError] = useState(null);

  const publish = async (spots = null) => {
    let formData = {};
    if (!spots) {
      formData = { spotNumber: typeof spot === "string" ? spot : spot.spot };
    }
    const result = await dispatch(postPermanentGuestSpots(spots || [formData]));
    if (result.meta.requestStatus === "fulfilled") onSuccess();
    else if (result.meta.rejectedWithValue) setError(result.payload);
    if (spots) return result;
  };

  return (
    <div style={{ display: "grid", gap: 8, maxWidth: 400 }}>
      <DialogWithCloseButton
        open={error}
        onClose={() => setError(null)}
        title="Fel uppstod"
      >
        <DialogContent>
          <p>{error?.message}</p>
          {error?.list && (
            <ul>
              {error.list.map((x, i) => (
                <li key={"errorlist-" + i}>{x}</li>
              ))}
            </ul>
          )}
        </DialogContent>
      </DialogWithCloseButton>
      <Autocomplete
        style={{ minWidth: 200 }}
        options={basImport}
        value={spot}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.spot + " " + option.name
        }
        noOptionsText="Inga platser hittades"
        renderInput={(params) => (
          <TextField
            {...params}
            label="Sök efter plats/innehavare"
            variant="outlined"
          />
        )}
        onChange={(event, option) => setSpot(option)}
        selectOnFocus={true}
      />
      <Button
        disabled={!spot}
        variant="contained"
        color="primary"
        onClick={() => publish()}
      >
        Registrera
      </Button>
      {showGenerator && (
        <div>
          <p>Alternativt registrera många: </p>
          <GenerateSpots
            onSuccess={() => onSuccess()}
            onSubmit={(spots) => publish(spots)}
          />
        </div>
      )}
    </div>
  );
}
