import { IUser } from "@hamnvy/shared";
import {
  Button,
  Table,
  TableBody,
  TableContainer,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import { pick } from "lodash";
import { useSnackbar } from "notistack";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import { useAppDispatch } from "../../../hooks";
import { PreviewUser } from "../../../types";
import { updateUser } from "../../app";
import EmailField from "../form_fields/EmailField";
import FirstnameField from "../form_fields/FirstnameField";
import LastnameField from "../form_fields/LastnameField";
import PhoneField from "../form_fields/PhoneField";
import UsernameField from "../form_fields/UsernameField";
import TKey from "./TKey";
import TVal from "./TVal";

interface Props {
  user: IUser;
  onClose: () => void;
}

const EditableUserDetails: FC<Props> = (props) => {
  const { user, onClose } = props;
  const methods = useForm<{
    user: PreviewUser;
  }>({ defaultValues: { user } });
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const {
    formState: { touchedFields, dirtyFields },
    getValues,
  } = methods;

  const handleSubmit = async () => {
    try {
      const changedFields = Object.keys(dirtyFields?.user ?? {});
      const values = getValues().user;
      const changes = pick(values, changedFields);
      // @ts-expect-error
      await dispatch(updateUser({ id: user.id, ...changes }));
      enqueueSnackbar("Användaren uppdaterad.", { variant: "success" });
      onClose();
    } catch (error) {
      enqueueSnackbar("Kunde inte uppdatera användaren.", { variant: "error" });
    }
  };

  return (
    <FormProvider {...methods}>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TKey>
                <Trans i18nKey="user.username">Användarnamn</Trans>
              </TKey>
              <TVal>
                <UsernameField size="small" />
              </TVal>
            </TableRow>
            <TableRow>
              <TKey>
                <Trans i18nKey="user.name">Namn</Trans>
              </TKey>
              <TVal>{user?.name ?? "-"}</TVal>
            </TableRow>
            <TableRow>
              <TKey>
                <Trans i18nKey="user.firstname">Förnamn</Trans>
              </TKey>
              <TVal>
                <FirstnameField size="small" />
              </TVal>
            </TableRow>
            <TableRow>
              <TKey>
                <Trans i18nKey="user.lastname">Efternamn</Trans>
              </TKey>
              <TVal>
                <LastnameField size="small" />
              </TVal>
            </TableRow>
            <TableRow>
              <TKey>
                <Trans i18nKey="user.email">Epostadress</Trans>
              </TKey>
              <TVal>
                <EmailField size="small" />
              </TVal>
            </TableRow>
            <TableRow>
              <TKey>
                <Trans i18nKey="user.phone">Telefon</Trans>
              </TKey>
              <TVal>
                <PhoneField size="small" />
              </TVal>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ p: 2, display: "flex", gap: 2 }}>
        <Button color="primary" variant="contained" onClick={handleSubmit}>
          Spara ändringar
        </Button>
        <Button onClick={onClose}>Avbryt</Button>
      </Box>
    </FormProvider>
  );
};

export default EditableUserDetails;
