import { Add } from "@mui/icons-material";
import { Button, DialogContent } from "@mui/material";
import { Box } from "@mui/system";
import { FC, useContext, useState } from "react";
import { DialogWithCloseButton } from "../../../Components/Dialog";
import Header from "../../main/Header";
import PageHeader from "../../main/PageHeader";
import { AssignmentContext } from "../AssignmentProvider";
import WorkAssignmentForm from "../WorkAssignmentForm";
import FailuresMap from "./FailuresMap";
import ListAssignmentPreview from "./ListAssignmentPreview";

interface Props {}

const FailurePage: FC<Props> = (props) => {
  const { appendAssignment } = useContext(AssignmentContext);
  const [isAdding, setAdding] = useState<boolean>(false);

  return (
    <>
      <DialogWithCloseButton
        title="Ny felanmälan"
        open={isAdding}
        onClose={() => setAdding(false)}
      >
        <DialogContent>
          <Box paddingTop={1}>
            <WorkAssignmentForm
              disableSpot
              onCancel={() => setAdding(false)}
              onSuccess={(assignment) => {
                setAdding(false);
                appendAssignment(assignment);
              }}
            />
          </Box>
        </DialogContent>
      </DialogWithCloseButton>
      <Header>
        <PageHeader title="Felanmälningar">
          <Button
            startIcon={<Add />}
            color="inherit"
            sx={{ "& .MuiButton-startIcon": { mr: 0.5 } }}
            onClick={() => setAdding(!isAdding)}
          >
            Skapa
          </Button>
        </PageHeader>
      </Header>
      <Box sx={{ px: 2 }}>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
          <ListAssignmentPreview />
          <FailuresMap />
        </Box>
      </Box>
    </>
  );
};

export default FailurePage;
