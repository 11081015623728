import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { addImportFilter } from "..";
import { DownButton, UpButton } from "../../../Components/utils";
import { basColumns } from "../../../services/bas";
import { getActiveImportFilters } from "../../app/appSelectors";

export default function BasColumns({ columns, onChange }) {
  const fields = columns.map((c) => c.field);

  function handleCheckbox(event, column) {
    const newColumns = basColumns.filter((col) =>
      col.field === column.field
        ? event.target.checked
        : fields.includes(col.field)
    );
    onChange(newColumns);
  }

  function moveUp(field) {
    const index = fields.indexOf(field);
    if (index !== 0) {
      const fc = columns.find((x) => x.field === field);
      const newColumns = [
        ...columns.slice(0, index - 1),
        fc,
        columns[index - 1],
        ...columns.slice(index + 1),
      ];
      onChange(newColumns);
    }
  }

  function moveDown(field) {
    const index = fields.indexOf(field);
    if (index !== 0) {
      const fc = columns.find((x) => x.field === field);
      const newColumns = [
        ...columns.slice(0, index),
        columns[index + 1],
        fc,
        ...columns.slice(index + 2),
      ];
      onChange(newColumns);
    }
  }

  return (
    <TableContainer
      component={Paper}
      style={{ maxHeight: 500, overflowY: "auto", maxWidth: 400 }}
    >
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left">Visa</TableCell>
            <TableCell>Namn</TableCell>
            <TableCell align="right">Flytta</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fields
            .map((field) => basColumns.find((c) => c.field === field))
            .concat(basColumns.filter((c) => !fields.includes(c.field)))
            .map((column) => (
              <TableRow key={column.field}>
                <TableCell align="left" padding="none">
                  <Checkbox
                    checked={fields.includes(column.field)}
                    onChange={(e) => handleCheckbox(e, column)}
                  />
                </TableCell>
                <TableCell>{column.title}</TableCell>
                <TableCell padding="none">
                  {fields.includes(column.field) && (
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {UpButton(() => moveUp(column.field), {
                        size: "small",
                        disabled: fields.indexOf(column.field) === 0,
                      })}
                      {DownButton(() => moveDown(column.field), {
                        size: "small",
                        disabled:
                          fields.indexOf(column.field) === fields.length - 1,
                      })}
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function SelectBasColumn({ ...rest }) {
  const dispatch = useDispatch();
  const activeBasFilters = useSelector((state) =>
    getActiveImportFilters(state)
  );
  return (
    <Tooltip
      title={rest?.title || "Sök eller välj i listan."}
      placement="right"
    >
      <Autocomplete
        size="small"
        value={null}
        style={{ minWidth: 200 }}
        options={basColumns}
        getOptionLabel={(option) => option.title}
        noOptionsText="Inga egna datafält hittades"
        renderInput={(params) => (
          <TextField
            {...params}
            label={rest?.label || "Lägg till filter på BAS-kolumner"}
            variant="outlined"
          />
        )}
        onChange={(event, field) =>
          field ? dispatch(addImportFilter({ field })) : null
        }
        selectOnFocus={true}
      />
    </Tooltip>
  );
}
