import { INotification, NotificationType } from "@hamnvy/shared";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonProps,
  IconButton,
  ListItem,
  SxProps,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { CSSProperties, SyntheticEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HamnvyFlagSquare } from "../../Components/Logos";
import { EditButton } from "../../Components/utils";
import { useAppDispatch } from "../../hooks";
import theme from "../../styles/theme";
import { postNotificationStats } from "../app";
import { UserIcon } from "../user/User";
import CompactNotificationStats from "./components/CompactNotificationStats";

const rootStyle: SxProps = {
  padding: 1,
  paddingRight: 2,
  borderTopLeftRadius: "1px",
  borderBottomLeftRadius: "1px",
  display: "grid",
  gridTemplateColumns: "20px 1fr auto",
  gap: 2,
  userSelect: "none",
};

type Props = {
  notifs: INotification[];
};

function NotificationsContainer({ notifs }: Props) {
  return (
    <div style={{ width: "100%", padding: 4, display: "grid", gap: 4 }}>
      {notifs.map((x) => (
        <NotificationRow notif={x} key={x.id} />
      ))}
      {notifs.length === 0 && <ListItem>Inga notiser.</ListItem>}
    </div>
  );
}

const getNotifColor = (variant: NotificationType) => {
  const colors = {
    sys: theme.palette.primary.main,
    org_msg: theme.palette.secondary.main,
    usr_msg: theme.palette.warning.main,
    biz: "#000",
    grp_msg: theme.palette.success.main,
  };
  return colors[variant];
};

export function NotificationRow({
  notif,
  history = false,
}: {
  notif: INotification;
  history?: boolean;
}) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [renderedAt] = useState(new Date());
  const [expandedAt, setExpandedAt] = useState<Date | null>(null);

  const handleDismiss = (e: SyntheticEvent): void => {
    e.stopPropagation();
    if (!history) {
      dispatch(
        postNotificationStats(
          // @ts-ignore
          {
            notification: notif.id,
            renderedAt,
            expandedAt,
            dismissedAt: new Date(),
          }
        )
      );
    }
  };

  if (!history) {
    useEffect(() => {
      if (renderedAt)
        dispatch(
          postNotificationStats(
            // @ts-ignore
            {
              notification: notif.id,
              renderedAt,
            }
          )
        );
    }, [renderedAt]);

    useEffect(() => {
      if (expandedAt)
        dispatch(
          postNotificationStats(
            // @ts-ignore
            {
              notification: notif.id,
              renderedAt,
              expandedAt,
            }
          )
        );
    }, [expandedAt]);
  }

  const handleOpen = (e: SyntheticEvent): void => {
    if (open) {
      setOpen(false);
    } else {
      if (!history) setExpandedAt(new Date());
      setOpen(true);
    }
  };

  return (
    <ListItem
      button
      sx={{
        ...rootStyle,
        borderLeft: `2px solid ${getNotifColor(notif.variant)}`,
      }}
      onClick={handleOpen}
    >
      {notif?.createdBy ? (
        <UserIcon user={notif.createdBy} size={20} />
      ) : (
        <HamnvyFlagSquare size={20} />
      )}
      <div>
        <Typography sx={{ fontWeight: 500, lineHeight: "unset" }}>
          {notif.title}
        </Typography>
        {history && (
          <Typography variant="overline" sx={{ lineHeight: "unset" }}>
            {format(new Date(notif.createdAt), "yyyy-MM-dd HH:mm")}
          </Typography>
        )}
      </div>
      <IconButton
        style={{ height: 20, width: 20, justifySelf: "end" }}
        size="large"
      >
        {open ? <ExpandLess /> : <ExpandMore />}
      </IconButton>
      {open && (
        <>
          <Box sx={{ gridColumn: "1/3", alignSelf: "end" }}>
            <Typography>{notif?.description}</Typography>
            {/* @ts-ignore */}
            {history && <CompactNotificationStats notif={notif} />}
            <Typography variant="caption">
              {notif?.createdBy &&
                `${history ? "Avsändare: " : ""}` +
                (notif.createdBy?.name || notif.createdBy.username)}
              {!history &&
                ", " + format(new Date(notif.createdAt), "yyyy-MM-dd HH:mm")}
            </Typography>
          </Box>
          {!history ? (
            <NotificationActionButton
              background={getNotifColor(notif.variant)}
              onClick={handleDismiss}
            >
              Läst
            </NotificationActionButton>
          ) : (
            EditButton(() => null, { size: "small" })
          )}
          {notif?.url && <NotificationActionButton
            background={getNotifColor(notif.variant)}
            onClick={() => navigate(notif?.url ?? "#")}
          >
            Öppna
          </NotificationActionButton>}
        </>
      )}
    </ListItem>
  );
}

interface NotificationActionButtonProps {
  background: CSSProperties["background"];
}

const NotificationActionButton = (
  props: NotificationActionButtonProps & ButtonProps
) => {
  const { background, ...rest } = props;
  return (
    <Button
      {...rest}
      variant="contained"
      style={{
        gridColumn: "3/4",
        gridRow: "2/3",
        alignSelf: "end",
        color: "#FFF",
        background,
        padding: 2,
      }}
    >
      {props.children}
    </Button>
  );
};

export default NotificationsContainer;
