import { useState } from "react";
import ActiveMap from "./ActiveMap";
import { ImageGrid } from "./ImageGrid";
import { Toolbox } from "./Toolbox/Toolbox";

import { Apps, Build } from "@mui/icons-material";
import { Button, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getMarkSetting } from "../../../services/utils/settings";
import { useStyles } from "../../../styles/styles";
import theme from "../../../styles/theme";
import DisplayDetails from "./DisplayDetails";
import Notes from "./Notes";

export default function MapView() {
  const dispatch = useDispatch();
  const image = useSelector((state) => state.app.image);
  const user = useSelector((state) => state.app.user);

  const [radius, setRadius] = useState(getMarkSetting("defaultSize"));
  const [displayDetails, setDisplayDetails] = useState(null);
  const [selectedBasRow, setSelectedBasRow] = useState(null);
  const [preview, setPreview] = useState(false);
  const [toolbox, setToolbox] = useState(false);
  const [views, setViews] = useState(false);
  const notMobile = useMediaQuery(theme.breakpoints.up("sm"));

  const closeDetails = () => setDisplayDetails(null);

  const classes = useStyles();
  return (
    <div className={classes.mapView}>
      {ActiveMap({
        setDisplayDetails,
        radius,
        setRadius,
        selectedBasRow,
        setSelectedBasRow,
        preview,
      })}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 8,
        }}
      >
        <div>
          {
            <Button
              variant={views ? "outlined" : "contained"}
              style={{ marginRight: 4 }}
              color="primary"
              startIcon={<Apps />}
              onClick={() => setViews(!views)}
            >
              Vyer
            </Button>
          }
          {notMobile && user?.role?.rights.includes("editMarks") && (
            <Button
              variant={toolbox ? "outlined" : "contained"}
              color="primary"
              startIcon={<Build />}
              onClick={() => setToolbox(!toolbox)}
            >
              Verktyg
            </Button>
          )}
        </div>
        {image && (
          <>
            <DisplayDetails
              onClose={() => closeDetails()}
              dispatch={dispatch}
            />
            {/* todo: remove displayDetails and keep active in selection inside state */}
            {user?.role?.rights.includes("messages") && (
              <Notes spot={displayDetails?.spot} />
            )}
            {/* <MarkData
              open={displayDetails}
              mark={displayDetails}
              onClose={() => closeDetails()}
            /> */}
          </>
        )}

        {notMobile &&
          toolbox &&
          image &&
          user?.role?.rights.includes("editMarks") && (
            <Toolbox
              {...{
                radius,
                setRadius,
                selectedBasRow,
                setSelectedBasRow,
                preview,
                setPreview,
                onClose: () => setToolbox(false),
              }}
            />
          )}

        {views && <ImageGrid onClose={() => setViews(false)} />}
      </div>
    </div>
  );
}
