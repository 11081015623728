import { createSlice } from "@reduxjs/toolkit";
import * as _ from "lodash";
import { getColumns } from "../../services/bas";
import { persistUserLogin } from "../../services/persist";
import {
  addBoats,
  addCustomer,
  addEndpoint,
  addMark,
  addMarkField,
  addOrganisationSettings,
  addSpots,
  addUser,
  addUserRole,
  addUserSettings,
  authenticate,
  basFileUpload,
  deleteAllSpots,
  deleteBasUpload,
  deleteBoat,
  deleteCustomer,
  deleteEndpoint,
  deleteImage,
  deleteLimitedGuestSpot,
  deleteMarkField,
  deleteMarks,
  deleteOccupancy,
  deletePermanentGuestSpot,
  deleteSpot,
  deleteTemporaryGuestSpot,
  deleteUser,
  deleteUserRole,
  doneFlipSignTodo,
  getBasImport,
  getBasUploads,
  getBoats,
  getCustomers,
  getEndpoints,
  getFilterSets,
  getGuestSpots,
  getImages,
  getLatestBasImport,
  getLoginHistory,
  getMarkFields,
  getMarks,
  getAllMarks,
  getNotes,
  getNotifications,
  getOccupancies,
  getOrganisationSettings,
  getPageVisits,
  getSpots,
  getTodos,
  getUserRoles,
  getUsers,
  migrateBoatsFromBAS,
  migrateSpotsFromBAS,
  patchLimitedGuestSpot,
  patchMark,
  patchMarks,
  patchOccupancy,
  patchUserRole,
  postNote,
  postNotification,
  postNotificationStats,
  postOccupancy,
  removeOrganisationSettings,
  saveFilters,
  setOrganisationSetting,
  updateBasUpload,
  updateBoat,
  updateCustomer,
  updateEndpoint,
  updateImage,
  updateMarkField,
  updateSpot,
  updateUser,
  uploadImage,
} from "./appApi";
import reducers from "./appReducers";
export const initialState = {
  isLoading: false,
  user: null,

  // active
  image: null,
  import: [],
  upload: null,
  // available
  images: [],
  marks: [],
  markFields: [],
  uploads: [],
  imports: [],
  // user state
  selectedMarks: [],
  markOriginals: [],
  selectedSpots: [],
  selectedRows: [],
  /**
   * Modes: 'edit', 'add', 'move', 'selectMultiple'
   */
  selectRectangle: { startX: 0, startY: 0, endX: 0, endY: 0, display: false },
  modes: [],
  spotToNewMark: "",
  rulers: [],
  rulerSensitivity: 50,
  // mouse: {x: null, y: null}
  orgSettings: [],
  loginHistory: [],
  endpoints: [],

  // user
  org: null,
  loggedIn: null,
  access: { token: null, validIn: null },
  remember: false,
  users: [],

  detailsColumns: getColumns("width", "boatType", "boatModel", "name", "phone"),
  boatDetails: ["brand", "model", "length", "width"],
  ownerDetails: ["name", "phone"],
  spotDetails: ["spotNumber", "width", "note"],

  settings: [],
  roles: [],
  apiMessage: "",

  filterSets: [],

  customers: [],
  boats: [],
  spots: [],

  notes: [],
  guestSpots: { permanent: [], limited: [], temporary: [] },
  occupancies: [],
  todos: { flipSigns: [] },
  pageVisits: [],
  notifications: [],
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers,
  extraReducers: (builder) => {
    builder
      .addCase(getImages.fulfilled, (state, action) => {
        state.images = action.payload.images;
      })
      .addCase(deleteImage.fulfilled, (state, { meta }) => {
        state.images = state.images.filter((x) => x.id !== meta.arg.id);
      })
      .addCase(updateImage.fulfilled, (state, { payload }) => {
        state.images = state.images.map((x) =>
          x.id === payload.image.id ? payload.image : x
        );
        state.image = payload.image;
      })
      .addCase(uploadImage.fulfilled, (state, { payload }) => {
        state.images.push(payload.image);
      })
      .addCase(getMarks.fulfilled, (state, action) => {
        const recieved = action.payload.marks;
        // TODO: fix 
        // state.marks = state.marks; // .filter(x => !recieved.find(y => y.id === x.id)).concat(recieved);
      })
      .addCase(getAllMarks.fulfilled, (state, action) => {
        state.marks = action.payload.marks;
      })
      .addCase(getBasUploads.fulfilled, (state, action) => {
        state.uploads = action.payload.uploads;
        // Välj senaste importen om sådan finns och val ej gjorts
        if (!state.import && state.imports.length > 0)
          state.import = state.imports[state.imports.length - 1];
      })
      .addCase(deleteBasUpload.fulfilled, (state, { meta }) => {
        state.uploads = state.uploads.filter((x) => x.id !== meta.arg.id);
      })
      .addCase(basFileUpload.fulfilled, (state, { payload }) => {
        state.uploads.push(payload.upload);
      })
      .addCase(updateBasUpload.fulfilled, ({ uploads }, { payload }) => {
        uploads[uploads.findIndex((x) => x.id === payload.upload.id)] =
          payload.upload;
      })
      .addCase(getBasImport.fulfilled, (state, { payload, meta }) => {
        state.import = payload.data;
        state.upload = state.uploads.find((x) => x.id === meta.arg.id);
      })
      .addCase(getLatestBasImport.fulfilled, (state, { payload, meta }) => {
        state.import = payload.data;
      })
      .addCase(patchMark.fulfilled, (state, { payload }) => {
        const mark = payload.marks[0];
        if (mark)
          state.marks = state.marks.map((existing) =>
            existing.id === mark.id ? mark : existing
          );
      })
      .addCase(patchMarks.fulfilled, (state, { payload }) => {
        const marks = payload.marks;
        state.marks = state.marks.map((existing) => {
          const newMark = marks.find((m) => m.id === existing.id);
          if (newMark) return newMark;
          return existing;
        });
      })
      .addCase(addMark.fulfilled, (state, { payload }) => {
        state.marks.push(payload.mark);
      })
      .addCase(deleteMarks.fulfilled, (state, { meta }) => {
        const deleted = meta.arg.ids;
        // filtrera bort om mark finns bland deleted
        state.marks = state.marks.filter((x) => deleted.indexOf(x.id) === -1);
      })
      .addCase(getMarkFields.fulfilled, (state, { payload }) => {
        state.markFields = payload.fields;
      })
      .addCase(addMarkField.fulfilled, (state, { payload }) => {
        state.markFields = [].concat(...state.markFields, ...payload.fields);
      })
      .addCase(updateMarkField.fulfilled, ({ markFields }, { payload }) => {
        markFields[markFields.findIndex((x) => x.id === payload.field.id)] =
          payload.field;
      })
      .addCase(deleteMarkField.fulfilled, ({ markFields }, { meta }) => {
        markFields = markFields.filter((x) => x.id !== meta.arg);
      })
      .addCase(setOrganisationSetting.fulfilled, (state, { payload, meta }) => {
        state.orgSettings = [
          ...state.orgSettings.filter((x) => x.type !== meta.arg.type),
          payload.settings,
        ];
      })
      .addCase(
        addOrganisationSettings.fulfilled,
        (state, { payload, meta }) => {
          state.orgSettings = [
            ...state.orgSettings.filter((x) => x.type !== meta.arg.type),
            payload.settings,
          ];
        }
      )
      .addCase(
        removeOrganisationSettings.fulfilled,
        (state, { payload, meta }) => {
          state.orgSettings = [
            ...state.orgSettings.filter((x) => x.type !== meta.arg.type),
            payload.settings,
          ];
        }
      )
      .addCase(getOrganisationSettings.fulfilled, (state, { payload }) => {
        state.orgSettings = payload.settings;
      })
      .addCase(getLoginHistory.fulfilled, (state, { payload }) => {
        state.loginHistory = payload.logins;
      })
      .addCase(getEndpoints.fulfilled, (state, { payload }) => {
        state.endpoints = payload.endpoints;
      })
      .addCase(addEndpoint.fulfilled, (state, { payload }) => {
        state.endpoints.push(payload.endpoint);
      })
      .addCase(deleteEndpoint.fulfilled, (state, { meta }) => {
        state.endpoints.splice(meta.arg.index);
      })
      .addCase(updateEndpoint.fulfilled, (state, { payload, meta }) => {
        state.endpoints[meta.arg.index] = payload.endpoint;
      })
      // user
      .addCase(authenticate.fulfilled, (state, { payload, meta }) => {
        state.access = payload.access;
        state.loggedIn = true;
        state.remember = meta.arg.remember;
        state.settings = payload.settings;
        state.images = payload.images;
        state.user = payload.user;
        if (payload.org) state.org = payload.org;
        state.isLoading = false;
        persistUserLogin(true);
      })
      .addCase(authenticate.rejected, (state, { payload }) => {
        // console.log({ payload })
        state.loggedIn = false;
        state.isLoading = false;
        state.apiMessage = payload?.message || "";
      })
      .addCase(authenticate.pending, (state) => {
        state.loggedIn = false;
        state.isLoading = true;
      })
      .addCase(getUsers.fulfilled, (state, { payload }) => {
        state.users = payload.users;
      })
      .addCase(addUser.fulfilled, (state, { payload }) => {
        state.users.push(payload.user);
      })
      .addCase(deleteUser.fulfilled, (state, { meta }) => {
        // 204 request, tar id från meta
        state.users = state.users.filter((user) => user.id !== meta.arg.id);
      })
      .addCase(updateUser.fulfilled, (state, { payload, meta }) => {
        state.users = state.users.map((user) =>
          user.id === meta.arg.id ? payload.user : user
        );
        if (state.user.id === payload.user.id) state.user = payload.user;
      })
      .addCase(addUserSettings.fulfilled, (state, { payload, meta }) => {
        state.settings = [
          ...state.settings.filter((x) => x.type !== meta.arg.type),
          payload.settings,
        ];
      })
      .addCase(getUserRoles.fulfilled, (state, { payload }) => {
        state.roles = payload.roles;
      })
      .addCase(addUserRole.fulfilled, (state, { payload }) => {
        state.roles.push(payload.role);
      })
      .addCase(patchUserRole.fulfilled, (state, { payload }) => {
        state.roles = state.roles.map((x) =>
          x.id === payload.role.id ? payload.role : x
        );
      })
      .addCase(deleteUserRole.fulfilled, (state, { meta }) => {
        state.roles.splice(meta.arg.index);
      })
      .addCase(saveFilters.fulfilled, (state, { payload }) => {
        state.filterSets = [
          ...state.filterSets.filter((x) => x.name !== payload.filterSet.name),
          payload.filterSet,
        ];
      })
      .addCase(getFilterSets.fulfilled, (state, { payload }) => {
        state.filterSets = payload.filterSets;
      })
      .addCase(getCustomers.fulfilled, (state, { payload }) => {
        state.customers = payload.customers;
      })
      .addCase(addCustomer.fulfilled, (state, { payload }) => {
        state.customers.push(payload.customer);
      })
      .addCase(getBoats.fulfilled, (state, { payload }) => {
        state.boats = payload.boats;
      })
      .addCase(addBoats.fulfilled, (state, { payload }) => {
        state.boats.push(...payload.boats);
      })
      .addCase(updateBoat.fulfilled, (state, { payload, meta }) => {
        state.boats = state.boats.map((x) =>
          x.id === meta.arg?.id ? payload.boat : x
        );
      })
      .addCase(deleteBoat.fulfilled, (state, { _, meta }) => {
        state.boats = state.boats.filter((x) => x.id !== meta.arg);
      })
      .addCase(deleteCustomer.fulfilled, (state, { _, meta }) => {
        state.customers = state.customers.filter((c) => c.id !== meta.arg);
      })
      .addCase(updateCustomer.fulfilled, (state, { payload }) => {
        state.customers = state.customers.map((x) =>
          x.id === payload.customer.id ? payload.customer : x
        );
      })
      .addCase(getSpots.fulfilled, (state, { payload }) => {
        state.spots = _.orderBy(payload.spots, "spotNumber", "asc");
      })
      .addCase(addSpots.fulfilled, (state, { payload }) => {
        state.spots.push(...payload.spots);
      })
      .addCase(updateSpot.fulfilled, (state, { payload }) => {
        state.spots = state.spots.map((x) =>
          x.id === payload.spot.id ? payload.spot : x
        );
      })
      .addCase(migrateSpotsFromBAS.fulfilled, (state, { payload }) => {
        state.spots = payload.spots;
      })
      .addCase(deleteSpot.fulfilled, (state, { _, meta }) => {
        state.spots = state.spots.filter((x) => x.id !== meta.arg);
      })
      .addCase(deleteAllSpots.fulfilled, (state, { payload }) => {
        state.spots = [];
      })
      .addCase(migrateBoatsFromBAS.fulfilled, (state, { payload }) => {
        state.boats = payload.boats;
      })
      .addCase(getNotes.fulfilled, (state, { payload }) => {
        state.notes = payload.notes;
      })
      .addCase(postNote.fulfilled, (state, { payload }) => {
        state.notes.push(payload.note);
      })
      .addCase(getGuestSpots.fulfilled, (state, { payload }) => {
        state.guestSpots = payload;
      })
      // .addCase(postLimitedGuestSpot.fulfilled, (state, { payload }) => {
      //   state.guestSpots.limited.push(payload.limited);
      // })
      .addCase(deleteLimitedGuestSpot.fulfilled, (state, { meta }) => {
        state.guestSpots.limited = state.guestSpots.limited.filter(
          (x) => x.id !== meta.arg
        );
      })
      .addCase(patchLimitedGuestSpot.fulfilled, (state, { payload, meta }) => {
        state.guestSpots.limited = state.guestSpots.limited.map((x) =>
          x.id === meta.arg.id ? payload.limited : x
        );
      })
      // .addCase(postTemporaryGuestSpot.fulfilled, (state, { payload }) => {
      //   state.guestSpots.temporary.push(payload.temporary);
      // })
      .addCase(deleteTemporaryGuestSpot.fulfilled, (state, { meta }) => {
        state.guestSpots.temporary = state.guestSpots.temporary.filter(
          (x) => x.id !== meta.arg.id
        );
      })
      // .addCase(postPermanentGuestSpots.fulfilled, (state, { payload }) => {
      //   state.guestSpots.permanent.push(...payload.permanents);
      // })
      .addCase(deletePermanentGuestSpot.fulfilled, (state, { meta }) => {
        state.guestSpots.permanent = state.guestSpots.permanent.filter(
          (x) => x.id !== meta.arg.id
        );
      })
      .addCase(getOccupancies.fulfilled, (state, { payload }) => {
        // todo: replace or add
        state.occupancies = payload.occupancies;
      })
      .addCase(postOccupancy.fulfilled, (state, { payload }) => {
        state.occupancies.push(payload.occupancy);
      })
      .addCase(patchOccupancy.fulfilled, (state, { payload, meta }) => {
        state.occupancies = state.occupancies.map((x) =>
          x.id === meta.arg.id ? payload.occupancy : x
        );
      })
      .addCase(deleteOccupancy.fulfilled, (state, { meta }) => {
        state.occupancies = state.occupancies.filter((x) => x.id !== meta.arg);
      })
      .addCase(getTodos.fulfilled, (state, { payload }) => {
        state.todos = payload;
      })
      .addCase(doneFlipSignTodo.fulfilled, (state, { payload }) => {
        state.todos = {
          ...state.todos,
          flipSigns: state.todos.flipSigns.map((x) =>
            x.id === payload.flipSign.id ? payload.flipSign : x
          ),
        };
      })
      .addCase(getPageVisits.fulfilled, (state, { payload }) => {
        state.pageVisits = payload.visits;
      })
      .addCase(getNotifications.fulfilled, (state, { payload }) => {
        state.notifications = payload.notifications;
      })
      .addCase(postNotification.fulfilled, (state, { payload }) => {
        if (payload.notification.users.includes(state.user.id))
          state.notifications = [payload.notification].concat(
            state.notifications
          );
      })
      .addCase(postNotificationStats.fulfilled, (state, { payload, meta }) => {
        state.notifications = state.notifications.map((x) =>
          x.id === meta.arg.notification ? { ...x, ...payload } : x
        );
      });
  },
});

export default appSlice.reducer;
export * from "./appApi";
