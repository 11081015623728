import {
  Button,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { api } from "../services/api";
import { PasswordReset } from "../types";

interface Props {
  pwr: PasswordReset;
}

const ResetPassword: FC<Props> = ({ pwr }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [password, setPassword] = useState<string>("");
  const [password2, setPassword2] = useState<string>("");

  const invalid = password.length === 0 || password !== password2;

  const handleSubmit = async () => {
    try {
      if (!invalid) {
        await api.post(`/reset_password/${pwr.id}`, { password });
        enqueueSnackbar(
          t("reset_password.success_snack", {
            defaultValue: "Du har nu ditt nya lösenord. Testa att logga in!",
          }),
          { variant: "success" }
        );
        navigate("/login");
      }
    } catch {
      enqueueSnackbar(
        t("reset_password.error_snack", {
          defaultValue:
            "Misslyckades att ändra ditt lösenord. Vänligen försök igen senare.",
        }),
        { variant: "error" }
      );
    }
  };

  return (
    <Card sx={{ width: "fit-content" }}>
      <CardHeader
        title={
          <Trans i18nKey="reset_password.password_title">Nytt lösenord</Trans>
        }
      />
      <CardContent
        sx={{ display: "flex", columnGap: 1, rowGap: 2, flexWrap: "wrap" }}
      >
        <TextField
          label={
            <Trans i18nKey="reset_password.password1">Nytt lösenord</Trans>
          }
          type="password"
          autoComplete="new-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          label={
            <Trans i18nKey="reset_password.password2">Bekräfta lösenord</Trans>
          }
          type="password"
          autoComplete="new-password"
          value={password2}
          onChange={(e) => setPassword2(e.target.value)}
        />
        <Tooltip
          title={
            invalid ? (
              <Trans i18nKey="reset_password.passwords_must_match">
                Lösenorden måste matcha
              </Trans>
            ) : (
              <Trans i18nKey="reset_password.passwords_match">
                Lösenorden matchar
              </Trans>
            )
          }
        >
          <Box sx={{ display: "flex" }}>
            <Button
              color="secondary"
              variant="contained"
              sx={{ fontWeight: "bold" }}
              disabled={invalid}
              onClick={handleSubmit}
            >
              OK
            </Button>
          </Box>
        </Tooltip>
      </CardContent>
    </Card>
  );
};

export default ResetPassword;
