import { Card } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import config from "../../config";
import { getColor } from "../../services/colors";
import { getMarkSetting } from "../../services/utils/settings";
import { useStyles } from "../../styles/styles";
import {
  deleteOccupancy,
  getMarks,
  patchOccupancy,
  postOccupancy,
} from "../app";

export default function ViewImage({ imageId, date }) {
  const images = useSelector((state) => state.app.images);
  const image = images.find((img) => img.id === imageId);
  const occupancies = useSelector((state) => state.app.occupancies);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(getMarks({ image: imageId }));
  }, [imageId]);

  const toggle = (mark) => {
    const o = occupancies.find((x) => x.spotNumber === mark.spot);
    if (o) {
      if (o.paid) {
        dispatch(deleteOccupancy(o.id));
      } else {
        dispatch(patchOccupancy({ id: o.id, paid: true }));
      }
    } else {
      dispatch(postOccupancy({ date, spotNumber: mark.spot }));
    }
  };

  const handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    image && (
      <div>
        <Card
          onClick={handleClick}
          style={{
            display: "flex",
            transform: `rotate(${image.rotation || 0}deg)`,
            width: "fit-content",
          }}
        >
          <img
            src={`${config.apiUrl}/images/${image.filename}`}
            alt=""
            id="view-background-image"
            className={classes.image}
            draggable={false}
            // ref={imgRef}
          />
          {AvailabilityOverlay({
            date,
            image,
            onMarkerClick: (mark) => toggle(mark),
          })}
        </Card>
      </div>
    )
  );
}

function AvailabilityOverlay({ date, image, onMarkerClick }) {
  const guestSpots = useSelector((state) => state.app.guestSpots);
  const occupancies = useSelector((state) => state.app.occupancies);
  const allMarks = useSelector((state) => state.app.marks);
  const marks = image
    ? allMarks.filter((x) => image.marks.map((x) => x.id).includes(x.id))
    : [];

  return (
    <svg
      className="overlay-svg"
      viewBox={`0 0 ${image?.width || "0"} ${image?.height || "0"}`}
    >
      {marks.map((mark) => {
        if (
          mark.spot &&
          (guestSpots.permanent.find((x) => x.spotNumber === mark.spot) ||
            guestSpots.temporary.find((x) => x.spotNumber === mark.spot) ||
            guestSpots.limited.find((x) => x.spotNumber === mark.spot))
        )
          return (
            <Circle
              date={date}
              occupancies={occupancies}
              mark={mark}
              key={mark.id}
              onMarkerClick={(mark) => onMarkerClick(mark)}
            />
          );
      })}
    </svg>
  );
}

function Circle({ occupancies, date, mark, onMarkerClick }) {
  const occupied = occupancies.find((x) => x.spotNumber === mark.spot);
  return (
    <circle
      cx={mark.x}
      cy={mark.y}
      r={mark.style?.radius || getMarkSetting("defaultSize")}
      fill={occupied ? (occupied.paid ? "red" : "yellow") : "green"}
      stroke={getColor("stroke")}
      strokeWidth={getMarkSetting("strokeWidth")}
      strokeOpacity={getMarkSetting("opacityStroke")}
      fillOpacity={mark.style?.opacity || getMarkSetting("opacity")}
      onClick={(e) => {
        e.stopPropagation();
        onMarkerClick(mark);
      }}
      style={{ cursor: "pointer", pointerEvents: "fill" }}
    />
  );
}
