import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { IconButton, SxProps, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";
import { addDays, subDays } from "date-fns";
import { FC } from "react";
interface Props {
  onDateChange: (date: Date | null) => void;
  date: Date;
  sx: SxProps;
}

const DatePickerWithArrows: FC<
  Props & Partial<DatePickerProps<Date, Date>>
> = ({ onDateChange, date, sx, ...rest }) => {
  return (
    <Box sx={{ display: "flex", gap: 0.5, alignItems: "center", ...sx }}>
      <IconButton onClick={() => onDateChange(subDays(date, 1))} size="small">
        <KeyboardArrowLeft />
      </IconButton>
      <DatePicker
        {...rest}
        value={date}
        onChange={(date) => onDateChange(date)}
        inputFormat="yyyy-MM-dd"
        renderInput={(params) => (
          <TextField {...params} sx={{ width: 150 }} size="small" />
        )}
      />
      <IconButton
        disabled={rest.maxDate ? addDays(date, 1) >= rest.maxDate : false}
        onClick={() => onDateChange(addDays(date, 1))}
        size="small"
      >
        <KeyboardArrowRight />
      </IconButton>
    </Box>
  );
};

export default DatePickerWithArrows;
