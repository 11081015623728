import { IUser } from "@hamnvy/shared";
import { Edit, EditOff } from "@mui/icons-material";
import { Card, IconButton } from "@mui/material";
import { FC, useState } from "react";
import { Trans } from "react-i18next";
import EditableUserDetails from "../components/EditableUserDetails";
import PaneTitle from "../components/PaneTitle";
import ViewOnlyUserDetails from "../components/ViewOnlyUserDetails";

interface Props {
  user: IUser;
}

const UserDetailsPane: FC<Props> = (props) => {
  const { user } = props;
  const [editing, setEditing] = useState<boolean>(false);
  return (
    <Card sx={{ maxWidth: 500 }}>
      <PaneTitle label={<Trans i18nKey="user.properties">Uppgifter</Trans>}>
        <IconButton
          size="small"
          color="inherit"
          sx={{ mr: -1 }}
          onClick={() => setEditing(!editing)}
        >
          {!editing ? <Edit /> : <EditOff />}
        </IconButton>
      </PaneTitle>

      {!editing ? (
        <ViewOnlyUserDetails user={user} />
      ) : (
        <EditableUserDetails user={user} onClose={() => setEditing(false)} />
      )}
    </Card>
  );
};

export default UserDetailsPane;
