import { Settings } from "@mui/icons-material";
import { IconButton } from "@mui/material";

export default function SettingsButton(openSettings, options = []) {
  return (
    <IconButton
      onClick={() => openSettings()}
      title="Inställningar"
      {...options}
      size="large">
      <Settings />
    </IconButton>
  );
}
