import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function ConfirmDialog({
  title,
  text = "",
  yes = "",
  no = "",
  onConfirm,
  open,
  setOpen,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={!!open}
      onClose={handleClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
      {text && (
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {no || "Nej"}
        </Button>
        <Button
          onClick={() => {
            handleClose();
            onConfirm();
          }}
          color="primary"
          autoFocus
        >
          {yes || "Ja"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
