import { INotification } from "@hamnvy/shared";
import { Notifications, NotificationsOutlined } from "@mui/icons-material";
import { Badge, IconButton, Popover, Tooltip } from "@mui/material";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getNotifications } from "../app";
import NotificationsContainer from "./NotificationsContainer";

type Props = {};

const NotificationsPopover = (props: Props) => {
  const dispatch = useAppDispatch();
  const notifications = useAppSelector<INotification[]>(
    (s) => s.app.notifications
  ).filter((x) => !x?.dismissed);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "notifications-popover" : undefined;
  const unopenedNotificationCount = notifications.filter(
    (x) => !x?.expanded
  ).length;

  useEffect(() => {
    dispatch(getNotifications());
  }, []);
  return (
    <>
      <Tooltip title="Notiser">
        <IconButton
          edge="end"
          aria-describedby={id}
          onClick={handleClick}
          size="large"
          sx={{ color: "#eee" }}
        >
          <Badge badgeContent={unopenedNotificationCount} color="error">
            {notifications.length > 0 ? (
              <Notifications />
            ) : (
              <NotificationsOutlined />
            )}
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{ style: { width: "100%", maxWidth: 320 } }}
      >
        <NotificationsContainer notifs={notifications} />
      </Popover>
    </>
  );
};

export default NotificationsPopover;
