import { Comment } from "@mui/icons-material";
import { Box, IconButton, IconButtonProps, Tooltip } from "@mui/material";
import { FC } from "react";

interface Props {
  title?: string;
}

const CommentButton: FC<Props & IconButtonProps> = (props) => {
  const { title, ...rest } = props;
  return (
    <Tooltip title={title ?? "Kommentarer"}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyItems: "center",
        }}
      >
        <IconButton size="small" {...rest}>
          <Comment />
        </IconButton>
      </Box>
    </Tooltip>
  );
};

export default CommentButton;
