import { Assignment, FilePreview, FileUpload } from "@hamnvy/shared";
import { ChangeEvent } from "react";
import { api } from "../api";
import Jimp from "jimp";

export const getSingleFile = (
  e: ChangeEvent<HTMLInputElement>
): File | null => {
  return e.target.files?.[0] ?? null;
};

export const uploadFileToS3 = async (file: File, bucket: string, original?: string) => {
  const result = await api.get(`/files?type=${bucket}`);
  const putUrl: string = result.data.url;
  const uploadRes = await fetch(putUrl, {
    method: "put",
    body: file,
    headers: {
      "Content-Disposition": "attachment; filename=" + file.name,
    },
  });
  if (uploadRes.ok) {
    const fileId = result.data.file.id;
    const data: any = {
      filename: file.name,
      size: file.size,
      contentType: file.type,
      status: "uploaded",
    };
    if (original) {
      data.variants = {original}
    }
    let thumbnail: FilePreview | undefined;
    if (!original && file?.type.startsWith("image")) {
      try {
        const fb = await file?.arrayBuffer();
        // @ts-expect-error
        const img = await Jimp.read(fb);
        // todo insert other variants
        const thumb = await img.scaleToFit(500, 500).getBufferAsync(img.getMIME())
        const thumbFile = new File([thumb], "thumb_" + file.name, { type: img.getMIME() })
        thumbnail = await uploadFileToS3(thumbFile, bucket, fileId);
        if (thumbnail) {
          data.variants = {...(data.variants ?? {}), thumbnail: thumbnail.summary.id};
        }
      } catch (err) {
        alert(err)
      }
    }
    const metaRes = await api.patch(`/files/${fileId}`, data);
    const summary: FileUpload = metaRes.data.file;
    const src = URL.createObjectURL(file);
    const uploadedFile: FilePreview = {
      thumbnailUrl: src,
      src,
      summary,
    };

    return uploadedFile;
  }
};

export const getIdFromFilePreview = (fp: FilePreview) => fp.summary.id;

export const getIdsFromFilePreviews = (fps: FilePreview[]) =>
  fps.map((fp) => getIdFromFilePreview(fp));

export const getAttachmentIds = (assignment?: Assignment): string[] => {
  if (!assignment) return [];
  return getIdsFromFilePreviews(assignment.attachments);
};

export const getLocalUrlForUploadedFile = (file: File): string =>
  URL.createObjectURL(file);
