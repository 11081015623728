import { Autocomplete, TextField } from "@mui/material";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getOrganisations } from "../../admin/adminAPI";

type Props = {
  value: string;
  onChange: (value: string) => void;
};

const OrganisationField = (props: Props) => {
  const dispatch = useAppDispatch();
  const orgs = useAppSelector<{ id: string; orgShortName: string }[]>(
    (s) => s.admin.organisations
  );
  const { value, onChange } = props;

  useEffect(() => {
    if (!orgs.length) dispatch(getOrganisations());
  }, []);

  return (
    <Autocomplete
      options={orgs}
      value={value ? orgs.find((x) => x.id === value) : null}
      getOptionLabel={(option) => option.orgShortName}
      size="small"
      style={{ width: 200 }}
      onChange={(e, val) => (val ? onChange(val.id) : onChange(""))}
      renderInput={(params) => (
        <TextField {...params} label="Organisation" variant="outlined" />
      )}
    />
  );
};

export default OrganisationField;
