import { FilePreview } from "@hamnvy/shared";
import { ArticleOutlined, Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  DialogContent,
  Divider,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { useContext, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { DialogWithCloseButton } from "../../Components/Dialog";
import ActionDialog from "../../Components/utils/ActionDialog";
import ImageAndFileUpload from "../../Components/utils/ImageAndFileUpload";
import Json from "../../Components/utils/Json";
import { isProd } from "../../config";
import { getAttachmentIds } from "../../services/utils/files";
import AssignmentCreatedBy from "./AssignmentCreatedBy";
import AssignmentProperties from "./AssignmentProperties";
import { AssignmentContext } from "./AssignmentProvider";
import GroupedAttachmentThumbnails from "./GroupedAttachmentThumbnails";

interface Props { }

const AssignmentDetails = (props: Props) => {
  const { t } = useTranslation();
  const {
    assignment,
    updateAssignment,
    deleteAssignment,
    isEditing,
    setEditing,
  } = useContext(AssignmentContext);
  const { enqueueSnackbar } = useSnackbar();
  const [jsonOpen, setJsonOpen] = useState<boolean>(false);
  const [isDeleting, setDeleting] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [changes, setChanges] = useState<{
    description?: string;
    title?: string;
  }>({});

  const onUpload = async (file: File, upload: FilePreview) => {
    try {
      await updateAssignment({
        attachments: getAttachmentIds(assignment).concat(upload.summary.id),
      });
    } catch (err) {
      enqueueSnackbar(
        t("assignment.add-attachment-error", {
          defaultValue: "Kunde inte läggas till bilagan.",
        }),
        {
          variant: "error",
        }
      );
    }
  };

  const discardChangesAndClose = () => {
    setEditing(false);
    setChanges({});
  };

  const saveChangesAndClose = () => {
    updateAssignment(changes);
    discardChangesAndClose();
  };

  const handleDeleteConfirm = async () => {
    try {
      setError("");
      await deleteAssignment();
      setDeleting(false);
      enqueueSnackbar("Felanmälan borttagen", { variant: "success" });
    } catch (err) {
      setError("Fel uppstod. Vänligen försök igen.");
    }
  };

  const afterFileDeletion = async (fileId: string) => {
    const attachments =
      assignment?.attachments
        .map((a) => a.summary.id)
        .filter((id) => id !== fileId) ?? [];
    updateAssignment({ attachments });
  };

  const hasAttachments = useMemo(
    () => (assignment ? assignment.attachments.length > 0 : false),
    [assignment]
  );

  return (
    <Box sx={{ pb: 2 }}>
      {!!assignment && (
        <Paper sx={{ p: 2 }}>
          <Box sx={{ pb: 1 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                columnGap: 2,
                pb: 1,
              }}
            >
              {isEditing ? (
                <TextField
                  size="small"
                  label="Rubrik"
                  defaultValue={assignment.title}
                  onChange={(e) =>
                    setChanges({ ...changes, title: e.target.value })
                  }
                  fullWidth
                />
              ) : (
                <Typography variant="h5">{assignment.title}</Typography>
              )}
              <Box sx={{ display: "flex", gap: 0.5 }}>
                <ActionDialog
                  open={isDeleting}
                  onClose={() => setDeleting(false)}
                  yes="Bekräfta"
                  no="Avbryt"
                  title="Ta bort felanmälan?"
                  error={error}
                  onConfirm={handleDeleteConfirm}
                />
                {isEditing && (
                  <Tooltip title="Radera felanmälan">
                    <IconButton size="small" onClick={() => setDeleting(true)}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Redigera">
                  <IconButton
                    size="small"
                    onClick={() => setEditing(true)}
                    disabled={isEditing}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Divider />
            <AssignmentCreatedBy
              assignment={assignment}
              pre={t("assignment.created_by", { defaultValue: "Skapad" })}
              dateFormat="yyyy-MM-dd HH:mm"
            />
          </Box>
          <AssignmentProperties />
          <Box sx={{ pt: 1 }}>
            <Typography variant="h6">
              <Trans i18nKey="assignment.description">Beskrivning</Trans>
            </Typography>
            <Divider sx={{ mb: 1 }} />
            {isEditing ? (
              <TextField
                label="Beskrivning"
                defaultValue={assignment?.description ?? ""}
                onChange={(e) =>
                  setChanges({ ...changes, description: e.target.value })
                }
                multiline
                fullWidth
              />
            ) : (
              <Typography whiteSpace="pre-line">
                {assignment?.description}
              </Typography>
            )}
          </Box>
          {(hasAttachments || isEditing) && (
            <Box sx={{ pt: 1 }}>
              <Typography variant="h6">
                <Trans i18nKey="assignment.attachments">Bilagor</Trans>
              </Typography>
              <Divider />
              {!hasAttachments && (
                <Typography sx={{ pt: 1 }}>
                  <Trans i18nKey="assignment.zero-attachments">
                    Inga bilagor finns tillagda.
                  </Trans>
                </Typography>
              )}
            </Box>
          )}
          <GroupedAttachmentThumbnails
            isEditing={isEditing}
            attachments={assignment.attachments}
            afterFileDeletion={afterFileDeletion}
          />
          {isEditing && (
            <ImageAndFileUpload
              id="assignment-edit"
              type="assignments"
              onUpload={onUpload}
            />
          )}

          {isEditing && <Divider sx={{ mt: 1 }} />}
          {isEditing && (
            <Box sx={{ display: "flex", gap: 1, py: 1 }}>

              {!isEmpty(changes) && <Tooltip
                title={isEmpty(changes) ? "Inga ändringar har gjorts" : ""}
              >
                <Box>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={isEmpty(changes)}
                    onClick={saveChangesAndClose}
                  >
                    Spara ändringar
                  </Button>
                </Box>
              </Tooltip>
              }
              <Button
                color="primary"
                variant="outlined"
                onClick={() => discardChangesAndClose()}
              >
                {isEmpty(changes) ? "Sluta redigera" : "Släng ändringar & sluta redigera"}
              </Button>
            </Box>
          )}
        </Paper>
      )}
    </Box>
  );
};

export default AssignmentDetails;
