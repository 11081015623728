import { Add, History } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import EditNotification from "./EditNotification";
import SentNotifications from "./SentNotifications";

type Props = {};

const NotificationPage = (props: Props) => {
  const [adding, setAdding] = useState<boolean>(false);
  const [history, setHistory] = useState<boolean>(false);
  return (
    <Box sx={{ py: 2, display: "flex", flexDirection: "column", gap: 2 }}>
      <Typography variant="h4">Notiser</Typography>
      <div style={{ display: "flex", gap: 8 }}>
        <Button
          variant={adding ? "outlined" : "contained"}
          color="primary"
          startIcon={<Add />}
          onClick={() => setAdding(!adding)}
        >
          Skapa
        </Button>
        <Button
          variant={history ? "outlined" : "contained"}
          color="primary"
          startIcon={<History />}
          onClick={() => setHistory(!history)}
        >
          Historik
        </Button>
      </div>
      {adding && <EditNotification />}
      {history && <SentNotifications />}
    </Box>
  );
};

export default NotificationPage;
