import { Box, SxProps, Typography } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { FC, ReactNode } from "react";

interface Props {
  label: ReactNode;
  children?: ReactNode;
  sx?: SxProps;
}

const PaneTitle: FC<Props> = (props) => {
  return (
    <Box
      sx={{
        px: 2,
        pt: 1,
        pb: 1,
        background: blueGrey[800],
        color: "white",
        userSelect: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        ...props.sx,
      }}
    >
      <Typography variant="h5">{props.label}</Typography>
      {props.children ? props.children : <Box />}
    </Box>
  );
};

export default PaneTitle;
