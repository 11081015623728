import { makeStyles } from "@mui/styles";
import { drawerWidth } from "../features/admin/menu/drawer";

const useStyles = makeStyles(function (theme) {
  return {
    root: {
      display: "grid",
      gridTemplateAreas: '"top" "map" "data"',
      // gridTemplateColumns: "480px repeat(auto-fill, minmax(500px, 1fr))",
      gap: theme.spacing(2),
      marginTop: theme.spacing(2),
      [theme.breakpoints.up("xl")]: {
        marginLeft: drawerWidth,
      },
    },
    mapView: {
      gridArea: "map",
      display: "grid",
      [theme.breakpoints.up("md")]: {
        gridTemplateColumns: "auto 300px",
      },
      [theme.breakpoints.down("md")]: {
        gridTemplateColumns: "1fr",
      },
      gap: theme.spacing(2),
      justifySelf: "start",
    },
    overlay: {
      position: "relative",
    },
    toolboxButtons: {
      display: "grid",
      gap: theme.spacing(1),
    },
    toolboxContent: {
      display: "grid",
      gridTemplateColumns: "1fr",
      justifyItems: "left",
      gap: theme.spacing(1),
    },
    image: {
      display: "block",
      width: "100%",
    },
    scroll500: {
      overflowY: "auto",
      maxHeight: 300,
    },
    imageHeader: {
      display: "grid",
      gridTemplateColumns: "4fr 1fr",
      alignItems: "center",
    },
    dataRelated: {
      display: "grid",
      gap: theme.spacing(2),
      gridTemplateColumns: "repeat(auto-fill, 1fr)",
    },
    centeredRoot: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        height: "100vh",
        flexDirection: "row",
        alignItems: "center", // centrera vertikalt
        gap: theme.spacing(4),
      },
      justifyContent: "center", // centrera horisontellt
    },
    guessManagerHome: {
      padding: theme.spacing(4),
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        height: "100%",
        flexDirection: "row",
        alignItems: "center", // centrera vertikalt
        gap: theme.spacing(4),
      },
      justifyContent: "center", // centrera horisontellt
    },
    center: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      justifyContent: "center", // centrera horisontellt
      alignItems: "center", // centrera vertikalt
      height: "100vh",
    },
    onetwo: {
      [theme.breakpoints.up("md")]: {
        display: "grid",
        gridTemplateColumns: "400px 1fr",
        gap: 16,
      },
    },
    followScroll: {
      [theme.breakpoints.up("md")]: {
        alignSelf: "start",
        position: "sticky",
        top: theme.spacing(6),
        zIndex: theme.zIndex.drawer,
      },
    },
  };
});
export { useStyles };
