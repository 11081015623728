import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import { BlockPicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import { CloseButton } from "../../Components/utils";
import {
  colorPalette,
  defaultColors,
  overrideDefaultColorsWithSettingsCollection,
} from "../../services/colors";
import { setOrganisationSetting } from "../app";

export default function ColorSettings({
  colorFields = defaultColors,
  settingsFor = "view",
}) {
  const dispatch = useDispatch();
  const orgSettings = useSelector((state) => state.app.orgSettings);
  const [openPalettes, setPalettes] = useState([]);
  const isPaletteOpen = (color) => openPalettes.includes(color.field);
  const togglePaletteFor = (color) =>
    setPalettes(
      isPaletteOpen(color)
        ? openPalettes.filter((x) => x !== color.field)
        : openPalettes.concat(color.field)
    );

  let colors = colorFields;
  let onChange = (field, hexColor) => console.log(field, hexColor);

  // set onChange methods
  switch (settingsFor) {
    case "organisation":
      colors = overrideDefaultColorsWithSettingsCollection(orgSettings);
      onChange = (color, value) =>
        dispatch(
          setOrganisationSetting({
            type: "colors",
            settings: [{ field: color.field, title: color.title, value }],
          })
        );
      break;
    case "user":
      // colors = defaultColors.override(userColors)
      break;
    case "view":
      //colors = viewColors;
      break;
    default:
      colors = colorFields;
      break;
  }

  return (
    <Card>
      <CardHeader title="Färgpalett" />
      <CardContent>
        <TableContainer>
          <Table>
            <TableBody>
              {colors.map((color) => (
                <TableRow key={"color-row-" + color.field}>
                  <TableCell>{color.title}</TableCell>
                  <TableCell>
                    <div style={{ display: "flex" }}>
                      <div>
                        {!isPaletteOpen(color) && (
                          <Tooltip title="Välj färg">
                            <Button
                              variant="outlined"
                              style={{ backgroundColor: color.value }}
                              onClick={() => togglePaletteFor(color)}
                            ></Button>
                          </Tooltip>
                        )}
                      </div>
                      <div>
                        {isPaletteOpen(color) &&
                          CloseButton(() => togglePaletteFor(color))}
                      </div>
                      {isPaletteOpen(color) && (
                        <BlockPicker
                          colors={colorPalette}
                          onChangeComplete={(c) => onChange(color, c.hex)}
                          triangle="hide"
                          color={color.value}
                        />
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
