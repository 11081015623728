import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CloseButton, MinusButton } from "../../Components/utils";
import { patchUserRole } from "../app";
import { AddUserRights, userRights } from "./UserGroups";

export default function EditUserGroup({ userGroup, onClose }) {
  const [name, setName] = useState("");
  const [rights, setRights] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setName(userGroup.name);
    setRights(userGroup.rights);
  }, [userGroup]);

  const savable = userGroup.name !== name || userGroup.rights !== rights;

  const handleSave = async () => {
    const result = await dispatch(
      patchUserRole({ id: userGroup.id, name, rights })
    );
    if (result.meta.requestStatus === "fulfilled") onClose();
  };

  return (
    <Card>
      <CardHeader
        title={"Redigera användargrupp: " + userGroup.name}
        action={CloseButton(() => onClose())}
      />
      <CardContent>
        <TextField
          variant="outlined"
          label="Namn"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={3}>Rättigheter</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Internt</TableCell>
                <TableCell>Namn</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rights.map((r) => (
                <TableRow key={"row4" + r}>
                  <TableCell>{r}</TableCell>
                  <TableCell>
                    {userRights.find((x) => x.field === r).name}
                  </TableCell>
                  <TableCell align="right" padding="none">
                    {MinusButton(
                      () => setRights(rights.filter((x) => x !== r)),
                      { size: "small" }
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ maxWidth: 300 }}>
          <AddUserRights onSelect={(r) => setRights(rights.concat(r))} />
        </div>
      </CardContent>
      <CardActions>
        <Tooltip
          title={!savable ? "Inga ändringar har gjorts." : "Spara ändringar"}
        >
          <div>
            <Button
              variant="contained"
              color="primary"
              disabled={!savable}
              onClick={() => handleSave()}
            >
              Spara
            </Button>
          </div>
        </Tooltip>
      </CardActions>
    </Card>
  );
}
