import { IUser } from "@hamnvy/shared";
import { Table, TableBody, TableContainer, TableRow } from "@mui/material";
import { FC } from "react";
import { Trans } from "react-i18next";
import TKey from "./TKey";
import TVal from "./TVal";

interface Props {
  user: IUser;
}

const ViewOnlyUserDetails: FC<Props> = (props) => {
  const { user } = props;
  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <TKey>
              <Trans i18nKey="user.username">Användarnamn</Trans>
            </TKey>
            <TVal>{user.username}</TVal>
          </TableRow>
          <TableRow>
            <TKey>
              <Trans i18nKey="user.name">Namn</Trans>
            </TKey>
            <TVal>{user?.name ?? "-"}</TVal>
          </TableRow>
          <TableRow>
            <TKey>
              <Trans i18nKey="user.firstname">Förnamn</Trans>
            </TKey>
            <TVal>{user?.firstname ?? "-"}</TVal>
          </TableRow>
          <TableRow>
            <TKey>
              <Trans i18nKey="user.lastname">Efternamn</Trans>
            </TKey>
            <TVal>{user?.lastname ?? "-"}</TVal>
          </TableRow>
          <TableRow>
            <TKey>
              <Trans i18nKey="user.email">Epostadress</Trans>
            </TKey>
            <TVal>{user?.email ?? "-"}</TVal>
          </TableRow>
          <TableRow>
            <TKey>
              <Trans i18nKey="user.phone">Telefon</Trans>
            </TKey>
            <TVal>{user?.phone ?? "-"}</TVal>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ViewOnlyUserDetails;
