import { Route } from "react-router";
import { Routes } from "react-router-dom";
import CRMBoatPage from "./BoatPage";
import CRMImport from "./CRMImport";
import CRMCustomerPage from "./CustomersPage";
import ManageSpots from "./manageSpots";
import MarinaInfo from "./MarinaInfo";

export default function CRMRoutes() {
  return (
    <Routes>
      <Route path="/" element={<MarinaInfo />} />

      <Route path="/customers" element={<CRMCustomerPage />} />

      <Route path="/boats" element={<CRMBoatPage />} />

      <Route path="/spots" element={<ManageSpots />} />

      <Route path="/settings" element={<CRMImport />} />
    </Routes>
  );
}
