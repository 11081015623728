import { IUser } from "@hamnvy/shared";
import { Menu } from "@mui/icons-material";
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/system";
import { FC, ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks";
import ResponsiveDrawer from "../admin/menu/drawer";
import { AdminSubmenu, BASSubmenu, CRMSubmenu } from "../admin/menu/MenuItems";
import NotificationsPopover from "../notifications/NotificationsPopover";
import CircleLogo from "../../Components/CircleLogo";

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.primary.main,
  },
  menuButton: {
    color: "white",
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("xl")]: {
      display: "none",
    },
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    // @ts-expect-error
    zIndex: theme.zIndex.drawer + 1,
  },
  // @ts-expect-error
  toolbar: theme.mixins.toolbar,
}));

interface Props {
  children?: ReactNode;
  passive?: boolean; // have menu etc
}

const Header: FC<Props> = ({ children, passive = false }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const org: IUser["org"] = useAppSelector((state) => state.app.org);
  const user: IUser | null = useAppSelector((state) => state.app.user);

  const menuContent = {
    // @ts-expect-error
    admin: user && user.isSuperUser && (
      <AdminSubmenu setMobileOpen={setMobileOpen} />
    ),
    crm: org?.hamnvyCRM && <CRMSubmenu setMobileOpen={setMobileOpen} />,
    bas: org?.usingBAS && <BASSubmenu setMobileOpen={setMobileOpen} text={mobileOpen} />,
  };
  // @ts-expect-error
  const menu = user?.isSuperUser ? "admin" : user?.org.usingBAS ? "bas" : "crm";

  return (
    <>
      {!passive && menu && (
        <ResponsiveDrawer
          mobileOpen={mobileOpen}
          setMobileOpen={setMobileOpen}
          handleDrawerToggle={handleDrawerToggle}
          contents={menuContent[menu]}
        />
      )}
      <AppBar color="primary" position="fixed" className={classes.appBar}>
        <Toolbar
          variant="dense"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: "16px"
          }}
        >
          {!passive && menu && (
            <IconButton
              edge="start"
              className={classes.menuButton}
              aria-label="menu"
              onClick={() => handleDrawerToggle()}
              size="large"
            >
              <Menu />
            </IconButton>
          )}
          <div
            style={{
              display: "flex",
              gap: 8,
              justifySelf: "flex-start",
              alignItems: "center",
              cursor: "pointer",
              userSelect: "none",
            }}
            // @ts-expect-error
            onClick={() => navigate(user?.isSuperUser ? "/admin" : "/overview")}
          >
            <CircleLogo size={32} />
            <Typography sx={{ color: "#eee", textShadow: "0 4px 10px rgba(0,0,0,0.2)" }}>
              <b>Hamnvy</b> Admin
            </Typography>
          </div>
          {!passive && <NotificationsPopover />}
        </Toolbar>
      </AppBar>
      {children}
    </>
  );
};

export default Header;
