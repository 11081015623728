export const reduxWait = storeAPI => next => action => {
    if (action.type === 'user') {
      setTimeout(() => {
        // Delay this action by one second
        console.log('middleware working')
        next(action)
      }, 1000)
      return
    }
  
    return next(action)
  }