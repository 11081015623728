import { ArrowBackIos } from "@mui/icons-material";
import {
  Card,
  CardHeader,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import format from "date-fns/format";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { VerticalCenterDiv } from "../../Components/utils/Divs";
import CRMDetailsSettings from "../map/MapView/CRMDetails";
import { updateOrganisation } from "./adminAPI";
import { AddUser, ListUsers } from "./ManageUsers";

export default function Org() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const admin = useSelector((state) => state.admin);
  const org = admin.organisations.find((x) => x.id === id);
  const users = useSelector((state) => state.app.users);

  return (
    <>
      <VerticalCenterDiv>
        <IconButton
          size="small"
          onClick={() => navigate("/admin/organisations")}
        >
          <ArrowBackIos />
        </IconButton>
        <span>{org?.orgShortName}</span>
      </VerticalCenterDiv>
      <Typography variant="h5">Användare</Typography>
      <ListUsers users={users.filter((x) => x.org === id)} orgCol={false} />
      <AddUser org={id} />
      <OrgDetails org={org} />
      <Card>
        <CardHeader title="Förvald platsdata" />
        <CRMDetailsSettings
          boatFields={org.defaultBoatDetails}
          ownerFields={org.defaultOwnerDetails}
          spotFields={org.defaultSpotDetails}
          onBoatChange={(fields) =>
            dispatch(
              updateOrganisation({ id: org.id, defaultBoatDetails: fields })
            )
          }
          onOwnerChange={(fields) =>
            dispatch(
              updateOrganisation({ id: org.id, defaultOwnerDetails: fields })
            )
          }
          onSpotChange={(fields) =>
            dispatch(
              updateOrganisation({ id: org.id, defaultSpotDetails: fields })
            )
          }
        />
      </Card>
    </>
  );
}

export function OrgDetails({ org }) {
  return (
    <Card>
      <CardHeader title="Detaljer" />
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Pris per månad, SEK</TableCell>
              <TableCell>{org?.monthlyPrice || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Organisationsnamn</TableCell>
              <TableCell>{org?.orgName || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Adress</TableCell>
              <TableCell>{org?.address || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ort</TableCell>
              <TableCell>{org?.city || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Epost</TableCell>
              <TableCell>{org?.email || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Telefon</TableCell>
              <TableCell>{org?.phone || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Registrerad</TableCell>
              <TableCell>
                {format(new Date(org?.createdAt), "yyyy-MM-dd") || "-"}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
