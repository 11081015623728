import { Add, GetApp } from "@mui/icons-material";
import { api } from "../../services/api";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CloseButton,
  ConfirmDialog,
  DeleteButton,
  EyeButton,
} from "../../Components/utils";
import config from "../../config";
import { deleteBasUpload, getBasImport, getBasUploads } from "../app";
import { BasFileUpload } from "./BasFileUpload";

function BasUploads({ open = true, onClose = null }) {
  const dispatch = useDispatch();
  const upload = useSelector((state) => state.app.upload);
  const uploads = useSelector((state) => state.app.uploads);
  const [isUploading, setUploading] = useState(false);
  const [isRemoving, setRemoving] = useState(false);

  useEffect(() => {
    getFileUploads();
  }, []);

  function getFileUploads() {
    dispatch(getBasUploads());
  }

  function remove(id) {
    dispatch(deleteBasUpload({ id }));
  }

  function view(id) {
    dispatch(getBasImport({ id }));
  }

  function handleRemove() {
    remove(isRemoving);
    setRemoving(false);
  }

  return (
    open && (
      <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
        <ConfirmDialog
          title="Radera importerad fil och tillhörande data?"
          open={isRemoving}
          setOpen={setRemoving}
          onConfirm={() => handleRemove()}
        />
        <div>
          <h3>Instruktioner: Exportera från BAS</h3>
          <ul>
            <li>Logga in BAS-K som administratör.</li>
            <li>Välj Rapporter-fliken i huvudmenyn.</li>
            <li>Välj Ny rapport uppe till höger i fönstret.</li>
            <li>Konfigurationsfliken kan lämnas orörd.</li>
            <li>Gå vidare till Kolumner & filter-fliken</li>
            <ul>
              <li>I menyn för Rapportmall välj Platshantering</li>
              <li>
                Välj samtliga kolumner genom att klicka på &#707;&#707;
                (dubbelpilen riktad åt höger)
              </li>
            </ul>
            <li>
              Slutligen gå till Resultat-fliken, vänta på att sidan laddar och
              slutligen klicka på Till Excel-knappen uppe till höger.
            </li>
            <li>
              Välj den nedladdade filen via Importera-knappen här nedan.
            </li>
          </ul>
        </div>
        <BasFileUpload open={isUploading} onClose={() => setUploading(false)} />
        <Card>
          <CardHeader
            title="Importhistorik"
            action={
              <div style={{ display: "flex" }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setUploading(true)}
                  endIcon={<Add />}
                >
                  Importera
                </Button>
                {onClose && CloseButton(() => onClose())}
              </div>
            }
          />
          <CardContent>
            {BasUploadsTable({
              uploads,
              onView: (id) => view(id),
              upload,
              onRemove: (id) => setRemoving(id),
            })}
          </CardContent>
        </Card>
      </div>
    )
  );
}
function BasUploadsTable({
  uploads = [],
  download = true,
  onView = null,
  upload = null,
  onRemove = null,
  action = null,
}) {
  const sortedUploads = []
    .concat(...uploads)
    .sort(
      (a, b) =>
        -new Date(a?.uploadDate).valueOf() + new Date(b?.uploadDate).valueOf()
    );

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Datum</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedUploads.map((u) => {
            const hasErrors = u?.errors.length > 0;
            return (
              <TableRow key={u.id}>
                <TableCell><span>{u.uploadDate.substring(0, 10)} </span>
                  {hasErrors && <><b>Felaktig fil</b>
                    <div style={{ whiteSpace: "pre-wrap" }}>
                      <ul>
                        {u.errors.map(x => <li key={x}>{x}</li>)}
                      </ul>
                    </div>
                  </>}</TableCell>
                <TableCell align="right">
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    {download && (
                      <Tooltip title="Ladda ned">
                        <div>
                          <IconButton
                            onClick={() => api.get(`${config.apiUrl}/bas/uploads/${u.filename}`, {
                              responseType: "blob"
                            }).then((response) => {
                              const href = URL.createObjectURL(response.data);

                              // create "a" HTML element with href to file & click
                              const link = document.createElement('a');
                              link.href = href;
                              link.setAttribute('download', u.originalFilename); //or any other extension
                              document.body.appendChild(link);
                              link.click();

                              // clean up "a" element & remove ObjectURL
                              document.body.removeChild(link);
                              URL.revokeObjectURL(href);
                            })}
                            title="Ladda ned orginalfil"
                            size="large"
                          >
                            <GetApp />
                          </IconButton>
                        </div>
                      </Tooltip>
                    )}
                    {onView && !hasErrors &&
                      EyeButton(() => onView(u.id), {
                        title: "Visa tillhörande data",
                        disabled: upload?.id === u.id
                      })}
                    {onRemove && DeleteButton(() => onRemove(u.id))}
                    {action}
                  </div>
                </TableCell>
              </TableRow>
            )
          }
          )}
        </TableBody>
      </Table>
    </TableContainer >
  );
}

export { BasUploads, BasUploadsTable };
