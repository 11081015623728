import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { format } from "date-fns";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotes } from "../app";
import Header from "../main/Header";

export default function MessagesPage() {
  const user = useSelector((state) => state.app.user);
  const notes = useSelector((state) => state.app.notes);
  const dispatch = useDispatch();
  const [spotFilter, setSpotFilter] = useState("");
  const sortedNotes = _.orderBy(
    notes.filter((x) => (spotFilter ? x.spot === spotFilter : true)),
    "createdAt",
    "desc"
  );
  const spots = _.uniq(notes.map((x) => x.spot));

  useEffect(() => {
    if (notes.length === 0) dispatch(getNotes());
  }, []);
  return user?.role?.rights?.includes("messages") ? (
    <>
      <Header />
      <Container style={{ marginTop: 16, display: "grid", gap: 8 }}>
        <Typography variant="h4">Anteckningar</Typography>
        {NotesFilters({ spots, spotFilter, setSpotFilter })}
        <NotesTable notes={sortedNotes} />
      </Container>
    </>
  ) : null;
}

function NotesFilters({ spots, spotFilter, setSpotFilter }) {
  return (
    <Card>
      <CardHeader title="Filter" />
      <CardContent>
        <Autocomplete
          style={{ width: 200 }}
          id="spot"
          options={spots}
          value={spotFilter || null}
          renderInput={(params) => (
            <TextField {...params} label="Plats" variant="outlined" />
          )}
          onChange={(event, spot) => setSpotFilter(spot)}
          selectOnFocus={false}
        />
      </CardContent>
    </Card>
  );
}

function NotesTable({ notes }) {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Datum</TableCell>
            <TableCell>Plats</TableCell>
            <TableCell>Meddelande</TableCell>
            <TableCell align="right">Avsändare</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notes.map((note) => (
            <TableRow key={note.id}>
              <TableCell>
                {format(new Date(note.createdAt), "yyyy-MM-dd HH:mm")}
              </TableCell>
              <TableCell>{note.spot}</TableCell>
              <TableCell>{note.message}</TableCell>
              <TableCell align="right">
                {note.user?.name || note.user.username}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
