import { SxProps } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import logo from "../Images/logo.svg";

interface Props {
  sx?: SxProps;
  size?: number;
}
const CircleLogo: FC<Props> = (props) => {
  const { size = 150 } = props;
  return (
    <Box
      sx={{
        width: size,
        height: size,
        borderRadius: "50%",
        background: "#F3F4F6",
        boxShadow: "0 4px 10px rgba(0,0,0,0.2)",
        backgroundImage: `url(${logo})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "50% 44%",
        backgroundSize: `${(80 / 150) * size}px`,
        ...props.sx,
      }}
    />
  );
};

export default CircleLogo;
