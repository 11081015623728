import {
  Check,
  Close,
  Edit,
  RotateLeft,
  RotateRight,
} from "@mui/icons-material";
import { Card, IconButton, TextField, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import config from "../../../config";
import { useStyles } from "../../../styles/styles";
import {
  addMark,
  beginSelectionRectangle,
  deselectMark,
  endSelectionRectangle,
  patchMark,
  resetMarkSelection,
  selectMark,
  spotToNewMark,
  updateImage,
} from "../../app";
import { getEmptySpotsInImage } from "../../app/appSelectors";
import { PointsOverlay } from "./Overlay";
import { XYfromEvent } from "./utils";

export default function ActiveMap({
  radius,
  setRadius,
  setDisplayDetails,
  selectedBasRow,
  setSelectedBasRow,
  preview,
}) {
  const dispatch = useDispatch();
  const image = useSelector((state) => state.app.image);
  const modes = useSelector((state) => state.app.modes);
  const allMarks = useSelector((state) => state.app.marks);
  const marks = image ? allMarks.filter(x => x.image === image.id) : [];
  const basData = useSelector((state) => state.app.import);
  const selectedMarks = useSelector((state) => state.app.selectedMarks);
  const selectedSpots = useSelector((state) => state.app.selectedSpots);
  const imgRef = useRef(null);
  const resizeFactor = useRef(1);
  const selectRectangle = useSelector((state) => state.app.selectRectangle);
  const rulers = useSelector((state) => state.app.rulers);
  const emptySpots = useSelector((state) => getEmptySpotsInImage(state));
  const spotSelected = useSelector((state) => state.app.spotToNewMark);

  function handleClick(e) {
    // console.log('event in ActiveMap')
    e.stopPropagation();
    resizeFactor.current = e.nativeEvent.target.clientHeight / image.height;
    const ev = XYfromEvent(e);
    const { x, y } = reCalc(ev.x, ev.y);
    if (modes.includes("selectMultiple")) {
      if (selectRectangle.startX && selectRectangle.startY) {
        if (!(selectRectangle.endX && selectRectangle.endY))
          dispatch(endSelectionRectangle({ x, y }));
      } else {
        dispatch(beginSelectionRectangle({ x, y }));
      }
    }
    if (modes.includes("add")) addPoint(x, y);
    if (modes.includes("move"))
      movePoint(
        marks.find((m) => m.id === selectedMarks[0]),
        x,
        y
      );
  }

  async function addPoint(x, y) {
    if (image) {
      const mark = {
        x,
        y,
        style: { radius },
      };
      if (spotSelected) mark.spot = spotSelected;
      if (selectedBasRow) {
        mark.spot = selectedBasRow.spot;
      }
      //console.log(mark)
      const result = await dispatch(addMark({ imageId: image.id, mark }));
      if (result.meta.requestStatus === "fulfilled") {
        dispatch(spotToNewMark(""));
      }
    }
  }

  function movePoint(mark, rawX, rawY) {
    const { id } = mark;
    const { x, y } = reCalc(rawX, rawY);
    const data = { x, y };
    dispatch(patchMark({ id, data }));
  }

  function handleMarkerClick(mark) {
    setDisplayDetails(mark);

    if (!modes.includes("add")) {
      // load radius
      if (mark.style?.radius) setRadius(mark.style.radius);

      if (!modes.includes("selectMultiple")) {
        dispatch(resetMarkSelection());
      }

      if (mark.spot) {
        setSelectedBasRow(
          basData.find((x) => x.spot === getPoint(mark.id).spot)
        );
      }

      const { id } = mark;
      selectedMarks.includes(mark.id)
        ? dispatch(deselectMark({ id }))
        : dispatch(selectMark({ id }));
    }
  }

  const getPoint = (id) => marks.find((p) => p.id === id);

  /**
   * Recalculates x, y based on current resize factor (image size)
   * @param {Number} x
   * @param {Number} y
   * @returns
   */
  function reCalc(x, y) {
    let result = { x: x / resizeFactor.current, y: y / resizeFactor.current };
    if (image.rotation === 180) {
      result.x = image.width - result.x;
      result.y = image.height - result.y;
    }
    return result;
  }

  /** Rotate image degrees counterclockwise */
  const rotate = (degrees) => {
    const rotation = ((image.rotation ? image.rotation : 0) + degrees) % 360;
    const data = { rotation };
    const { id } = image;
    dispatch(updateImage({ id, data }));
  };

  const classes = useStyles();
  const [isEditing, setEditing] = useState(false);
  const [title, setTitle] = useState("");

  function Header() {
    function updateTitle() {
      const { id } = image;
      const data = { title };
      dispatch(updateImage({ id, data })).then(() => {
        setEditing(false);
      });
    }
    return (
      <div key={"map-" + image.id} className={classes.imageHeader}>
        <div id="image-title" style={{ display: "flex", alignItems: "center" }}>
          {!isEditing && (
            <Typography variant="h5">
              {image.title || "Ange titel här"}
            </Typography>
          )}
          {isEditing && (
            <>
              <TextField
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder={image.title}
                label="Ange nytt namn för vyn"
              />
              <IconButton onClick={() => updateTitle()} size="large">
                <Check />
              </IconButton>
            </>
          )}
          <IconButton onClick={() => setEditing(!isEditing)} size="large">
            {isEditing ? <Close /> : <Edit />}
          </IconButton>
        </div>
        {/* {ImageToolbox({ rotate })} */}
      </div>
    );
  }

  return (
    image && (
      <div>
        <Card
          onClick={handleClick}
          style={{
            display: "flex",
            transform: `rotate(${image.rotation || 0}deg)`,
            width: "fit-content",
            background: blue[50],
          }}
        >
          <img
            src={`${config.apiUrl}/images/${image.filename}`}
            alt=""
            id="points-background"
            draggable={false}
            className={classes.image}
            ref={imgRef}
          />
          {PointsOverlay({
            image,
            handleMarkerClick,
            resizeFactor: resizeFactor.current,
            preview,
            radius,
            marks,
            selectedMarks,
            dispatch,
            modes,
            rulers,
            emptySpots,
          })}
        </Card>
      </div>
    )
  );
}

function ImageToolbox({ rotate }) {
  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <IconButton
        onClick={() => rotate(90)}
        title="Rotera bild åt vänster"
        disabled
        size="large"
      >
        <RotateLeft />
      </IconButton>
      <IconButton
        onClick={() => rotate(-90)}
        title="Rotera bild åt höger"
        disabled
        size="large"
      >
        <RotateRight />
      </IconButton>
    </div>
  );
}
