import {
  Checkbox,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { basColumns } from "../../services/bas";
import {
  defaultColumnsSettings,
  getDefaultOverridenValue,
  getSettingsOverridenByCollections,
} from "../../services/utils/settings";
import { addOrganisationSettings } from "../app";
import { addUserSettings } from "../app/appApi";
import { StickyHeadTable } from "../data/Tables/Tables";

export default function SetBasColumns({ settingsFor = "organisation" }) {
  const dispatch = useDispatch();
  const orgSettings = useSelector((state) => state.app.orgSettings);
  const userSettings = useSelector((state) => state.app.settings);

  // console.log(defaultColumnsSettings)
  const overrideWith = [orgSettings];
  if (settingsFor === "user") overrideWith.push(userSettings);

  const settings = getSettingsOverridenByCollections(
    "columns",
    defaultColumnsSettings,
    ...overrideWith
  );
  const columns = getDefaultOverridenValue("bas", basColumns, settings);
  // console.log(settings)
  // console.log(columns)
  const HeaderColumns = (
    <>
      <TableCell>Visa</TableCell>
      <TableCell>Flytta</TableCell>
      <TableCell>Visningsnamn</TableCell>
      <TableCell>Exempel för inmatningsfält</TableCell>
    </>
  );

  // @ts-ignore
  const updateField = (field, data) => {
    const updateSettings = {
      type: "columns",
      settingsGroup: "bas",
      settings: [{ field, ...data }],
    };
    if (settingsFor === "user") {
      // @ts-ignore
      dispatch(addUserSettings(updateSettings));
    } else {
      // @ts-ignore
      dispatch(addOrganisationSettings(updateSettings));
    }
  };

  const checkField = (field, visible) => updateField(field, { visible });
  const positions = columns.map((x) => x?.position).filter((x) => x);
  // console.log(positions)
  const move = (column, position) => {
    const onNewPosition = columns.find((x) => x.position === position);
    const settings = [{ ...column, position }];
    if (onNewPosition) {
      settings.push({ ...onNewPosition, position: column.position });
    }
    const addSettings = {
      type: "columns",
      settingsGroup: "bas",
      settings,
    };
    if (settingsFor === "user") {
      // @ts-ignore
      dispatch(addUserSettings(addSettings));
    } else {
      // @ts-ignore
      dispatch(addOrganisationSettings(addSettings));
    }
  };

  return (
    <StickyHeadTable
      header={
        <TableRow>
          <TableCell colSpan={4}>
            <Typography variant="h5">Baskolumner</Typography>
          </TableCell>
        </TableRow>
      }
      stickyHeaderRow={HeaderColumns}
      size="small"
      maxHeight={400}
    >
      {columns
        .concat(
          basColumns.filter(
            (c) => columns.findIndex((x) => x.field === c.field) === -1
          )
        )
        .map((column, index) => {
          // console.log(column)
          return (
            <TableRow key={"bascolumn-row-" + column.field}>
              <TableCell>
                <Checkbox
                  checked={column?.visible || false}
                  onChange={(e) => checkField(column.field, e.target.checked)}
                />
              </TableCell>
              <TableCell>
                {column?.visible && (
                  <TextField
                    variant="outlined"
                    size="small"
                    type="number"
                    min={0}
                    max={Math.max(...positions)}
                    value={column?.position || ""}
                    onChange={(e) => move(column, Number(e.target.value))}
                    label="Position"
                  />
                )}
              </TableCell>
              <TableCell>{column.title}</TableCell>
              <TableCell>{column.inputPlaceholder}</TableCell>
            </TableRow>
          );
        })}
    </StickyHeadTable>
  );
}
