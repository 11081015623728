import { Delete } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";

export default function DeleteButton(callback, options = {}) {
  return (
    <Tooltip title={options?.title || "Radera"}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyItems: "center",
        }}
      >
        <IconButton
          onClick={() => callback()}
          title={null}
          size="small"
          {...options}
        >
          <Delete />
        </IconButton>
      </Box>
    </Tooltip>
  );
}
