import { makeStyles } from "@mui/styles";

const {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  Typography,
  TableCell,
} = require("@mui/material");

function StickyHeadTable({
  header,
  stickyHeaderRow,
  children,
  footer,
  numberOfRows,
  emptyText,
  maxHeight,
  ...rest
}) {
  const useStyles = makeStyles((theme) => ({
    table: {
      overflowY: "auto",
      overflowX: "auto",
      maxHeight: maxHeight || "100vh",
      "& table": { borderCollapse: "separate" }, // fix transparent border in sticky header row
    },
    sticky: {
      "& th": {
        margin: 0,
        position: "sticky",
        top: 0,
        backgroundColor: theme.palette.grey[100],
        zIndex: 10, // place over buttons in table
      },
    },
  }));
  const classes = useStyles();
  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table {...rest}>
        <TableHead>
          {header}
          {stickyHeaderRow && (
            <TableRow className={classes.sticky}>{stickyHeaderRow}</TableRow>
          )}
        </TableHead>
        <TableBody>
          {children}
          {((children && children.length === 0) || numberOfRows === 0) && (
            <TableRow>
              <TableCell>
                <Typography variant="body1">
                  {emptyText || "Inga rader att visa."}
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        {footer && <TableFooter>{footer}</TableFooter>}
      </Table>
    </TableContainer>
  );
}

export { StickyHeadTable };
