import { IUser } from "@hamnvy/shared";
import {
  Card,
  Table,
  TableBody,
  TableContainer,
  TableRow,
} from "@mui/material";
import { FC } from "react";
import { Trans } from "react-i18next";
import { relativeDate } from "../../../services/utils/dates";
import PaneTitle from "../components/PaneTitle";
import ResetUserButton from "../components/ResetUserButton";
import TKey from "../components/TKey";
import TVal from "../components/TVal";

interface Props {
  user: IUser;
}

const ActivityPane: FC<Props> = (props) => {
  const { user } = props;
  return (
    <Card>
      <PaneTitle label="Aktivitet" />
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TKey>
                <Trans i18nKey="user.last_login">Senast inloggad</Trans>
              </TKey>
              <TVal>
                {user.lastLogin ? (
                  relativeDate(user.lastLogin)
                ) : (
                  <Trans i18nKey="user.never_logged_in">Aldrig inloggad</Trans>
                )}
              </TVal>
            </TableRow>
            <TableRow>
              <TKey>
                <Trans i18nKey="user.send_reset_email">
                  Skicka återställningsmail
                </Trans>
              </TKey>
              <TVal>
                <ResetUserButton user={user} />
              </TVal>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default ActivityPane;
