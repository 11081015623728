import { configureStore } from "@reduxjs/toolkit";
import adminReducer from "./features/admin/adminSlice";
import appReducer from "./features/app/appSlice";
import dataReducer from "./features/data/dataSlice";
import { reduxWait } from "./services/middlewares/reduxAwait";

const store = configureStore({
  reducer: {
    app: appReducer,
    data: dataReducer,
    admin: adminReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([reduxWait]),
  devTools: process.env.NODE_ENV !== "production",
});

export default store;

// eslint-disable-next-line
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
