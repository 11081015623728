import { styled } from "@mui/styles";
import { Box } from "@mui/system";
import { FC, ReactNode } from "react";

const StyledBox = styled(Box)({
  background: "#F3F4F6",
});

interface Props {
  children: ReactNode;
}

const LoginMainContainer: FC<Props> = (props) => {
  return <StyledBox>{props.children}</StyledBox>;
};

export default LoginMainContainer;
