import { Edit } from "@mui/icons-material";
import { Chip, ChipProps } from "@mui/material";
import { FC } from "react";

const SlimChip: FC<
  { color?: string; editable?: boolean } & Omit<ChipProps, "color">
> = (props) => {
  const { color, editable, sx, onClick, ...rest } = props;
  const editableProps = editable
    ? { onDelete: onClick, deleteIcon: <Edit />, clickable: true }
    : {};

  return (
    <Chip
      {...rest}
      sx={{
        py: 0.5,
        px: 1,
        height: "unset",
        gap: 0.5,
        "& > span": { px: 0 },
        "& .MuiChip-deleteIcon": {
          margin: "0",
          marginRight: "-4px",
          fontSize: "16px",
        },
        userSelect: "none",
        ...(color && {
          background: color,
          color: "#fff",
        }),
        "& > svg": {
          color: "rgba(255,255,255,0.5) !important",
        },
        ":hover": {
          ...(color && {
            background: color,
          }),
          "& > svg": {
            color: "rgba(255,255,255,0.8) !important",
          },
        },
        ...sx,
      }}
      onClick={onClick}
      {...editableProps}
    />
  );
};

export default SlimChip;
