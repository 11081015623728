import { Add, Lock, LockOpen } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import { CloseButton, CopyButton, EyeButton } from "../../Components/utils";
import { relativeDate } from "../../services/utils/dates";
import {
  addOrganisation,
  getOrganisations,
  updateOrganisation,
} from "../admin/adminAPI";
import { forceLogout } from "../app";
import { StickyHeadTable } from "../data/Tables/Tables";
import Org from "./Org";

function RegisterOrganisation({ open = true, onClose = null }) {
  const dispatch = useDispatch();

  const [orgName, setOrgName] = useState("");
  const [orgShortName, setOrgShortName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");

  const handleOrgName = (e) => {
    setOrgName(e.target.value);
  };

  const handleOrgShort = (e) => {
    e.stopPropagation();

    setOrgShortName(e.target.value);
  };

  const handleAdress = (e) => {
    e.stopPropagation();

    setAddress(e.target.value);
  };

  const handlePhone = (e) => {
    e.stopPropagation();

    setPhone(e.target.value);
  };

  const handleEmail = (e) => {
    e.stopPropagation();

    setEmail(e.target.value);
  };

  const handleCity = (e) => {
    e.stopPropagation();
    setCity(e.target.value);
  };

  const isSubmitDisabled = () =>
    !(orgName && orgShortName && address && phone && city && email);
  const addOrg = () => {
    const payload = { orgName, orgShortName, address, phone, city, email };
    dispatch(addOrganisation(payload));
  };

  const OrgRegFields = (
    <form
      onSubmit={(e) => e.preventDefault()}
      style={{ display: "grid", gap: 8 }}
    >
      <TextField
        label="Fullständigt organisationsnamn"
        value={orgName}
        onChange={(e) => handleOrgName(e)}
        variant="outlined"
        size="small"
      />
      <TextField
        label="Kort namn"
        value={orgShortName}
        onChange={handleOrgShort}
        variant="outlined"
        size="small"
      />
      <TextField
        label="Adress"
        value={address}
        onChange={handleAdress}
        variant="outlined"
        size="small"
      />
      <TextField
        label="Ort"
        value={city}
        onChange={handleCity}
        variant="outlined"
        size="small"
      />
      <TextField
        label="Telefon"
        value={phone}
        onChange={handlePhone}
        variant="outlined"
        size="small"
      />
      <TextField
        label="Epost"
        value={email}
        onChange={handleEmail}
        variant="outlined"
        size="small"
      />
      <Button
        type="submit"
        variant="contained"
        color="secondary"
        disabled={isSubmitDisabled()}
        onClick={() => addOrg()}
      >
        Registrera
      </Button>
    </form>
  );

  return (
    open && (
      <Card>
        <CardHeader
          title="Registrera organisation"
          action={CloseButton(() => onClose())}
        />
        <CardContent>{OrgRegFields}</CardContent>
      </Card>
    )
  );
}

export default function ManageOrganisations() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.app.user);
  const admin = useSelector((state) => state.admin);

  const [isAddingOrg, setAddingOrg] = useState(false);
  const [isEditingOrg, setEditingOrg] = useState(null);

  useEffect(() => {
    // todo: move to /admin
    if (!user?.isSuperUser) {
      forceLogout();
      navigate("/login");
    }
    dispatch(getOrganisations());
  }, []);

  const HeaderRow = (
    <TableRow>
      <TableCell colSpan={2}>
        <Typography variant="h5">Organisationer</Typography>
      </TableCell>
      <TableCell colSpan={5}>
        <Button
          onClick={() => setAddingOrg(!isAddingOrg)}
          startIcon={<Add />}
          variant="contained"
          color="primary"
        >
          Ny
        </Button>
      </TableCell>
    </TableRow>
  );

  const StickyHeader = (
    <>
      <TableCell></TableCell>
      <TableCell>Fullständigt organisationsnamn</TableCell>
      <TableCell>Registrerad</TableCell>
      {/* <TableCell>Uppdaterad</TableCell> */}
      <TableCell>SBU</TableCell>
      <TableCell>BAS</TableCell>
      <TableCell>CRM</TableCell>
      <TableCell>Antal användare</TableCell>
    </>
  );

  return <>
    <Routes>
      <Route
        path="/"
        element={
          <>
            <RegisterOrganisation
              open={isAddingOrg}
              onClose={() => setAddingOrg(false)}
            />
            <StickyHeadTable
              header={HeaderRow}
              stickyHeaderRow={StickyHeader}
            >
              {admin.organisations.map((organisation) => {
                const {
                  id,
                  orgName,
                  createdAt,
                  updatedAt,
                  users,
                  memberSBU,
                  usingBAS,
                  hamnvyCRM,
                } = organisation;
                return (
                  <TableRow key={"org-row-" + id}>
                    <TableCell>
                      <div style={{ display: "flex", gap: 4 }}>
                        {CopyButton(() => navigator.clipboard.writeText(id), {
                          title: "Kopiera ID",
                        })}
                        <IconButton
                          onClick={() =>
                            setEditingOrg(isEditingOrg === id ? null : id)
                          }
                          size="large">
                          {isEditingOrg === id ? <LockOpen /> : <Lock />}
                        </IconButton>
                        {EyeButton(() =>
                          navigate("/admin/organisations/" + id)
                        )}
                      </div>
                    </TableCell>
                    <TableCell>{orgName}</TableCell>
                    <TableCell>
                      <Tooltip title={createdAt || ""}>
                        <span>{relativeDate(createdAt)}</span>
                      </Tooltip>{" "}
                    </TableCell>
                    {/* <TableCell>{updatedAt}</TableCell> */}
                    <TableCell>
                      <Tooltip title="Medlem SBU">
                        <Checkbox
                          checked={memberSBU}
                          disabled={isEditingOrg !== id}
                          onClick={(e) =>
                            dispatch(
                              updateOrganisation({
                                id,
                                memberSBU: e.target.checked,
                              })
                            )
                          }
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Använder BAS">
                        <Checkbox
                          checked={usingBAS}
                          disabled={isEditingOrg !== id}
                          onClick={() =>
                            dispatch(
                              updateOrganisation({ id, usingBAS: !usingBAS })
                            )
                          }
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Hamnvy CRM">
                        <Checkbox
                          checked={hamnvyCRM}
                          disabled={isEditingOrg !== id}
                          onClick={() =>
                            dispatch(
                              updateOrganisation({
                                id,
                                hamnvyCRM: !hamnvyCRM,
                              })
                            )
                          }
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell>{users && users.length}</TableCell>
                  </TableRow>
                );
              })}
            </StickyHeadTable>
          </>
        }
      />

      <Route path="/:id" element={<Org />} />
    </Routes>
  </>;
}
