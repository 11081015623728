import { FilePreview } from "@hamnvy/shared";
import { Box } from "@mui/material";
import { FC } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ImageAndFileUpload from "../../../Components/utils/ImageAndFileUpload";
import { getLocalUrlForUploadedFile } from "../../../services/utils/files";
import GroupedAttachmentThumbnails from "../GroupedAttachmentThumbnails";

const AssignmentAttachments: FC<{}> = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { watch } = useFormContext();
  const attachments: (FilePreview & { id: number })[] =
    watch("assignment.attachments") ?? [];

  const { append: addAttachment, remove: removeAttachment } = useFieldArray({
    control: control,
    name: "assignment.attachments",
  });

  const afterFileDeletion = (id: string) => {
    const index = attachments.findIndex((x) => x.summary.id === id);
    if (index > -1) removeAttachment(index);
  };

  const onUpload = (file: File, upload: FilePreview) => {
    addAttachment({
      src: getLocalUrlForUploadedFile(file),
      summary: upload.summary,
    });
  };

  return (
    <Box>
      <GroupedAttachmentThumbnails
        attachments={attachments}
        afterFileDeletion={afterFileDeletion}
        isEditing
      />
      <ImageAndFileUpload
        id="assignment-add"
        type="assignments"
        onUpload={onUpload}
        label={t("attachment.attach", { defaultValue: "Bifoga" })}
        sx={{ justifyContent: "space-between" }}
      />
    </Box>
  );
};

export default AssignmentAttachments;
