import { Typography, useTheme } from "@mui/material";
import { Box, SxProps } from "@mui/system";
import { FC, isValidElement, ReactNode } from "react";

interface Props {
  title: string | ReactNode;
  children?: ReactNode;
  sx?: SxProps;
  titleHeader?: ReactNode;
}

const PageHeader: FC<Props> = (props) => {
  const { title, children, sx, titleHeader } = props;
  const theme = useTheme();
  return (
    <Box
      sx={{
        background: "#eee",
        // background: theme.palette.primary.main,
        // color: "#fff",
        marginTop: "-16px",
        px: 3,
        py: 2,
        mb: 2,
        display: "flex",
        columnGap: 4,
        rowGap: 2,
        alignItems: "center",
        flexWrap: "wrap",
        borderBottom: "1px solid #ccc",
        ...sx,
      }}
    >
      <Box
        className="title"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "space-between",
        }}
      >
        {titleHeader}
        {isValidElement(title) ? (
          title
        ) : (
          <Typography variant="h5">{title}</Typography>
        )}
      </Box>
      {children}
    </Box>
  );
};

export default PageHeader;
