import { Box } from "@mui/system";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getBoats, getCustomers, getSpots } from "../app";
import Summary from "../customer/summary";
import Header from "../main/Header";
import PageHeader from "../main/PageHeader";

export default function MarinaInfo() {
  const org: any = useAppSelector((state) => state.app.org);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCustomers());
    dispatch(getBoats());
    dispatch(getSpots());
  }, []);

  return (
    <>
      <Header>
        <PageHeader
          title={
            "Hamninformation: " + (org.orgShortName ?? "Organisationsnamn")
          }
        />
      </Header>
      <Box sx={{ px: 3 }}>
        <Summary />
      </Box>
    </>
  );
}
