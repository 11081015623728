import axios from "axios";
import config from "../config";
import { forceLogout, gotAccessFromRefreshToken } from "../features/app";

const api = axios.create({
  baseURL: config.apiUrl,
  // withCredentials: true
});

// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  // console.log({error, token, failedQueue})
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const httpService = {
  setupInterceptor: (store) => {
    api.interceptors.request.use(async (request) => {
      let accessToken = store.getState().app.access.token;

      if (accessToken) {
        request.headers["Authorization"] = "Bearer " + accessToken;
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + accessToken;
      }

      return request;
    });

    api.interceptors.response.use(
      (response) => {
        // simply return the response if there is no error
        return response;
      },
      (error) => {
        const isLoggedIn = localStorage.getItem("logged_in");
        const originalConfig = error.config;
        if (
          isLoggedIn &&
          error.response.status === 401 &&
          !originalConfig._retry
        ) {
          if (isRefreshing) {
            return new Promise(function (resolve, reject) {
              failedQueue.push({ resolve, reject });
            })
              .then((token) => {
                originalConfig.headers["Authorization"] = "Bearer " + token;
                return axios(originalConfig);
              })
              .catch((err) => {
                return Promise.reject(err);
              });
          }

          originalConfig._retry = true;
          isRefreshing = true;

          /** Refresh token finns i cookies som httpOnly, alltså ej tillgänglig för avläsning i klienten */
          return new Promise(function (resolve, reject) {
            axios
              .get(`${config.apiUrl}/auth/refresh_token`, {
                withCredentials: true,
              })
              .then((result) => {
                store.dispatch(gotAccessFromRefreshToken(result.data));
                processQueue(null, result.data?.access?.token);
                resolve(api(originalConfig));
              })
              .catch((err) => {
                processQueue(err, null);
                store.dispatch(forceLogout());
                reject(err);
              })
              .finally(() => {
                isRefreshing = false;
              });
          });
        }

        return Promise.reject(error);
      }
    );
  },
};

export { api, httpService };
