import { IUser } from "@hamnvy/shared";
import { Box } from "@mui/system";
import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "../../Components/utils/BackButton";
import { useAppDispatch, useAppSelector } from "../../hooks";
import NotFoundPage from "../../routes/404.page";
import { getUsers } from "../app";
import Header from "../main/Header";
import PageHeader from "../main/PageHeader";
import ActivityPane from "./panes/ActivityPane";
import OperationsPane from "./panes/OperationsPane";
import UserDetailsPane from "./panes/UserDetailsPane";

interface Props {}

const UserDetailsPage: FC<Props> = (props) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const users: IUser[] = useAppSelector((s) => s.app.users);

  useEffect(() => {
    if (users.length === 0) {
      dispatch(getUsers());
    }
  }, []);

  const user = users.find((x) => x.id === id);
  const navigate = useNavigate();

  return user ? (
    <>
      <Header>
        <PageHeader
          title={`Användare: ${user.username}`}
          titleHeader={
            <Box sx={{ "& *": { color: "white !important" } }}>
              <BackButton label="Tillbaka" onClick={() => navigate("/users")} />
            </Box>
          }
        />
      </Header>
      <Box
        sx={{
          px: 2,
          display: "flex",
          gap: 2,
          flexWrap: "wrap",
        }}
      >
        <UserDetailsPane user={user} />
        <ActivityPane user={user} />
        {/* <GroupsCard user={user} /> */}
        <OperationsPane user={user} />
      </Box>
    </>
  ) : (
    <NotFoundPage />
  );
};

export default UserDetailsPage;
