import store from "../store";
import { updateMarkField } from "../features/app";

const TYPES = [
  { type: "string", name: "Kort text" },
  { type: "text", name: "Lång text" },
  { type: "number", name: "Numeriskt tal" },
  { type: "boolean", name: "Sant/falskt" },
];

const isFieldDisabled = (field, mark) =>
  [mark.id, "*"].some((x) => field?.disableEditing.includes(x));

const isFieldGloballyDisabled = (field) => field?.disableEditing.includes("*");

function disableField(field, mark) {
  const { id } = field;
  const disableEditing = [...new Set([].concat(field?.disableEditing, mark.id))];
  const data = { disableEditing };

  store.dispatch(updateMarkField({ id, data }));
}

function enableField(field, mark) {
  const { id } = field;
  const disableEditing = field.disableEditing.filter((x) => x !== mark.id);
  const data = { disableEditing };

  store.dispatch(updateMarkField({ id, data }));
}

function disableFieldGlobally(field) {
  const { id } = field;
  const disableEditing = field.disableEditing.filter((x) => x !== "*");
  const data = { disableEditing: ["*"].concat(disableEditing) };
  store.dispatch(updateMarkField({ id, data }));
}

function enableFieldGlobally(field) {
  const { id } = field;
  const disableEditing = field.disableEditing.filter((x) => x !== "*");
  const data = { disableEditing };
  store.dispatch(updateMarkField({ id, data }));
}

export {
  TYPES,
  isFieldDisabled,
  disableField,
  enableField,
  isFieldGloballyDisabled,
  enableFieldGlobally,
  disableFieldGlobally
};
