import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import { isFulfilled } from "@reduxjs/toolkit";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CloseButton } from "../../Components/utils";
import i18n from "../../i18n";
import { basFileUpload, updateBasUpload } from "../app";

function BasFileUpload({ open, onClose = null }) {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [fileBase64, setFileBase64] = useState(null);
  const org = useSelector((state) => state.app.org);
  const { enqueueSnackbar } = useSnackbar();

  async function handleChange(event) {
    const files = event.target.files;
    if (files && files.length > 0) {
      setFile(files[0]);
      const reader = new FileReader();
      reader.onload = function (event) {
        setFileBase64(event.target.result);
      };
      await reader.readAsDataURL(files[0]);
    }
  }

  async function onSubmit() {
    try {
      const data = new FormData();
      const blob = await fetch(fileBase64).then((res) => res.blob());
      data.append("file", blob);
      const result = await dispatch(basFileUpload({ data }));
      if (isFulfilled(result)) {
        enqueueSnackbar(
          i18n.t("fileUpload.create_snack_success", {
            defaultValue: "Filuppladdningen lyckades",
          }),
          { variant: "success" }
        );
      }
      if (result.payload?.upload) {
        dispatch(
          updateBasUpload({
            id: result.payload.upload.id,
            data: { originalFilename: file.name, org: org.id },
          })
        );
      }
      setFile(null);
      setFileBase64(null);
      if (onClose) onClose();
    } catch (err) {
      alert("fel uppstod");
      console.log(err);
    }
  }

  const accept =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

  return (
    open && (
      <Card id="file-upload">
        <CardHeader
          title="Filuppladdning"
          action={onClose && CloseButton(() => onClose())}
        />
        <CardContent>
          {!fileBase64 && (
            <Typography variant="body1">
              För att importera data från BAS
            </Typography>
          )}
          {file && file?.name && (
            <Typography variant="body1">
              Vald fil: <b>{file.name}</b>
            </Typography>
          )}
        </CardContent>
        <CardActions>
          <input
            id="file-upload-btn"
            type="file"
            accept={accept}
            hidden
            onChange={handleChange}
          />
          <label htmlFor="file-upload-btn">
            <Button
              variant={fileBase64 ? "text" : "contained"}
              color="primary"
              component="span"
            >
              Välj en {fileBase64 && "ny"} fil
            </Button>
          </label>
          <Button
            variant={fileBase64 ? "contained" : "text"}
            color={fileBase64 ? "primary" : undefined}
            type="submit"
            onClick={onSubmit}
            disabled={!fileBase64}
          >
            Ladda upp
          </Button>
        </CardActions>
      </Card>
    )
  );
}

export { BasFileUpload };
