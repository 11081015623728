import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useSelector } from "react-redux";
import { CreateBoat, ManageBoats } from "../customer/manageBoats";
import Header from "../main/Header";
import PageHeader from "../main/PageHeader";

export default function CRMBoatPage() {
  const customers = useSelector((state) => state.app.customers);
  const [isCreatingBoat, setCreatingBoat] = useState(false);

  return (
    <>
      <Header>
        <PageHeader title="Båtar">
          <Box sx={{ display: "flex", gap: 1, color: "white" }}>
            <Button
              variant="outlined"
              color="inherit"
              sx={{
                backgroundColor: "secondary.main",
                textTransform: "none",
                "& .MuiButton-startIcon": { mr: 0.5 },
              }}
              startIcon={<Add />}
              onClick={() => setCreatingBoat(true)}
            >
              Ny båt
            </Button>
          </Box>
        </PageHeader>
      </Header>
      <Box sx={{ px: 3 }}>
        <Box sx={{ py: 1 }}>
          <ManageBoats />
        </Box>
        <CreateBoat
          open={isCreatingBoat}
          customers={customers}
          onClose={() => setCreatingBoat(false)}
        />
      </Box>
    </>
  );
}
