import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { sv } from "date-fns/locale";
import { SnackbarProvider } from "notistack";
import { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import LoadingApp from "./features/app/LoadingApp";
import i18n from "./i18n";
import reportWebVitals from "./reportWebVitals";
import AppRoutes from "./routes/AppRoutes";
import { httpService } from "./services/api";
import store from "./store";
import "./styles/index.css";
import theme from "./styles/theme";

httpService.setupInterceptor(store);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3}>
              <BrowserRouter>
                <Suspense fallback={<LoadingApp />}>
                  <AppRoutes />
                </Suspense>
              </BrowserRouter>
            </SnackbarProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </Provider>
  </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
