import { IUser } from "@hamnvy/shared";
import { Autocomplete, TextField } from "@mui/material";
import { FC, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getUsers } from "../../app";

const FIELD_NAME = "assignment.labels";

const AssignmentCategoriesField: FC<{}> = (props) => {
  const users: IUser[] = useAppSelector((s) => s.app.users);
  const dispatch = useAppDispatch();
  const { control, setValue } = useFormContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (users.length === 0) {
      dispatch(getUsers());
    }
  }, []);

  return (
    <Controller
      defaultValue={[]}
      control={control}
      name={FIELD_NAME}
      render={({ field }) => (
        <Autocomplete
          multiple
          size="small"
          options={["El", "Vatten", "Brygga", "Förtöjning"]}
          freeSolo
          forcePopupIcon
          noOptionsText={t("workAssignment.category_empty", {
            defaultValue: "Hittar ingen att tilldela till",
          })}
          renderInput={(params) => (
            <TextField
              label={t("workAssignment.category", { defaultValue: "Kategori" })}
              {...params}
            />
          )}
          {...field}
          onChange={(e, values) => setValue(FIELD_NAME, values)}
        />
      )}
    />
  );
};

export default AssignmentCategoriesField;
