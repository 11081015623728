const customerColumns = [
  { type: 'string', field: "name", title: "Namn", inputPlaceholder: "Förnamn Efternamn" },
  { type: 'string', field: "address", title: "Adress", inputPlaceholder: "Exempelväg 32" },
  { type: 'string', field: "postalCode", title: "Postnr", inputPlaceholder: "412 76" },
  { type: 'string', field: "city", title: "Ort", inputPlaceholder: "Göteborg" },
  { type: 'string', field: "phone", title: "Telefon", inputPlaceholder: "0712345678"},
  { type: 'string', field: "email", title: "Epost", inputPlaceholder: "namn@exempel.se" },
];

const boatColumns = [
  { type: 'string', field: "name", title: "Båtnamn", inputPlaceholder: "Prinsessan" },
  { type: 'string', field: "brand", title: "Båtfabrikat", inputPlaceholder: "Bavaria" },
  { type: 'string', field: "type", title: "Båttyp", inputPlaceholder: "Segelbåt" },
  { type: 'string', field: "model", title: "Båtmodell", inputPlaceholder: "32" },
  { type: 'string', field: "serial", title: "Serienummer", inputPlaceholder: "XXXX-YYYY" },
  { type: 'number', field: "length", title: "Längd", inputPlaceholder: "10 m" },
  { type: 'number', field: "width", title: "Bredd", inputPlaceholder: "3 m" },
  { type: 'number', field: "depth", title: "Djup", inputPlaceholder: "1.5 m" },
  { type: 'number', field: "height", title: "Höjd", inputPlaceholder: "14 m" },
  { type: 'number', field: "weight", title: "Vikt", inputPlaceholder: "4 ton" },
  { type: 'string', field: "note", title: "Båtkommentar", inputPlaceholder: "Strulig motor" },
];

const spotColumns = [
  { type: 'string', field: "spotNumber", title: "Plats", inputPlaceholder: "A1" },
  { type: 'number', field: "length", title: "Längd", inputPlaceholder: "10 m" },
  { type: 'number', field: "width", title: "Bredd", inputPlaceholder: "4 m" },
  { type: 'number', field: "depth", title: "Djup", inputPlaceholder: "3 m" },
  { type: 'number', field: "height", title: "Höjd", inputPlaceholder: "14 m" },
  { type: 'string', field: "note", title: "Anteckning", inputPlaceholder: "Bomm från 2019" },
];

/** Get Columns from fieldnames */
const getCustomerColumns = (...fields) =>
  fields.map((field) => customerColumns.find((c) => c.field === field));

  /** Get Columns from fieldnames */
const getBoatColumns = (...fields) =>
  fields.map((field) => boatColumns.find((c) => c.field === field));

/** Get Columns from fieldnames */
const getSpotColumns = (...fields) =>
fields.map((field) => spotColumns.find((c) => c.field === field));

const defaultCustomerColumns = getCustomerColumns("name", "phone");
const defaultBoatColumns = getBoatColumns("brand", "model", "length", "width")
const defaultSpotColumns = getSpotColumns("spotNumber", "width", "note")

export { 
  customerColumns, 
  getCustomerColumns, 
  defaultCustomerColumns,
  boatColumns,
  getBoatColumns,
  defaultBoatColumns,
  spotColumns,
  getSpotColumns,
  defaultSpotColumns
};
