export default function NotFoundPage(props) {
    return <div style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "grey",
        gap: 16,
      }}>404: Sidan kunde inte hittas</div>
}