import { Remove } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

export default function MinusButton(callback, options = []) {
  return (
    <Tooltip title={options?.title || "Radera"}>
      <div>
        <IconButton onClick={() => callback()} {...options} title={null} size="large">
          <Remove />
        </IconButton>
      </div>
    </Tooltip>
  );
}
