import { Lock, LockOpen } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import {
  disableField,
  disableFieldGlobally,
  enableField,
  enableFieldGlobally,
  isFieldDisabled,
  isFieldGloballyDisabled,
} from "../../../services/customData";

export function LockField({ mark = null, field, lockGlobally = false }) {
  const open = mark
    ? !isFieldDisabled(field, mark)
    : !isFieldGloballyDisabled(field);
  const toggle = () => {
    if (lockGlobally) {
      open ? disableFieldGlobally(field) : enableFieldGlobally(field, mark);
    } else {
      open ? disableField(field, mark) : enableField(field, mark);
    }
  };
  const disabledGlobally = isFieldGloballyDisabled(field);
  const title = disabledGlobally
    ? "Låst för alla platser"
    : `Lås${open ? "" : " upp"} ${field.name.toLowerCase()} ${
        lockGlobally ? "för alla platser" : ""
      }`;

  return (
    <Tooltip title={title}>
      <span>
        <IconButton
          disabled={lockGlobally ? false : disabledGlobally}
          onClick={() => toggle()}
          size="large">
          {open ? <LockOpen /> : <Lock />}
        </IconButton>
      </span>
    </Tooltip>
  );
}
