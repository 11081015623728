import store from "../../store";

export function userHasRight(right) {
  const state = store.getState();
  return state.app.user?.role?.rights.includes(right) || false;
}

export function userIsSuperUser() {
  const state = store.getState();
  return state.app.user?.isSuperUser || false;
}

export function userIsAdmin() {
  const state = store.getState();
  return state.app.user?.isAdmin || false;
}
