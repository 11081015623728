import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { DownButton, MinusButton, UpButton } from "../../../Components/utils";
import {
  boatColumns,
  customerColumns,
  spotColumns,
} from "../../../services/crm";
import { moveDown, moveUp } from "../../../services/utils/arrays";
import {
  AddBoatDetailsField,
  AddCustomerDetailsField,
  AddSpotDetailsField,
} from "./DisplayDetails";

export default function CRMDetailsSettings({
  boatFields = [],
  ownerFields = [],
  spotFields = [],
  onBoatChange,
  onOwnerChange,
  onSpotChange,
}) {
  const dispatch = useDispatch();
  const spotTable = (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={3}>Plats</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {spotFields
            .map((x) => spotColumns.find((y) => y.field === x))
            .map((x) => (
              <TableRow key={x.field}>
                <TableCell padding="none">
                  {UpButton(() => onSpotChange(moveUp(x.field, spotFields)), {
                    size: "small",
                    disabled: spotFields.indexOf(x.field) === 0,
                  })}
                  {DownButton(
                    () => onSpotChange(moveDown(x.field, spotFields)),
                    {
                      size: "small",
                      disabled:
                        spotFields.indexOf(x.field) === spotFields.length - 1,
                    }
                  )}
                </TableCell>
                <TableCell>{x.title}</TableCell>
                <TableCell padding="none" align="right">
                  {MinusButton(() =>
                    onSpotChange(spotFields.filter((c) => c != x.field))
                  )}
                </TableCell>
              </TableRow>
            ))}
          <TableRow>
            <TableCell colSpan={3}>
              <AddSpotDetailsField
                fields={spotFields}
                onChange={(_, o) =>
                  o ? onSpotChange([...spotFields, o.field]) : null
                }
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
  const boatTable = (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={3}>Båt</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {boatFields
            .map((x) => boatColumns.find((y) => y.field === x))
            .map((x) => (
              <TableRow key={x.field}>
                <TableCell padding="none">
                  {UpButton(() => onBoatChange(moveUp(x.field, boatFields)), {
                    size: "small",
                    disabled: boatFields.indexOf(x.field) === 0,
                  })}
                  {DownButton(
                    () => onBoatChange(moveDown(x.field, boatFields)),
                    {
                      size: "small",
                      disabled:
                        boatFields.indexOf(x.field) === boatFields.length - 1,
                    }
                  )}
                </TableCell>
                <TableCell>{x.title}</TableCell>
                <TableCell padding="none" align="right">
                  {MinusButton(() =>
                    onBoatChange(boatFields.filter((c) => c != x.field))
                  )}
                </TableCell>
              </TableRow>
            ))}
          <TableRow>
            <TableCell colSpan={3}>
              <AddBoatDetailsField
                fields={boatFields}
                onChange={(_, o) =>
                  o ? onBoatChange([...boatFields, o.field]) : null
                }
              />{" "}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
  const ownerTable = (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={3}>Ägare</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ownerFields
            .map((x) => customerColumns.find((y) => y.field === x))
            .map((x) => (
              <TableRow key={x.field}>
                <TableCell padding="none">
                  {UpButton(() => onOwnerChange(moveUp(x.field, ownerFields)), {
                    size: "small",
                    disabled: ownerFields.indexOf(x.field) === 0,
                  })}
                  {DownButton(
                    () => onOwnerChange(moveDown(x.field, ownerFields)),
                    {
                      size: "small",
                      disabled:
                        ownerFields.indexOf(x.field) === ownerFields.length - 1,
                    }
                  )}
                </TableCell>
                <TableCell>{x.title}</TableCell>
                <TableCell padding="none" align="right">
                  {MinusButton(() =>
                    onOwnerChange(ownerFields.filter((c) => c != x.field))
                  )}
                </TableCell>
              </TableRow>
            ))}
          <TableRow>
            <TableCell colSpan={3}>
              <AddCustomerDetailsField
                fields={ownerFields}
                onChange={(_, o) =>
                  o ? onOwnerChange([...ownerFields, o.field]) : null
                }
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
  return (
    <>
      {spotTable}
      {boatTable}
      {ownerTable}
    </>
  );
}
