import { TextField, TextFieldProps } from "@mui/material";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const FirstnameField: FC<TextFieldProps> = (props) => {
  const { control } = useFormContext();
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name="user.firstname"
      defaultValue=""
      render={({ field }) => (
        <TextField
          label={t("user.firstname", {
            defaultValue: "Förnamn",
          })}
          type="text"
          {...props}
          {...field}
        />
      )}
    />
  );
};

export default FirstnameField;
