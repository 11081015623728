import { Delete, ScreenRotation, ShowChart } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Slider,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CloseButton,
  ConfirmDialog,
  DeleteButton,
} from "../../../../Components/utils";
import {
  isMarkCloseToRuler,
  snapMarksToCloseRuler,
} from "../../../../services/utils/rulers";
import { getMarkSetting } from "../../../../services/utils/settings";
import { useStyles } from "../../../../styles/styles";
import {
  deleteMarks,
  deselectAllMarks,
  patchMark,
  patchMarks,
  removeMode,
  selectAllMarks,
  selectMarks,
  setRulerSensitivity,
  spotToNewMark,
  toggleMode,
} from "../../../app";
import { getEmptySpotsInImage } from "../../../app/appSelectors";
import { HelpTexts } from "./HelpTexts";
import { Modes } from "./Modes";

function Toolbox({
  radius,
  setRadius,
  selectedBasRow,
  setSelectedBasRow,
  preview,
  setPreview,
  onClose = null,
}) {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.app.import);
  const user = useSelector((state) => state.app.user);
  const modes = useSelector((state) => state.app.modes);
  const availableSpots = useSelector((state) => getEmptySpotsInImage(state));
  const selectedMarks = useSelector((state) => state.app.selectedMarks);
  const selectedSpots = useSelector((state) => state.app.selectedSpots);
  const rulers = useSelector((state) => state.app.rulers);
  const image = useSelector((state) => state.app.image);
  const rulerSensitivity = useSelector((state) => state.app.rulerSensitivity);
  const marks = useSelector((state) => state.app.marks).filter(
    (x) => x.image === image.id
  );
  const remove = (mode) => dispatch(removeMode(mode));
  const spots = useSelector((s) => s.app.spots);
  const spotSelected = useSelector((state) => state.app.spotToNewMark);
  const [isDeletingMarks, setDeletingMarks] = useState(false);

  function deleteSelected() {
    const ids = selectedMarks;
    dispatch(deleteMarks({ ids }));
    dispatch(deselectAllMarks());
    setDeletingMarks(false);
  }

  function deselectAll() {
    dispatch(deselectAllMarks());
  }

  function selectAll() {
    dispatch(selectAllMarks());
  }

  const allSelected = selectedMarks.length === marks.length;
  const noMarks = marks.length === 0;
  const noSelected = selectedMarks.length === 0;
  const save = () => {
    remove("edit");
    remove("move");

    dispatch(
      patchMarks({ marks: marks.filter((x) => selectedMarks.includes(x.id)) })
    );
  };

  function handleRadiusChange(e, radius) {
    setRadius(Number(radius));
    const patchedMarks = selectedMarks.map((id) => ({
      id,
      style: {
        ...marks.find((x) => x.id === id).style,
        radius: Number(radius),
      },
    }));
    dispatch(patchMarks({ marks: patchedMarks }));
  }

  function ifMultipleSelected(addString) {
    if (selectedMarks.length > 1) return addString;
  }
  const classes = useStyles();

  function EditActions() {
    const isAnyMarkCloseToRuler = marks
      .map((m) => isMarkCloseToRuler(m, rulers))
      .some((x) => x);

    return (
      modes.includes("edit") && (
        <div style={{ display: "grid", gap: 8 }}>
          {!noSelected && (
            <div>
              <ConfirmDialog
                title={`Radera ${
                  selectedMarks.length === 1 ? "en" : selectedMarks.length
                } markering${selectedMarks.length > 1 ? "ar" : ""}?`}
                onConfirm={() => deleteSelected()}
                open={isDeletingMarks}
                setOpen={setDeletingMarks}
              />
              <Button
                startIcon={<Delete />}
                onClick={() => setDeletingMarks(true)}
                disabled={noSelected}
                variant="contained"
                style={{ backgroundColor: "#DF2935", color: "white" }}
              >
                Ta bort markerad{ifMultipleSelected("e")}
              </Button>
            </div>
          )}
          <div>
            <Tooltip title="För att placera platser på en rak linje">
              <Button
                startIcon={<ShowChart />}
                onClick={() => dispatch(toggleMode("ruler"))}
                variant={modes.includes("ruler") ? "outlined" : "contained"}
                color="secondary"
              >
                {!modes.includes("ruler")
                  ? "Skapa hjälplinje"
                  : "Sluta skapa hjälplinjer"}
              </Button>
            </Tooltip>
          </div>
          <Tooltip
            title={
              !isAnyMarkCloseToRuler
                ? "Inga platser nära linjal"
                : "Klicka för att flytta närliggande platser till närmaste punkt på linjal"
            }
          >
            <div>
              <Button
                startIcon={<ScreenRotation />}
                variant="contained"
                color="secondary"
                onClick={() => snapMarksToCloseRuler(marks, rulers)}
                disabled={!isAnyMarkCloseToRuler}
              >
                Anpassa platser till hjälplinjer
              </Button>
            </div>
          </Tooltip>
          {modes.includes("ruler") && (
            <Typography variant="body1" className="help">
              Klicka för att ange startpunkt för ny linjal, och klicka igen för
              att ange slutpunkt. <br />
              Genom att klicka på linjalen raderas den. Närliggande platser
              markeras och kan anpassas till linjalen.
            </Typography>
          )}
          <div style={{ width: 200 }}>
            <Typography id="ruler-slider" gutterBottom>
              Hjälplinjens precision
            </Typography>
            <Slider
              value={rulerSensitivity}
              onChange={(e, val) => dispatch(setRulerSensitivity(val))}
              min={0}
              max={100}
              aria-labelledby="ruler-slider"
              step={1}
              valueLabelDisplay="auto"
            />
          </div>
        </div>
      )
    );
  }

  function SpotSelect() {
    function handleSpotChange(event, selectedObject) {
      select(selectedObject);
    }

    function assignSpot(spot) {
      const id = selectedMarks[0];
      dispatch(patchMarks({ marks: [{ id, spot }] }));
    }

    function select(row) {
      setSelectedBasRow(row);
      assignSpot(row.spot);
    }

    const handleCRMSpotChange = (spot) => {
      if (modes.includes("add")) dispatch(spotToNewMark(spot));
      else dispatch(patchMark({ id: selectedMarks[0], data: { spot } }));
    };

    const spotsInImage = marks.map((x) => x?.spot).filter((y) => y);
    const rowsNotInImage = data.filter(
      (row) => !spotsInImage.includes(row.spot)
    );

    if (
      (modes.includes("add") || modes.includes("edit")) &&
      selectedMarks.length <= 1
    ) {
      let input;
      if (user.org.usingBAS)
        input = (
          <Autocomplete
            style={{ width: 200 }}
            id="spot"
            options={modes.includes("add") ? rowsNotInImage : data}
            value={selectedBasRow}
            isOptionEqualToValue={(option) =>
              option.spot === selectedBasRow.spot
            }
            getOptionLabel={(option) => option.spot}
            getOptionDisabled={(option) => spotsInImage.includes(option.spot)}
            renderInput={(params) => (
              <TextField {...params} label="Välj plats" variant="outlined" />
            )}
            onChange={(event, newValue) => handleSpotChange(event, newValue)}
            selectOnFocus={false}
          />
        );
      else if (user.org.hamnvyCRM)
        input = (
          <Autocomplete
            style={{ width: 200 }}
            id="spot"
            options={spots.map((x) => x.spotNumber)}
            value={
              modes.includes("add")
                ? spotSelected
                : marks.find((x) => x.id === selectedMarks[0])?.spot
            }
            getOptionDisabled={(option) => spotsInImage.includes(option)}
            renderInput={(params) => (
              <TextField {...params} label="Välj plats" variant="outlined" />
            )}
            // todo: vänta med dispatch
            onChange={(event, spot) => handleCRMSpotChange(spot)}
            selectOnFocus={false}
          />
        );

      return (
        <div style={{ display: "flex" }}>
          {input}
          {modes.includes("edit") &&
            DeleteButton(() =>
              dispatch(
                patchMark({ id: selectedMarks[0], data: { spot: null } })
              )
            )}
        </div>
      );
    }
    return null;
  }

  function SelectionButtons() {
    const ids = marks
      .filter((m) => (m?.spot ? availableSpots.includes(m.spot) : false))
      .map((x) => x.id);
    return (
      <>
        <Button onClick={() => deselectAll()} disabled={noSelected}>
          Avmarkera alla
        </Button>
        <Button onClick={() => selectAll()} disabled={noMarks || allSelected}>
          Markera alla
        </Button>
        <Tooltip
          title={
            availableSpots.length === 0
              ? "Alla platser är upptagna"
              : `Markera ${availableSpots.length} lediga platser`
          }
        >
          <div>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => dispatch(selectMarks({ ids }))}
              disabled={availableSpots.length === 0}
            >
              Markera lediga
            </Button>
          </div>
        </Tooltip>
      </>
    );
  }

  function Size() {
    return (
      (modes.includes("add") || modes.includes("edit")) && (
        <div style={{ width: 200 }}>
          <Typography id="marker-size-slider" gutterBottom>
            Storlek
          </Typography>
          <Slider
            value={radius || getMarkSetting("defaultSize")}
            onChange={handleRadiusChange}
            min={0}
            max={50}
            aria-labelledby="radius-slider"
            step={1}
            valueLabelDisplay="auto"
            disabled={modes.includes("edit") && noSelected}
          />
        </div>
      )
    );
  }

  return (
    <Card id="actions">
      <CardHeader
        title="Verktygslåda"
        action={onClose ? CloseButton(() => onClose()) : null}
      />
      <CardContent className={classes.toolboxContent}>
        <SelectionButtons />
        {user?.role?.rights.includes("editMarks") && (
          <>
            {Modes({
              modes,
              noSelected,
              setPreview,
              preview,
              ifMultipleSelected,
              setSelectedBasRow,
              deselectAll,
            })}
            {HelpTexts(modes)}
            <SpotSelect />
            {modes.includes("edit") && selectedMarks.length === 1 && (
              <span>
                Position på bild: <PositionString markId={selectedMarks[0]} />
              </span>
            )}
            <Size />
            <EditActions />
          </>
        )}
      </CardContent>
    </Card>
  );

  function PositionString({ markId }) {
    const mark = marks.find((x) => x.id === markId);
    return mark ? (
      <span>{`(${Number(mark.x).toFixed(0)}, ${Number(mark.y).toFixed(
        0
      )})`}</span>
    ) : null;
  }
}

export { Toolbox };
