import { appSlice } from "./appSlice";
export * from './appSlice';

// Action creators are generated for each case reducer function
export const {
  selectImage,
  useLatestBasImport,
  selectMark,
  deselectMark,
  resetMarkSelection,
  selectAllMarks,
  deselectAllMarks,
  addMode,
  removeMode,
  toggleMode,
  toggleRow,
  moveSelectedMarks,
  setOriginalsFromMarkSelection,
  beginSelectionRectangle,
  endSelectionRectangle,
  clearSelectionRectangle,
  beginRuler,
  endRuler,
  deleteRuler,
  // setMouse,
  ruler,
  setRulerSensitivity,
  selectMarks,
  deselectRows,
  selectRows,
  logout,
  forceLogout,
  gotAccessFromRefreshToken,
  setDetailsColumns,
  clearApiMessage, 
  selectOrganisation,
  setBoatDetails,
  setOwnerDetails,
  setSpotDetails,
  spotToNewMark
} = appSlice.actions;
