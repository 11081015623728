import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { CloseButton } from "../../Components/utils";
import { customerColumns } from "../../services/crm";
import { addCustomer } from "../app";

export default function PasteAndImportCustomers({ onClose }) {
  const [columns, setColumns] = useState({});
  const [text, setText] = useState("");
  const dispatch = useDispatch();

  const handleColumnChange = (e, field) => {
    const newColumns = { ...columns, [field]: e.target.value };
    setColumns(newColumns);
  };

  const execImport = () => {
    const rows = text
      .split("\n")
      .map((row) => row.split("\t").map((s) => s.trim()));
    const customers = rows.map((r) => {
      const data = {};
      Object.keys(columns).forEach((field) => {
        if (columns[field]) data[field] = r[columns[field] - 1];
      });
      return data;
    });
    customers.forEach((customer) => {
      dispatch(addCustomer(customer));
    });
    if (onClose) onClose();
  };

  return (
    <Card>
      <CardHeader
        title="Importera kunder"
        action={onClose && CloseButton(() => onClose())}
      />
      <CardContent style={{ display: "grid", gap: 8 }}>
        {/* Lägg till vad för värde per kolumn. Samt ignorera */}
        {/* En kund per rad. \n. \t mellan värden för kundfält. */}
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Innehåll</TableCell>
                <TableCell>Kolumn</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customerColumns.map((x) => (
                <TableRow key={"cc" + x.field}>
                  <TableCell>{x.title}</TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      variant="outlined"
                      size="small"
                      onChange={(e) => handleColumnChange(e, x.field)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TextField
          multiline
          fullWidth
          variant="outlined"
          type="text"
          placeholder="Klistra in direkt från Excel"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        <Button
          onClick={() => execImport()}
          variant="contained"
          color="primary"
        >
          Importera
        </Button>
      </CardContent>
    </Card>
  );
}
