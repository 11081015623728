import { Add } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

export default function AddButton(callback, options = {}) {
  return (
    <Tooltip title={options.title || "Skapa ny"}>
      <div>
        <IconButton onClick={() => callback()} {...options} size="large">
          <Add />
        </IconButton>
      </div>
    </Tooltip>
  );
}
