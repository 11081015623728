import { Paper, SxProps } from "@mui/material";
import { FC, useContext } from "react";
import { AssignmentContext } from "../AssignmentProvider";
import ImageWithMarks from "../ImageWithMarks";

interface Props {
  sx?: SxProps;
}

const FailuresMap: FC<Props> = (props) => {
  const { sx } = props;
  const { marks } = useContext(AssignmentContext);
  return (
    <>
      {marks[0] && (
        <Paper sx={{ maxWidth: 500, height: "fit-content", ...sx }}>
          <ImageWithMarks marks={marks} imageId={marks[0].image} />
        </Paper>
      )}
    </>
  );
};

export default FailuresMap;
