import { Assignment, Cached, Close } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import _ from "lodash";
import randomize from "randomatic";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ConfirmDialog,
  CopyButton,
  DeleteButton,
} from "../../Components/utils";
import { relativeDate } from "../../services/utils/dates";
import { getColorsWorkingWithWhiteOnTop } from "../../services/utils/userColors";
import {
  addUser,
  deleteUser,
  getUserRoles,
  getUsers,
  updateUser,
} from "../app/appApi";
import { StickyHeadTable } from "../data/Tables/Tables";
import SelectOrg from "../login/SelectOrg";
import { UserIcon } from "../user/User";
import { SelectUserGroup } from "./UserGroups";

export function AddUser({ org = null }) {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [userCreated, setUserCreated] = useState(null);
  const [error, setError] = useState("");
  let orgId = org || "";

  const submitDisabled = !username;

  async function submit() {
    const password = randomize("Aa0", 10);
    const payload = { username, password };
    if (email) payload.email = email;
    if (phone) payload.phone = phone;
    if (orgId) payload.org = orgId;
    if (name) payload.name = name;
    const result = await dispatch(addUser(payload));
    if (result) {
      if (!result?.error) {
        setUserCreated({ username, email, password });
        setUsername("");
        setName("");
        setEmail("");
        setPhone("");
        setError("");
        orgId = "";
      } else {
        setError(
          "Fel inträffade. Är användarnamnet eller epostadressen redan använd?"
        );
      }
    }
  }

  return (
    <>
      <Dialog open={!!userCreated} onClose={() => setUserCreated(null)}>
        <DialogTitle>Användare skapad</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Användarnamn: {userCreated?.username || ""}
          </Typography>
          <Typography variant="body1">
            Epost: {userCreated?.email || <i>ej angiven</i>}
          </Typography>
          <Typography variant="body1">
            Lösenord: {userCreated?.password}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<Assignment />}
            variant="contained"
            color="primary"
            onClick={() =>
              navigator.clipboard.writeText(
                `Användarnamn: ${userCreated?.username}\n${
                  userCreated?.email && `Epost: ${userCreated.email}\n`
                }Lösenord: ${userCreated?.password}`
              )
            }
          >
            Kopiera till urklipp
          </Button>
          <Button
            startIcon={<Close />}
            variant="contained"
            onClick={() => setUserCreated(null)}
          >
            Stäng
          </Button>
        </DialogActions>
      </Dialog>
      <Card>
        <CardHeader title="Lägg till ny användare" />
        <CardContent>
          <form
            onSubmit={(e) => e.preventDefault()}
            style={{ display: "grid", gap: 8 }}
          >
            <TextField
              variant="outlined"
              value={name}
              label="Namn"
              error={!!error}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              variant="outlined"
              value={username}
              type="username"
              label="Användarnamn"
              error={!!error}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              variant="outlined"
              value={email}
              type="email"
              label="Epostadress"
              error={!!error}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              value={phone}
              type="phone"
              label="Telefon"
              error={!!error}
              onChange={(e) => setPhone(e.target.value)}
            />
            {!org && (
              <SelectOrg onSelect={(org) => (orgId = org.id)} value={orgId} />
            )}
            {error && <Typography variant="body1">{error}</Typography>}
          </form>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => submit()}
            disabled={submitDisabled}
          >
            Skapa
          </Button>
        </CardActions>
      </Card>
    </>
  );
}

export function ListUsers({ users = [], orgCol = true, adminCol = false }) {
  const dispatch = useDispatch();
  const roles = useSelector((state) => state.app.roles);

  const orgs = useSelector((state) => state.admin.organisations);
  const myUserId = useSelector((state) => state.app.user.id);
  const [removing, setRemoving] = useState(null);
  const [isUpdatingPassword, setUpdatingPassword] = useState(null);

  const UserTableHead = () => (
    <>
      <TableCell></TableCell>
      <TableCell>Senast inloggad</TableCell>
      <TableCell>Användarnamn</TableCell>
      <TableCell>Användargrupp</TableCell>
      <TableCell>Epostadress</TableCell>
      <TableCell>Telefon</TableCell>
      {orgCol && <TableCell>Organisation</TableCell>}
      {adminCol && <TableCell>Admin</TableCell>}
      <TableCell>Osynlig</TableCell>
      <TableCell>Registrerad</TableCell>
      <TableCell>Lösenord</TableCell>
      <TableCell>Inaktivera</TableCell>
      <TableCell></TableCell>
    </>
  );
  useEffect(() => {
    dispatch(getUsers());
    if (roles.length == 0) dispatch(getUserRoles());
  }, []);

  const remove = () => {
    dispatch(deleteUser({ id: removing.id }));
    setRemoving(null);
  };

  const [settingColor, setColor] = useState(null);
  const colors = getColorsWorkingWithWhiteOnTop();
  const handleColorChange = async (e, id) => {
    const result = await dispatch(updateUser({ id, color: e.target.value }));
    if (result.meta.requestStatus === "fulfilled") setColor(null);
  };

  const UserRows = () =>
    users &&
    users.length > 0 &&
    _.orderBy(
      users.filter((x) => x.lastLogin),
      "lastLogin",
      "desc"
    )
      .concat(users.filter((x) => !x.lastLogin))
      .map((user) => (
        <TableRow key={user.id}>
          <TableCell>
            <UserIcon
              user={user}
              onClick={() =>
                setColor(settingColor === user.id ? null : user.id)
              }
              style={{ cursor: "pointer" }}
            />
            {settingColor === user.id && (
              <Select value="" onChange={(e) => handleColorChange(e, user.id)}>
                {colors.map((c) => (
                  <MenuItem
                    key={c}
                    style={{ backgroundColor: c, color: "#FFF" }}
                    value={c}
                  >
                    {c}
                  </MenuItem>
                ))}
              </Select>
            )}
          </TableCell>
          <TableCell>{relativeDate(user.lastLogin)}</TableCell>
          <TableCell>{user.username}</TableCell>
          <TableCell>
            {user.role
              ? roles.find((x) => x.id === user.role)?.name
              : SelectUserGroup({ user, roles, dispatch })}
          </TableCell>
          <TableCell>{user.email}</TableCell>
          <TableCell>{user?.phone || ""}</TableCell>
          {orgCol && (
            <TableCell>
              {!user?.org ? (
                <SelectOrg
                  onSelect={(org) =>
                    dispatch(updateUser({ id: user.id, org: org.id }))
                  }
                  value={""}
                  placeholder="Saknas"
                />
              ) : (
                <span>
                  {orgs.find((x) => x.id === user.org)?.orgShortName ||
                    user.org}
                </span>
              )}
            </TableCell>
          )}
          {adminCol && (
            <TableCell>
              <Checkbox
                checked={user.isAdmin}
                onChange={(e) =>
                  dispatch(
                    updateUser({ id: user.id, isAdmin: e.target.checked })
                  )
                }
              />
            </TableCell>
          )}
          <TableCell>
            <Checkbox
              checked={user?.invisible ?? false}
              onChange={(e) =>
                dispatch(
                  updateUser({ id: user.id, invisible: e.target.checked })
                )
              }
            />
          </TableCell>
          <TableCell>{user.created.substring(0, 10)}</TableCell>
          <TableCell>
            <Tooltip title="Generera nytt lösenord">
              <IconButton
                onClick={() => setUpdatingPassword(user)}
                size="large"
              >
                <Cached />
              </IconButton>
            </Tooltip>
          </TableCell>
          <TableCell>
            <Checkbox
              checked={!!user?.disabled}
              onChange={(e) =>
                dispatch(
                  updateUser({ id: user.id, disabled: e.target.checked })
                )
              }
            />
          </TableCell>
          <TableCell padding="none">
            {DeleteButton(() => setRemoving(user), {
              disabled: user.id === myUserId,
            })}
          </TableCell>
        </TableRow>
      ));

  const updatePassword = async () => {
    const password = randomize("Aa0", 8);
    const result = await dispatch(
      updateUser({ id: isUpdatingPassword.id, password })
    );
    if (result.meta.requestStatus === "fulfilled") {
      setUpdatingPassword(null);
      navigator.clipboard.writeText(password);
    }
  };

  function UpdatePasswordDialog() {
    return (
      <Dialog
        open={!!isUpdatingPassword}
        onClose={() => setUpdatingPassword(null)}
      >
        <DialogTitle>
          Generera nytt lösenord för {isUpdatingPassword?.username}
        </DialogTitle>
        <DialogContent>
          Lösenordet uppdateras först du klickar på kopieringsknappen.
        </DialogContent>
        <DialogActions>{CopyButton(() => updatePassword())}</DialogActions>
      </Dialog>
    );
  }

  return (
    <>
      <ConfirmDialog
        open={removing}
        setOpen={setRemoving}
        title="Radera användare?"
        text={`Användarnamn: '${removing?.username || ""}'`}
        onConfirm={() => remove()}
      />
      <UpdatePasswordDialog />
      <StickyHeadTable stickyHeaderRow={<UserTableHead />} maxHeight={400}>
        <UserRows />
      </StickyHeadTable>
    </>
  );
}
