import { IUser } from "@hamnvy/shared";
import { Button } from "@mui/material";
import { FC, useState } from "react";
import { Trans } from "react-i18next";
import ActionDialog from "../../../Components/utils/ActionDialog";
import { useAppDispatch } from "../../../hooks";
import { updateUser } from "../../app";

interface Props {
  user: IUser;
}

const DisableUserButton: FC<Props> = (props) => {
  const { user } = props;
  const dispatch = useAppDispatch();
  const [dialog, setDialog] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const handleAction = async () => {
    try {
      setError(false);
      // @ts-expect-error
      await dispatch(updateUser({ id: user.id, disabled: !user.disabled }));
      setDialog(false);
    } catch (err) {
      setError(true);
    }
  };

  return (
    <>
      <ActionDialog
        open={dialog}
        onClose={() => setDialog(false)}
        onConfirm={handleAction}
        error={
          error && (
            <Trans i18nKey="user.update_error">
              Kunde inte genomföra ändringen.
            </Trans>
          )
        }
        title={
          <>
            {user.disabled ? (
              <Trans i18nKey="user.enable_title">Aktivera kontot</Trans>
            ) : (
              <Trans i18nKey="user.disable_title">Inaktivera kontot</Trans>
            )}
            ?
          </>
        }
      />
      <Button
        color={user.disabled ? "success" : "warning"}
        variant="outlined"
        onClick={() => setDialog(true)}
      >
        {user.disabled ? (
          <Trans i18nKey="user.enable_button">Aktivera</Trans>
        ) : (
          <Trans i18nKey="user.disable_button">Inaktivera</Trans>
        )}
      </Button>
    </>
  );
};

export default DisableUserButton;
