import { Visibility } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

export default function EyeButton(callback, options) {
  return (
    <Tooltip title={options?.title || "Visa"}>
      <div>
        <IconButton onClick={() => callback()} {...{ ...options, title: null }} size="large">
          <Visibility />
        </IconButton>
      </div>
    </Tooltip>
  );
}
