import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { CloseButton } from "../../Components/utils";
import { boatColumns, customerColumns, spotColumns } from "../../services/crm";
import { convertStringValueToFieldType } from "../../services/utils/fields";
import { getBoats, getCustomers, getSpots, postCRMImport } from "../app";

const BAS_COLS = {
  boat: {
    name: "13",
    brand: "14",
    type: "15",
    model: "16",
    serial: "17",
    length: "18",
    width: "19",
    height: "",
    depth: "20",
    weight: "21",
    note: "22",
  },
  spot: {
    spotNumber: "2",
    length: "4",
    width: "3",
    note: "23",
  },
  customer: {
    name: "25", // 26 efternamn
    address: "6",
    postalCode: "8",
    city: "9",
    phone: "11",
    email: "12",
  },
};

export default function CRMImport({ onClose = null }) {
  const [cols, setCols] = useState({});
  const [basImport, setBasImport] = useState(false);
  const crmColumns = {
    customer: customerColumns,
    boat: boatColumns,
    spot: spotColumns,
  };

  const [text, setText] = useState("");
  const dispatch = useDispatch();

  const handleColumnChange = (e, type, field) => {
    const newColumns = {
      ...cols,
      [type]: { ...cols[type], [field]: e.target.value },
    };
    setCols(newColumns);
  };

  const handleBasImportToggle = () => {
    if (basImport) {
      setBasImport(false);
      setCols({});
    } else {
      setBasImport(true);
      setCols(BAS_COLS);
    }
  };

  const execImport = async () => {
    const rows = text
      .split("\n")
      .map((row) => row.split("\t").map((s) => s.trim()));
    const data = rows.map((r) => {
      const data = {};
      Object.keys(cols).forEach((type) => {
        if (cols[type]) {
          data[type] = {};
          Object.keys(cols[type]).forEach((field) => {
            if (cols[type][field]) {
              const strVal = r[cols[type][field] - 1];
              const fieldType = crmColumns[type].find(
                (x) => x.field === field
              ).type;
              data[type][field] = convertStringValueToFieldType(
                strVal,
                fieldType
              );
            }
          });
        }
      });
      return data;
    });
    const result = await dispatch(postCRMImport({ data }));
    if (result.meta.requestStatus === "fulfilled") {
      alert("Import lyckades.");
      dispatch(getCustomers());
      dispatch(getBoats());
      dispatch(getSpots());
    }
    if (onClose) onClose();
  };

  return (
    <Card>
      <CardHeader
        title="Importera data"
        action={onClose && CloseButton(() => onClose())}
      />
      <CardContent style={{ display: "grid", gap: 8 }}>
        <FormControlLabel
          control={
            <Switch value={basImport} onChange={handleBasImportToggle} />
          }
          label="Använd inställningar för BAS-export"
        />
        {!basImport && (
          <div style={{ display: "flex", gap: 8 }}>
            <div>
              <Typography variant="h6">Kunder</Typography>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Innehåll</TableCell>
                      <TableCell>Kolumn</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {customerColumns.map((x) => (
                      <TableRow key={x.field}>
                        <TableCell>{x.title}</TableCell>
                        <TableCell>
                          <TextField
                            type="number"
                            variant="outlined"
                            size="small"
                            onChange={(e) =>
                              handleColumnChange(e, "customer", x.field)
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div>
              <Typography variant="h6">Båtar</Typography>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Innehåll</TableCell>
                      <TableCell>Kolumn</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {boatColumns.map((x) => (
                      <TableRow key={x.field}>
                        <TableCell>{x.title}</TableCell>
                        <TableCell>
                          <TextField
                            type="number"
                            variant="outlined"
                            size="small"
                            onChange={(e) =>
                              handleColumnChange(e, "boat", x.field)
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div>
              <Typography variant="h6">Platser</Typography>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Innehåll</TableCell>
                      <TableCell>Kolumn</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {spotColumns.map((x) => (
                      <TableRow key={x.field}>
                        <TableCell>{x.title}</TableCell>
                        <TableCell>
                          <TextField
                            type="number"
                            variant="outlined"
                            size="small"
                            onChange={(e) =>
                              handleColumnChange(e, "spot", x.field)
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        )}
        <TextField
          multiline
          maxRows={10}
          fullWidth
          variant="outlined"
          type="text"
          placeholder="Klistra in direkt från Excel"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        <Button
          onClick={() => execImport()}
          variant="contained"
          color="primary"
        >
          Importera
        </Button>
      </CardContent>
    </Card>
  );
}
