import { Box, Paper, Typography, useTheme } from "@mui/material";
import { FC, ReactNode, useContext } from "react";
import { AssignmentContext } from "./AssignmentProvider";
import AssignmentSpotSummary from "./AssignmentSpotSummary";
import Assignees from "./fields/Assignees";
import Labels from "./fields/Labels";
import Priority from "./fields/Priority";

const AssignmentProperties = () => {
  const { assignment, isEditing } = useContext(AssignmentContext);

  const marks = assignment?.marks ?? [];
  return (
    <Box
      sx={{
        maxWidth: "300px",
      }}
    >
      {assignment && (
        <Box sx={{ display: "grid", gap: 1 }}>
          {/*<Content>
            <Typography>Prioritet</Typography>
            <Box>
              <Priority editable={isEditing} />
            </Box>
          </Content>*/}
          {/* <Content>
            <Typography>Deadline</Typography>
            <Box sx={{ display: "flex", gap: 1 }}>
              <DatePicker
                // label="Basic example"
                value={assignment?.deadline}
                disablePast
                onChange={(deadline) => {
                  if (deadline) updateAssignment({ deadline });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      height: "24px",
                      width: "110px",
                      "& input": {
                        color: `${grey[700]} !important`,
                        "-webkit-text-fill-color": `${grey[900]} !important`,
                      },
                      "& *": {
                        p: "0 !important",
                        m: "0 !important",
                        border: "none",
                      },
                    }}
                    disabled
                  />
                )}
              />
            </Box>
          </Content> */}
          <AssignmentSpotSummary spot={marks[0]?.spot} editable={isEditing} />
          <Content>
            <Typography>Kategori</Typography>
            <MultipleChipsContainer items={<Labels editable={isEditing} />} />
          </Content>
          <Content>
            <Typography>Ansvarig</Typography>
            <MultipleChipsContainer
              items={<Assignees editable={isEditing} />}
            />
          </Content>
        </Box>
      )}
    </Box>
  );
};

const MultipleChipsContainer: FC<{ items: ReactNode }> = (props) => (
  <Box
    sx={{
      display: "flex",
      flexWrap: "wrap",
      gap: 0.5,
      justifyContent: "flex-end",
    }}
  >
    {props.items}
  </Box>
);

const Content: FC<{ children: ReactNode }> = ({ children }) => {
  const theme = useTheme();
  return (
    <Paper
      sx={{
        p: 1,
        borderRadius: 1,
        backgroundColor: theme.palette.background.default,
        display: "flex",
        gap: 1,
        justifyContent: "space-between",
      }}
    >
      {children}
    </Paper>
  );
};

export default AssignmentProperties;
