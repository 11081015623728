import { Close, Help, MoreVert } from "@mui/icons-material";
import {
  Button,
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { justDateString, todaysDateString } from "../../services/utils/dates";
import { useStyles } from "../../styles/styles";
import { getGuestSpots, getOccupancies } from "../app";
import Header from "../main/Header";
import ViewImage from "../map/ViewImage";
import DatePickerWithArrows from "./DatePickerWithArrows";

export default function PaymentCheck() {
  const images = useSelector((state) => state.app.images);
  const payImages = images.filter((x) => x?.paycheck);
  const [date, setDate] = useState(new Date(todaysDateString()));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGuestSpots());
    dispatch(getOccupancies(justDateString(new Date().toISOString())));
  }, []);

  useEffect(() => {
    if (payImages.length > 0) setActiveView(payImages[0].id);
  }, [payImages[0]]);

  const [activeView, setActiveView] = useState("");

  const handleDate = (date) => {
    const res = justDateString(date.toISOString());
    setDate(date);
    dispatch(getOccupancies(res));
    dispatch(getGuestSpots(res));
  };

  function Stats() {
    const occupancies = useSelector((s) => s.app.occupancies);
    const spots = useSelector((s) => s.app.guestSpots);
    const numSpots =
      spots.limited.length + spots.temporary.length + spots.permanent.length;
    const numOccupied = occupancies.length;
    const numAvailable = numSpots - numOccupied;
    const belgrad = ((numOccupied / numSpots) * 100 || 0).toFixed(1);
    const numPaying = occupancies.filter((x) => x?.paid).length;
    const payrate = ((numPaying / numOccupied) * 100 || 0).toFixed(0);
    return (
      <div>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Upptagna</TableCell>
                <TableCell align="right">{numOccupied}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Lediga</TableCell>
                <TableCell align="right">{numAvailable}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Betalt</TableCell>
                <TableCell
                  align="right"
                  style={{
                    color:
                      payrate == "100"
                        ? "green"
                        : numOccupied != 0
                        ? "red"
                        : "unset",
                  }}
                >
                  {payrate}%
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {/* <Typography variant="body1">Beläggningsgrad {belgrad}%</Typography> */}
      </div>
    );
  }

  function InlineCircle({ radius, color, ...props }) {
    return (
      <svg height={radius * 2} width={radius * 2}>
        <circle cx={radius} cy={radius} r={radius} fill={color} {...props} />
      </svg>
    );
  }

  const styles = useStyles();
  const [help, setHelp] = useState(false);

  return (
    <>
      <Header />
      <Container className={styles.onetwo} style={{ marginTop: 16 }}>
        <div
          className={styles.followScroll}
          style={{ display: "grid", gap: 8 }}
        >
          <Typography variant="h4">
            Betalningskontroll
            <IconButton
              size="small"
              aria-label="Hjälp"
              onClick={() => setHelp(!help)}
            >
              <Help />
            </IconButton>
          </Typography>
          {help && (
            <div>
              <Typography variant="h6">
                Hjälp{" "}
                <IconButton
                  aria-label="Stäng"
                  onClick={() => setHelp(false)}
                  size="small"
                >
                  <Close />
                </IconButton>
              </Typography>
              <Typography variant="body1">
                Välj ett område för att se dess beläggning vid givet datum och
                klicka på en plats för att växla mellan tillgänglig{" "}
                <InlineCircle radius={5} color="green" />, upptagen{" "}
                <InlineCircle radius={5} color="orange" /> och betald{" "}
                <InlineCircle radius={5} color="red" />.
              </Typography>
            </div>
          )}

          <div style={{ display: "flex", gap: 8, flexWrap: "wrap" }}>
            {payImages.length > 1 &&
              payImages.map((img) => (
                <Button
                  key={"pc" + img.id}
                  variant={activeView == img.id ? "contained" : "outlined"}
                  color="primary"
                  onClick={() => setActiveView(img.id)}
                >
                  {img.title}
                </Button>
              ))}
            {payImages.length === 0 && (
              <Typography variant="h4">
                Ingen vy är markerad som betalningsvy. Kom igång genom att i
                Översikt klicka på vyer, välj önskad vy och i menyn <MoreVert />{" "}
                &quot;visa i betalningskontroll&quot;.
              </Typography>
            )}
          </div>
          <DatePickerWithArrows
            date={date}
            onDateChange={(date) => handleDate(date)}
            maxDate={new Date()}
            size="small"
          />
          <Stats />
        </div>
        {activeView && <ViewImage date={date} imageId={activeView} />}
      </Container>
    </>
  );
}
