import { MenuItem, TextField } from "@mui/material";

type Props = {
  value: string;
  onChange: (value: string) => void;
  isSuperUser?: boolean | undefined;
};

const TypeOfNotificationField = (props: Props) => {
  const { value, onChange, isSuperUser = false } = props;
  // todo type check for message variant
  return (
    <TextField
      size="small"
      variant="outlined"
      select
      style={{ width: 200 }}
      label="Typ av meddelande"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    >
      {isSuperUser && [
        <MenuItem key="sys" value="sys_msg">
          System
        </MenuItem>,
        <MenuItem key="biz" value="biz_msg">
          Hamnvy AB
        </MenuItem>,
      ]}
      <MenuItem value="org_msg">Organisation</MenuItem>
      <MenuItem value="grp_msg">Grupp</MenuItem>
      <MenuItem value="usr_msg">Användare</MenuItem>
    </TextField>
  );
};

export default TypeOfNotificationField;
