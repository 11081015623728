import { Assignment, AssignmentStatusCode } from "@hamnvy/shared";
import { ChipProps, Popper } from "@mui/material";
import { blue, green, orange, red } from "@mui/material/colors";
import { Box } from "@mui/system";
import { FC, useContext, useState } from "react";
import PopperContent from "../../../Components/PopperContent";
import SlimChip from "../../../Components/utils/SlimChip";
import { AssignmentContext } from "../AssignmentProvider";

interface Props {
  assignment: Assignment;
  editable?: boolean;
}

interface Status {
  code: AssignmentStatusCode;
  label: string;
  color: string;
}

export const assignmentStatuses: Status[] = [
  { code: "todo", label: "Ej påbörjad", color: blue[800] },
  { code: "in_progress", label: "Pågående", color: green[600] },
  { code: "waiting", label: "Väntande", color: orange[800] },
  { code: "done", label: "Avslutad", color: red[900] },
];

const AssignmentStatusChip: FC<Props & ChipProps> = (props) => {
  const { updateAssignment } = useContext(AssignmentContext);
  const { assignment, editable } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event?: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event ? (anchorEl ? null : event.currentTarget) : null);
  };

  const editableProps = editable
    ? {
        editable: true,
        onClick: handleClick,
      }
    : {};

  const status = assignment
    ? assignmentStatuses.find((x) => x.code === assignment.status.code)
    : null;

  const open = Boolean(anchorEl);
  const id = open ? "status-popper" : undefined;

  return (
    <>
      {status && (
        <StatusChip status={status} sx={props.sx} {...editableProps} />
      )}
      {editable && (
        <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-end">
          <PopperContent
            heading="Ändra status"
            handleClick={() => handleClick()}
          >
            <Box sx={{ display: "flex", gap: 1 }}>
              {assignmentStatuses.map((s) => (
                <StatusChip
                  key={s.code}
                  status={s}
                  onClick={() => updateAssignment({ status: { code: s.code } })}
                />
              ))}
            </Box>
          </PopperContent>
        </Popper>
      )}
    </>
  );
};

export const StatusChip: FC<{ status: Status } & ChipProps> = (props) => {
  const { status, sx, ...editableProps } = props;
  return (
    <SlimChip
      label={status.label}
      color={status.color}
      sx={{
        ...sx,
      }}
      {...editableProps}
    />
  );
};

export default AssignmentStatusChip;
