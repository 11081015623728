import { TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { relativeDate } from "../../services/utils/dates";
import { getLoginHistory } from "../app";
import { StickyHeadTable } from "../data/Tables/Tables";

export function LoginHistory() {
  return <UserLogins />;
}

function UserLogins() {
  const dispatch = useDispatch();
  const loginHistory = useSelector((state) => state.app.loginHistory);
  const users = useSelector((state) => state.app.users);
  useEffect(() => {
    dispatch(getLoginHistory());
  }, []);

  const TableHead = () => (
    <>
      <TableCell>Användare</TableCell>
      <TableCell>
        <Tooltip title="Utan inloggningsdialog, sparat lösenord">
          <span>Uppdateringsnyckel</span>
        </Tooltip>
      </TableCell>
      <TableCell align="right">Tidstämpel</TableCell>
    </>
  );

  const TableRows = () =>
    loginHistory.map((log) => {
      const user = users.find((u) => u.id === log.user);
      return (
        user && (
          <TableRow key={log.id}>
            <TableCell>
              <Tooltip title={user.email || ""}>
                <span>{user.username}</span>
              </Tooltip>
            </TableCell>
            <TableCell>{log.withRefreshToken ? "Ja" : ""}</TableCell>
            <TableCell align="right">{relativeDate(log.timestamp)}</TableCell>
          </TableRow>
        )
      );
    });

  return (
    <StickyHeadTable
      maxHeight={400}
      header={
        <TableRow>
          <TableCell colSpan={3}>
            <Typography variant="h5">Inloggningshistorik</Typography>
          </TableCell>
        </TableRow>
      }
      stickyHeaderRow={<TableHead />}
      size="small"
    >
      <TableRows />
    </StickyHeadTable>
  );
}
