import Bar from "./bar";

const r = (): number => Math.random() * 100;

const bars: Bar[] = [
  { value: 10, onClick: () => alert("Paret"), label: "Klicka mig." },
  { value: 30 },
  { value: 20 },
  { value: r() },
  { value: r() },
  { value: r() },
  { value: r() },
  { value: r() },
  { value: r() },
  { value: r() },
  { value: r() },
  {
    value: r(),
    color: "#CCC",
    textColor: "#000",
    label: "Solveig klickade mig",
  },
];

const sep: Bar[] = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
].map((x) => ({ label: "2022-09-" + x, value: r() }));

export default sep;
