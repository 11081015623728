import { formatRelative, subDays } from "date-fns";
import { sv } from "date-fns/locale";

export function relativeDate(inputDate, baseDate = new Date()) {
  return inputDate
    ? formatRelative(subDays(new Date(inputDate), 0), baseDate, { locale: sv })
    : "Aldrig";
}

export function justDateString(dateString) {
  return dateString.slice(0, 10);
}

export function todaysShortDateString() {
  return new Date().toISOString().slice(0, 10);
}

export function todaysDateString() {
  return new Date(todaysShortDateString()).toISOString();
}

export function getLocalTime(utcDateStr) {
  return new Date(utcDateStr).toLocaleTimeString().slice(0, 5);
}
