const stripNumberFromSpaceAndDash = (str) => {
  let res = str ? str.replaceAll(/[- ]/g, "") : "";
  if (res.startsWith("0")) res = res.replace("0", "+46");
  else if (!res.startsWith("+")) res = `+${res}`;
  return res;
};

const basColumns = [
  { type: "string", field: "spot", title: "Plats", inputPlaceholder: "IP 232" },
  {
    type: "string",
    field: "area",
    title: "Område",
    inputPlaceholder: "Brygga B",
  },
  { type: "number", field: "width", title: "Bredd", inputPlaceholder: "2 m" },
  { type: "number", field: "length", title: "Längd", inputPlaceholder: "4 m" },
  {
    type: "string",
    field: "customerId",
    title: "Kundnr",
    inputPlaceholder: "123 456",
  },
  {
    type: "string",
    field: "address",
    title: "Adress",
    inputPlaceholder: "Ändtarmsvägen 31",
  },
  {
    type: "string",
    field: "postalCode",
    title: "Postnr",
    inputPlaceholder: "475 50",
  },
  { type: "string", field: "city", title: "Ort", inputPlaceholder: "Hälsö" },
  { type: "string", field: "country", title: "Land", inputPlaceholder: "SE" },
  {
    type: "string",
    field: "phone",
    title: "Mobil",
    inputPlaceholder: "0700272272",
  },
  {
    type: "string",
    field: "email",
    title: "Epost",
    inputPlaceholder: "philip@crona.eu",
  },
  {
    type: "string",
    field: "boatName",
    title: "Båtnamn",
    inputPlaceholder: "Cronjuvelen",
  },
  {
    type: "string",
    field: "boatType",
    title: "Båttyp",
    inputPlaceholder: "Segelbåt",
  },
  {
    type: "string",
    field: "boatModel",
    title: "Båtmodell",
    inputPlaceholder: "First 31",
  },
  {
    type: "string",
    field: "boatSerial",
    title: "Serienummer",
    inputPlaceholder: "XXXX-YYYY",
  },
  {
    type: "number",
    field: "boatLength",
    title: "Båtlängd",
    inputPlaceholder: "4 m",
  },
  {
    type: "number",
    field: "boatWidth",
    title: "Båtbredd",
    inputPlaceholder: "2 m",
  },
  {
    type: "number",
    field: "boatDepth",
    title: "Båtdjup",
    inputPlaceholder: "1 m",
  },
  {
    type: "number",
    field: "boatDeplacement",
    title: "Båtdeplacement",
    inputPlaceholder: "4 ton",
  },
  {
    type: "string",
    field: "spotComment",
    title: "Platskommentar",
    inputPlaceholder: "Begränsat djup",
  },
  {
    type: "string",
    field: "firstname",
    title: "Förnamn",
    inputPlaceholder: "Philip",
  },
  {
    type: "string",
    field: "lastname",
    title: "Efternamn",
    inputPlaceholder: "Crona",
  },
  {
    type: "string",
    field: "name",
    title: "Namn",
    inputPlaceholder: "Philip Crona",
  },
];

const basColPrintFunction = {
  phone: stripNumberFromSpaceAndDash,
  phoneHome: stripNumberFromSpaceAndDash,
};

/** Get Columns from fieldnames */
const getColumns = (...fields) =>
  fields.map((field) => basColumns.find((c) => c.field === field));

const defaultColumns = getColumns("area", "spot", "boatModel", "name");

export {
  basColPrintFunction,
  basColumns,
  defaultColumns,
  getColumns,
  stripNumberFromSpaceAndDash,
};
