import { Add, Upload } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { FC, useState } from "react";

import { CreateCustomer, ManageCustomers } from "../customer/manageCustomers";
import PasteAndImportCustomers from "../customer/PasteAndImportCustomers";
import Header from "../main/Header";
import PageHeader from "../main/PageHeader";

const CustomersPage: FC = () => {
  const [isCreatingCustomer, setCreatingCustomer] = useState(false);
  const [isImporting, setImporting] = useState(false);

  return (
    <>
      <Header>
        <PageHeader title="Kunder">
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              color="inherit"
              variant="outlined"
              startIcon={<Add />}
              sx={{
                backgroundColor: "secondary.main",
                textTransform: "none",
                "& .MuiButton-startIcon": { mr: 0.5 },
              }}
              onClick={() => setCreatingCustomer(true)}
            >
              Ny kund
            </Button>
            <Button
              color="inherit"
              startIcon={<Upload />}
              sx={{
                textTransform: "none",
                "& .MuiButton-startIcon": { mr: 0.5 },
              }}
              onClick={() => setImporting(true)}
            >
              Importera
            </Button>
          </Box>
        </PageHeader>
      </Header>
      <Box sx={{ px: 3 }}>
        {isImporting && (
          <PasteAndImportCustomers onClose={() => setImporting(false)} />
        )}
        <ManageCustomers />
      </Box>
      <CreateCustomer
        open={isCreatingCustomer}
        onClose={() => setCreatingCustomer(false)}
      />
    </>
  );
};

export default CustomersPage;
