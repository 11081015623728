import { Button, TextField } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { addEndpoint } from "../app";

export function CreateEndpoint() {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [html, setHtml] = useState("");

  const handleSubmit = async () => {
    const result = await dispatch(addEndpoint({ name, html }));
    if (result) {
      setName("");
      setHtml("");
    }
  };

  return (
    <div>
      <form onSubmit={(e) => e.preventDefault()}>
        <TextField
          value={name}
          onChange={(e) => setName(e.target.value)}
          label="Ändpunktens namn"
          variant="outlined"
        />
        <TextField
          value={html}
          onChange={(e) => setHtml(e.target.value)}
          label="HTML-kod"
          variant="outlined"
          multiline
        />
        <Button
          disabled={!name || !html}
          type="submit"
          variant="outlined"
          color="primary"
          onClick={() => handleSubmit()}
        >
          Publicera
        </Button>
      </form>
    </div>
  );
}
