import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateUser } from "../app/appApi";

export function UpdatePassword() {
  const dispatch = useDispatch();
  // const [current, setCurrent] = useState('');
  const [newPassword, setNewPassword] = useState("");
  const [newConfirm, setNewConfirm] = useState("");

  const submitDisabled = !newPassword || newPassword !== newConfirm;

  const updatePassword = async () => {
    const result = await dispatch(updateUser({ password: newPassword }));

    //on success do this
    // setNewPassword('');
    // setNewConfirm('');
  };

  return (
    <Card>
      <CardHeader title="Byt lösenord" />
      <CardContent>
        {/* <TextField value={current} type="password" label="Nuvarande lösenord" onChange={(e) => setCurrent(e.target.value)} /> */}
        <form
          onSubmit={(e) => e.preventDefault}
          style={{ display: "grid", gap: 8 }}
        >
          <TextField
            autoComplete="new-password"
            variant="outlined"
            value={newPassword}
            type="password"
            label="Nytt lösenord"
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            autoComplete="new-password"
            variant="outlined"
            value={newConfirm}
            type="password"
            label="Upprepa lösenord"
            onChange={(e) => setNewConfirm(e.target.value)}
          />
        </form>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => updatePassword()}
          disabled={submitDisabled}
        >
          Spara
        </Button>
      </CardActions>
    </Card>
  );
}
