import { useSelector } from "react-redux";
import { getBoatOwners, getNonBoatOwners } from "../app/appSelectors";

const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} = require("@mui/material");

function Summary() {
  const spots = useSelector((state) => state.app.spots);
  const customers = useSelector((state) => state.app.customers);
  const boats = useSelector((state) => state.app.boats);
  const boatOwners = useSelector((state) => getBoatOwners(state));
  const nonBoatOwners = useSelector((state) => getNonBoatOwners(state));

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>Organisationsinformation</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Antal</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Platser</TableCell>
            <TableCell>{spots.length}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Kunder</TableCell>
            <TableCell>{customers.length}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Båtar</TableCell>
            <TableCell>{boats.length}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Båtägare</TableCell>
            <TableCell>{boatOwners.length}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Kunder utan båt</TableCell>
            <TableCell>{nonBoatOwners.length}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default Summary;
