import { createAsyncThunk } from "@reduxjs/toolkit";
import { format } from "date-fns";
import { api } from "../../services/api";

export const getImages = createAsyncThunk(
  "images/get",
  async (payload, { getState }) => {
    const response = await api.get(`/images?org=${getState().app.org.id}`);
    return response.data;
  }
);

export const deleteImage = createAsyncThunk("image/delete", async (payload) => {
  const response = await api.delete(`/images/${payload.id}`);
  return response.data;
});

export const updateImage = createAsyncThunk("image/update", async (payload) => {
  const response = await api.patch(`/images/${payload.id}`, payload.data);
  return response.data;
});

export const uploadImage = createAsyncThunk("image/upload", async (payload) => {
  const response = await api.post("/images", payload.data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
});

export const getMarks = createAsyncThunk(
  "marks/get",
  async (payload, { getState }) => {
    const id = payload ? payload.image : getState().app.image.id;

    const response = await api.get(`/images/${id}/marks`);
    return response.data;
  }
);

export const getAllMarks = createAsyncThunk(
  "get/marks-all",
  async () => {
    const response = await api.get(`/images/marks/all`);
    return response.data;
  }
);

export const getBasUploads = createAsyncThunk("bas/uploads/get", async () => {
  const response = await api.get("/bas/uploads");
  return response.data;
});

export const deleteBasUpload = createAsyncThunk(
  "bas/upload/delete",
  async (payload) => {
    const response = await api.delete(`/bas/uploads/${payload.id}`);
    return response.data;
  }
);

export const getBasImport = createAsyncThunk(
  "bas/import/get",
  async (payload) => {
    const response = await api.get(`/bas/uploads/${payload.id}/data`);
    return response.data;
  }
);

export const getLatestBasImport = createAsyncThunk(
  "bas/latest_import/get",
  async () => {
    const response = await api.get(`/bas/uploads/latest/data`);
    return response.data;
  }
);

export const updateBasUpload = createAsyncThunk(
  "bas/import/update",
  async (payload) => {
    const response = await api.patch(
      `/bas/uploads/${payload.id}`,
      payload.data
    );
    return response.data;
  }
);

export const basFileUpload = createAsyncThunk("bas/upload", async (payload) => {
  const response = await api.post(`/bas/uploads`, payload.data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
});

export const patchMark = createAsyncThunk(
  "mark/patch",
  async ({ id, data }) => {
    const response = await api.patch(`/marks`, { marks: [{ id, ...data }] });
    return response.data;
  }
);

export const patchMarks = createAsyncThunk("marks/patch", async ({ marks }) => {
  const response = await api.patch(`/marks`, { marks });
  return response.data;
});

export const addMark = createAsyncThunk(
  "mark/add",
  async ({ imageId, mark }) => {
    const response = await api.post(`/images/${imageId}/marks`, mark);
    return response.data;
  }
);

export const deleteMarks = createAsyncThunk("marks/delete", async ({ ids }) => {
  const response = await api.delete(`/marks?ids=${ids.join(",")}`);
  return response.data;
});

export const getMarkFields = createAsyncThunk("markFields/get", async () => {
  const response = await api.get(`/marks/fields`);
  return response.data;
});

export const addMarkField = createAsyncThunk(
  "markFields/add",
  async (payload) => {
    const response = await api.post(`/marks/fields`, {
      fields: [payload.field],
    });
    return response.data;
  }
);

export const updateMarkField = createAsyncThunk(
  "markFields/update",
  async ({ id, data }) => {
    const response = await api.patch(`/marks/fields/${id}`, data);
    return response.data;
  }
);

export const deleteMarkField = createAsyncThunk(
  "markField/delete",
  async (id) => {
    const response = await api.delete(`/marks/fields/${id}`);
    return response.data;
  }
);

export const addOrganisationSettings = createAsyncThunk(
  "add/settings/organisation",
  async (payload, { getState }) => {
    const { type, settingsGroup, settings } = payload;
    const settingFields = settings.map((x) => x.field);
    const existing = getState().app.orgSettings.find((x) => x?.type === type);
    const existingSettings = existing?.settings.find(
      (x) => x.field === settingsGroup
    );
    // console.log({existingSettings})
    let value = settings;
    if (existingSettings)
      value = [
        ...existingSettings.value.filter(
          (x) => !settingFields.includes(x.field)
        ),
        ...settings,
      ];
    const response = await api.post(`/settings/organisation`, {
      type,
      settings: [{ field: settingsGroup, value }],
    });
    return response.data;
  }
);

export const removeOrganisationSettings = createAsyncThunk(
  "remove/settings/organisation",
  async (payload) => {
    const response = await api.post(`/settings/organisation/delete`, payload);
    return response.data;
  }
);

export const setOrganisationSetting = createAsyncThunk(
  "set/settings/organisation",
  async (payload) => {
    const response = await api.post(`/settings/organisation`, payload);
    return response.data;
  }
);

export const getOrganisationSettings = createAsyncThunk(
  "get/settings/organisation",
  async () => {
    const response = await api.get(`/settings/organisation`);
    return response.data;
  }
);

export const getLoginHistory = createAsyncThunk(
  "get/loginHistory",
  async () => {
    const response = await api.get(`/login_history`);
    return response.data;
  }
);

export const getEndpoints = createAsyncThunk("get/endpoints", async () => {
  const response = await api.get(`/endpoints`);
  return response.data;
});

export const addEndpoint = createAsyncThunk("add/endpoint", async (payload) => {
  const response = await api.post(`/endpoints`, payload);
  return response.data;
});

export const deleteEndpoint = createAsyncThunk(
  "delete/endpoint",
  async (payload, { getState }) => {
    const endpoint = getState().app.endpoints[payload.index];
    const response = await api.delete(`/endpoints/${endpoint.id}`);
    return response.data;
  }
);

export const updateEndpoint = createAsyncThunk(
  "update/endpoint",
  async (payload, { getState }) => {
    const endpoint = getState().app.endpoints[payload.index];
    const response = await api.patch(`/endpoints/${endpoint.id}`, payload.data);
    return response.data;
  }
);

// user
export const authenticate = createAsyncThunk(
  "authenticateUser",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.post("/auth", payload, {
        withCredentials: true,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  "updateUser",
  async (payload, { getState }) => {
    const response = await api.patch(
      `/users/${payload?.id || getState().app.user.id}`,
      payload
    );
    return response.data;
  }
);

export const addUser = createAsyncThunk("addUser", async (payload) => {
  const response = await api.post(`/users`, payload);
  return response.data;
});

export const getUsers = createAsyncThunk("getUsers", async () => {
  const response = await api.get(`/users`);
  return response.data;
});

export const deleteUser = createAsyncThunk("deleteUser", async (payload) => {
  const response = await api.delete(`/users/${payload.id}`);
  return response.data;
});

export const addUserSettings = createAsyncThunk(
  "add/settings/user",
  async (payload, { getState }) => {
    const { type, settingsGroup, settings } = payload;
    const settingFields = settings.map((x) => x.field);
    const existing = getState().app.settings.find((x) => x?.type === type);
    const existingSettings = existing?.settings.find(
      (x) => x.field === settingsGroup
    );
    // console.log({existingSettings})
    let value = settings;
    if (existingSettings)
      value = [
        ...existingSettings.value.filter(
          (x) => !settingFields.includes(x.field)
        ),
        ...settings,
      ];
    const response = await api.post(`/settings/user`, {
      type,
      settings: [{ field: settingsGroup, value }],
    });
    return response.data;
  }
);

export const getUserRoles = createAsyncThunk("get/user/roles", async () => {
  const response = await api.get(`/users/roles`);
  return response.data;
});

export const addUserRole = createAsyncThunk(
  "add/user/role",
  async (payload) => {
    const response = await api.post(`/users/roles`, payload);
    return response.data;
  }
);

export const patchUserRole = createAsyncThunk(
  "patch/user/role",
  async ({ id, ...payload }) => {
    const response = await api.patch(`/users/roles/${id}`, payload);
    return response.data;
  }
);

export const deleteUserRole = createAsyncThunk(
  "delete/user/role",
  async ({ index }, { getState }) => {
    const role = getState().app.roles[index];
    const response = await api.delete(`/users/roles/${role.id}`);
    return response.data;
  }
);

export const saveFilters = createAsyncThunk(
  "add/user/filterset",
  async (payload, { getState }) => {
    const userId = getState().app.user.id;
    const response = await api.post(`/users/${userId}/filters`, payload);
    return response.data;
  }
);

export const getFilterSets = createAsyncThunk(
  "get/user/filtersets",
  async (_, { getState }) => {
    const userId = getState().app.user.id;
    const response = await api.get(`/users/${userId}/filters`);
    return response.data;
  }
);

export const getCustomers = createAsyncThunk("getCustomers", async () => {
  const response = await api.get(`/customers`);
  return response.data;
});

export const addCustomer = createAsyncThunk(
  "addCustomer",
  async (payload, { getState }) => {
    const org = getState().app.org?.id;
    const response = await api.post(`/customers`, { ...payload, org });
    return response.data;
  }
);

export const deleteCustomer = createAsyncThunk("deleteCustomer", async (id) => {
  const response = await api.delete(`/customers/${id}`);
  return response.data;
});

export const updateCustomer = createAsyncThunk(
  "updateCustomer",
  async ({ id, ...data }) => {
    const response = await api.patch(`/customers/${id}`, data);
    return response.data;
  }
);

export const getBoats = createAsyncThunk("getBoats", async () => {
  const response = await api.get(`/boats`);
  return response.data;
});

export const addBoats = createAsyncThunk("addBoat", async (payload) => {
  const response = await api.post(`/boats`, payload);
  return response.data;
});

export const updateBoat = createAsyncThunk(
  "updateBoat",
  async ({ id, ...data }) => {
    const response = await api.patch(`/boats/${id}`, data);
    return response.data;
  }
);

export const deleteBoat = createAsyncThunk("deleteBoat", async (id) => {
  const response = await api.delete(`/boats/${id}`);
  return response.data;
});

export const getSpots = createAsyncThunk("getSpots", async () => {
  const response = await api.get(`/spots`);
  return response.data;
});

export const addSpots = createAsyncThunk("addSpots", async (spots) => {
  const response = await api.post(`/spots`, spots);
  return response.data;
});

export const updateSpot = createAsyncThunk(
  "updateSpot",
  async ({ id, ...data }) => {
    const response = await api.patch(`/spots/${id}`, data);
    return response.data;
  }
);

export const migrateSpotsFromBAS = createAsyncThunk(
  "migrateSpotsFromBAS",
  async (_, { getState }) => {
    const response = await api.post(`/spots/migrateFromBAS`, {
      org: getState().app.org.id,
    });
    return response.data;
  }
);

export const deleteSpot = createAsyncThunk("deleteSpot", async (id) => {
  const response = await api.delete(`/spots/${id}`);
  return response.data;
});

export const deleteAllSpots = createAsyncThunk("deleteAllSpots", async () => {
  const response = await api.delete(`/spots`);
  return response.data;
});

export const migrateBoatsFromBAS = createAsyncThunk(
  "migrateBoatsFromBAS",
  async (_, { getState }) => {
    const response = await api.post(`/boats/migrateFromBAS`, {
      org: getState().app.org.id,
    });
    return response.data;
  }
);

export const getNotes = createAsyncThunk("getNotes", async () => {
  const response = await api.get(`/notes`);
  return response.data;
});

export const postNote = createAsyncThunk("postNote", async (payload) => {
  const response = await api.post(`/notes`, payload);
  return response.data;
});

export const postMetric = createAsyncThunk("postMetric", async (payload) => {
  const response = await api.post(`/app_metrics`, payload);
  return response.data;
});

export const postCRMImport = createAsyncThunk(
  "postCRMImport",
  async (payload) => {
    const response = await api.post(`/crm/import`, payload);
    return response.data;
  }
);

export const getGuestSpots = createAsyncThunk("getGuestSpots", async (date) => {
  const response = await api.get(`/spots/guest${date ? "?date=" + date : ""}`);
  return response.data;
});

export const postLimitedGuestSpot = createAsyncThunk(
  "postLimitedGuestSpot",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.post(`/spots/guest/limited`, payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteLimitedGuestSpot = createAsyncThunk(
  "deleteLimitedGuestSpot",
  async (id) => {
    const response = await api.delete(`/spots/guest/limited/${id}`);
    return response.data;
  }
);

export const patchLimitedGuestSpot = createAsyncThunk(
  "patchLimitedGuestSpot",
  async ({ id, ...data }, { rejectWithValue }) => {
    try {
      const response = await api.patch(`/spots/guest/limited/${id}`, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const postTemporaryGuestSpot = createAsyncThunk(
  "postTemporaryGuestSpot",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.post(`/spots/guest/temporary`, payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteTemporaryGuestSpot = createAsyncThunk(
  "deleteTemporaryGuestSpot",
  async ({ id, ...data }) => {
    const response = await api.delete(
      `/spots/guest/temporary/${id}${data.date ? "?date=" + data.date : ""}`
    );
    return response.data;
  }
);

export const postPermanentGuestSpots = createAsyncThunk(
  "postPermanentGuestSpot",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.post(`/spots/guest/permanent`, payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deletePermanentGuestSpot = createAsyncThunk(
  "deletePermanentGuestSpot",
  async ({ id, ...data }) => {
    const response = await api.delete(
      `/spots/guest/permanent/${id}${data.date ? "?date=" + data.date : ""}`
    );
    return response.data;
  }
);

export const getOccupancies = createAsyncThunk(
  "getOccupancies",
  async (date) => {
    const response = await api.get(`/spots/guest/occupancy/${date}`);
    return response.data;
  }
);

export const postOccupancy = createAsyncThunk(
  "postOccupancy",
  async (payload) => {
    const response = await api.post(`/spots/guest/occupancy`, payload);
    return response.data;
  }
);

export const patchOccupancy = createAsyncThunk(
  "patchOccupancy",
  async ({ id, ...data }) => {
    const response = await api.patch(`/spots/guest/occupancy/${id}`, data);
    return response.data;
  }
);

export const deleteOccupancy = createAsyncThunk(
  "deleteOccupancy",
  async (id) => {
    const response = await api.delete(`/spots/guest/occupancy/${id}`);
    return response.data;
  }
);

export const getTodos = createAsyncThunk("getTodos", async () => {
  const response = await api.get(`/todos`);
  return response.data;
});

export const doneFlipSignTodo = createAsyncThunk(
  "doneFlipSignTodo",
  async (id) => {
    const response = await api.post(`/todos/flip-signs/${id}/toggle-done`);
    return response.data;
  }
);

export const generateFlipSignTodos = createAsyncThunk(
  "generateTodos",
  async () => {
    const response = await api.post(`/todos/flip-signs/generate-todos`);
    return response.data;
  }
);

export const postPageVisit = createAsyncThunk("postPageVisit", async (page) => {
  const response = await api.post(`/stats/visits`, { page });
  return response.data;
});

export const getPageVisits = createAsyncThunk(
  "getPageVisits",
  async (payload) => {
    let to = "";
    let from = "";
    if (payload?.from) {
      from = "?from=" + format(new Date(payload.from), "yyyy-MM-dd");
      to =
        "&to=" + format(new Date(payload?.to ? payload.to : ""), "yyyy-MM-dd");
    }
    const response = await api.get(`/stats/visits` + from + to);
    return response.data;
  }
);

export const getNotifications = createAsyncThunk(
  "getNotifications",
  async () => {
    const response = await api.get(`/notifications`);
    return response.data;
  }
);

export const postNotification = createAsyncThunk(
  "postNotification",
  async (payload) => {
    const response = await api.post(`/notifications`, payload);
    return response.data;
  }
);

export const postNotificationStats = createAsyncThunk(
  "postNotificationStats",
  async ({ notification, ...payload }) => {
    const response = await api.post(`/notifications/${notification}`, payload);
    return response.data;
  }
);
